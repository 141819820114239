import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { insertStudent } from '../studentAction';

const AddStudent = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [age, setAge] = useState(0);
  return (
    <div>
      <Row>
        <Form>
          <Col className='col-6 offset-6 mt-4 py-4'>
            Name :{' '}
            <input
              name='name'
              placeholder='Felix'
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            Age :{' '}
            <input
              name='age'
              value={age}
              onChange={(e) => {
                setAge(e.target.value);
              }}
            />
          </Col>

          <Button onClick={() => dispatch(insertStudent({ age, name }))}>
            {' '}
            Click Me
          </Button>
        </Form>
      </Row>
      <Row>
        <Col className='col-6 offset-6'>{JSON.stringify(age)}</Col>
      </Row>
    </div>
  );
};

export default AddStudent;
