import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { getContributionByMemberID } from '../../actions/contributionAction';
import { insertRefundschedule } from '../../actions/refundscheduleAction';
import {
  getAllStudentsByGroup,
  getStudentDetailsByNationalID
} from '../../studentAction';

const AddRefund = () => {
  let userId = JSON.parse(localStorage.getItem('mamatwendelogin')).id;
  const StudentDetails = useSelector((state) => state.StudentDetails);
  const contributionList = useSelector((state) => state.contributionList);
  const [nationalID, setNationalId] = useState(0);
  const { contributions } = contributionList;

  const { student } = StudentDetails;

  useEffect(() => {
    dispatch(getStudentDetailsByNationalID(nationalID));
  }, [nationalID]);

  useEffect(() => {
    if (student?.id > 0) {
      dispatch(getContributionByMemberID(student?.id));
    }
  }, [student]);

  useEffect(() => {
    if (contributions?.length > 0) {
      setProposedamount(
        contributions?.reduce((acc, r) => acc + Number(r.total), 0)
      );
    }
  }, [contributions]);

  const dispatch = useDispatch();
  const [proposedamount, setProposedamount] = useState(0);
  const [reference, setReference] = useState(0);
  const [date, setDate] = useState(new Date());
  const submitHandler = () => {
    dispatch(
      insertRefundschedule({
        proposedamount,
        reference,
        date,
        groupid: StudentDetails?.student?.groupid,
        member: StudentDetails?.student?.id
      })
    );
  };

  return (
    <div>
      <Row>
        <Col>New Refund Schedule</Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={submitHandler} className='border1 p-5'>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                National ID : 22804975
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  className='form-control form-control-sm'
                  name='nationalID'
                  value={nationalID}
                  onChange={(e) => {
                    setNationalId(e.target.value);
                  }}
                  placeholder='.....'
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                Amount
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  className='form-control form-control-sm'
                  name='proposedamount'
                  value={proposedamount}
                  onChange={(e) => {
                    setProposedamount(e.target.value);
                  }}
                  type='number'
                  placeholder='0.00'
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                Reference
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  className='form-control form-control-sm'
                  name='reference'
                  value={reference}
                  onChange={(e) => {
                    setReference(e.target.value);
                  }}
                  placeholder='.....'
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group>
              <Form.Label className='d-flex justify-content-end'>
                <label>
                  <h4>{new Intl.NumberFormat().format(proposedamount)}</h4>
                </label>
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                Refund Date
              </Form.Label>
              <Col className='col-9'>
                <DatePicker
                  dateFormat='dd/MMM/yyyy'
                  className='form-control form-control-sm'
                  selected={date}
                  name='date'
                  onChange={(date) => setDate(date)}
                ></DatePicker>
              </Col>
            </Form.Group>

            <Form.Group>
              <Button
                disabled={!StudentDetails?.student?.id > 0}
                style={{ float: 'right' }}
                onClick={submitHandler}
                className='btn btn-sm btn-block'
              >
                Save Repayment Schedule for {StudentDetails?.student.fullname}
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AddRefund;
