import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWards } from '../actions/wardAction';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { MDBDataTableV5 } from 'mdbreact';
import Loader from '../Loader';
import Message from '../Message';
import { FaPlus } from 'react-icons/fa6';
const Wards = () => {
  const dispatch = useDispatch();
  const wardList = useSelector((state) => state.wardList);

  const { wards, loading, error } = wardList;

  useEffect(() => {
    dispatch(getAllWards());
  }, []);

  const handleDelete = () => {};

  const data = {
    columns: [
      {
        label: 'SN',
        field: 'id',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Constituency',
        field: 'constituency',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Action',
        field: 'update',
        sort: 'asc',
        width: 10
      }
    ],
    rows:
      wards &&
      wards.length &&
      wards.map((ward) => {
        return {
          name: ward.name,
          id: ward.id,
          constituency: ward.constituency,

          update: (
            <>
              <LinkContainer to={`/ward/update/${ward.id}`}>
                <Button variant='success' className='btn-sm mr-2'>
                  <i className='fas fa-angle-double-right'></i>
                </Button>
              </LinkContainer>{' '}
              {'  '}
              <Button
                variant='danger'
                className='btn-sm mr-2'
                onClick={() => {
                  handleDelete(ward.id);
                }}
              >
                <i className='fas fa-trash'></i>
              </Button>
            </>
          )
        };
      })
  };

  return (
    <div>
      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8 pt-5'>
          <h2 className='text-center'>
            MAMA TWENDE - LIST OF WARDS
            <span style={{ float: 'right' }}>
              <Link to='/addward'>
                <Button className='btn btn-sm'>
                  {' '}
                  <FaPlus className='mx-1' />
                  New Ward
                </Button>
              </Link>
            </span>
          </h2>

          <hr />
        </Col>
      </Row>
      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger' children={error} />
          ) : (
            <MDBDataTableV5
              small
              //cellEdit={cellEditFactory({ mode: 'click' })}

              entriesOptions={[5, 25, 50, 75, 100, 150, 200, 250, 300]}
              entries={5}
              hover
              striped
              sortable
              exportToCSV
              fullPagination
              data={data}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Wards;
