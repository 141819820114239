import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import FormContainer from '../FormContainer';
import Alert from '../Alert';
import { getAllConstituency} from '../actions/constituencyAction';
import { insertWard } from '../actions/wardAction';
const AddWard = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [constituency, setConstituency] = useState('');

  const wardInsert = useSelector((state) => state.wardInsert);
  const constituencyList = useSelector((state) => state.constituencyList);

  const { constituencies, loading: constituenciesLoading } = constituencyList;

  useEffect(() => {
    dispatch(getAllConstituency());
  }, [dispatch]);

  const { success } = wardInsert;
  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
    }, 3000);
  }, [success]);

  const submitHandler = () => {
    if (!!constituency) {
      if (!!name) {
        dispatch(
          insertWard({
            name,
            constituency,
          })
        );
      }
    }
  };

  return (
    <div>
      <Row>
        <Col className='col-6 offset-3 mt-3 pt-3'>
          <h2 className='text-center'>
            ADD WARD
            <span style={{ float: 'right' }}>
              <Link to='/wards'>
                <Button className='btn btn-light btn-sm'>All Wards</Button>
              </Link>
            </span>
          </h2>
          <hr />
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col className='col-8 offset-2 my-3'>
          <FormContainer>
            <Row>
              <Col className='mt-3'>
                <div className='col-12'>
                  {A && <Alert type='success' message={A} />}
                </div>
              </Col>
            </Row>
            <Form onSubmit={submitHandler} className='border1'>
              <Form.Group className='my-2' as={Row} controlId='fullnames'>
                <Form.Label column sm={3}>
                  WARD NAME
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name='name'
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder='Group Name'
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group className='my-2' as={Row} controlId='ward'>
                <Form.Label column sm={3}>
                  CHOOSE CONSTITUENCY
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as='select'
                    name='ward'
                    value={constituency}
                    onChange={(e) => {
                      setConstituency(e.target.value);
                    }}
                    placeholder='0'
                  >
                    <option value={''}>--Select Constituency--</option>
                    {!constituenciesLoading &&
                      constituencies &&
                      constituencies.length &&
                      constituencies.map((constituency) => (
                        <option key={constituency.id} value={constituency.id}>
                          {constituency.name}
                        </option>
                      ))}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Button
                style={{ float: 'right' }}
                onClick={submitHandler}
                className='btn btn-sm'
              >
                SAVE
              </Button>
            </Form>
          </FormContainer>
        </Col>
      </Row>
    </div>
  );
};

export default AddWard;
