import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getAllGroups, getGroupDetailsByID } from "../actions/groupAction";
import { getAllStudentsByGroup } from "../studentAction";
import { LinkContainer } from "react-router-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import Loader from "../Loader";
import Message from "../Message";
import ReportGroupMembers from "../Report_GroupMember";
import ReactToPrint from "react-to-print";
import Papa from "papaparse";
import { useCSVDownloader } from "react-papaparse";
const ManageGroup = () => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const groupList = useSelector((state) => state.groupList);
  const studentList = useSelector((state) => state.studentList);
  const { students, loading: studentsLoading } = studentList;
  const groupDetails = useSelector((state) => state.groupDetails);

  const { group, loading: groupLoading } = groupDetails;

  const { groups, loading: groupsLoading, error } = groupList;

  const [groupid, setGroup] = useState(0);

  useEffect(() => {
    dispatch(getAllGroups());
  }, []);
  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 100,
      },

      {
        label: "ID Number",
        field: "idnumber",
        sort: "asc",
        width: 100,
      },

      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 100,
      },

      {
        label: "Position",
        field: "position",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "update",
        sort: "asc",
        width: 10,
      },
    ],
    rows:
      students &&
      students.length &&
      students.map((meeting) => {
        return {
          name: meeting.fullname,
          idnumber: meeting.idnumber,
          position: meeting.position,
          mobile: meeting.mobile,

          update: (
            <>
              <LinkContainer to={`/member/bio/${meeting.id}`}>
                <Button variant="success" className="btn-sm mr-2">
                  Details <i className="fas fa-angle-double-right"></i>
                </Button>
              </LinkContainer>{" "}
              {"  "}
              <Button
                variant="danger"
                className="btn-sm mr-2"
                onClick={() => {
                  handleDelete(meeting.id);
                }}
              >
                Delete <i className="fas fa-trash"></i>
              </Button>
            </>
          ),
        };
      }),
  };
  const handleDelete = () => {};

  const { CSVDownloader, Type } = useCSVDownloader();

  const downloadCSV = () => {
    const csv = Papa.unparse({ students });
    const blob = new Blob([csv]);
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob, { type: "text/plain" });
    a.download = "CSV Export File";
  };
  return (
    <div>
      <Row className="dflex justify-content-center">
        <Col className="col-4 py-3 offset-2">
          <h3>Group Members</h3>
          <Form.Control
            as="select"
            name="groupid"
            value={groupid}
            onChange={(e) => {
              setGroup(e.target.value);
              dispatch(getGroupDetailsByID(e.target.value));
              dispatch(getAllStudentsByGroup(e.target.value));
            }}
          >
            <option value="0">Choose group name</option>
            {!groupsLoading &&
              groups?.map((g) => (
                <option value={g.id} key={g.id}>
                  {g.name}
                </option>
              ))}
          </Form.Control>
        </Col>
        <Col className="col-3 py-3 pt-5 offset-2">
          <ReactToPrint
            trigger={() => (
              <Button variant="success" className="btn mr-2">
                Print Group Members <i className="fas fa-print"></i>
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-10">
          <h2 className="text-center">{group.name} MEMBERS REGISTER</h2>
          <div style={{ display: "none" }}>
            <ReportGroupMembers id={groupid} ref={componentRef} />
          </div>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col className="col-10">
          {studentsLoading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger" children={error} />
          ) : students && students.length ? (
            <>
              <MDBDataTableV5
                small
                //cellEdit={cellEditFactory({ mode: 'click' })}

                entriesOptions={[5, 25, 50, 75, 100, 150, 200, 250, 300]}
                entries={5}
                hover
                striped
                responsiveSm
                sortable
                exportToCSV
                fullPagination
                data={data}
              />
            </>
          ) : (
            <Row>
              <h3>No Records Found</h3>
            </Row>
          )}
        </Col>
      </Row>
      <Row className="dflex justify-content-end">
        <Col className="col-4">
          <CSVDownloader data={students} filename="members">
            <Button
              className="btn btn-sm btn-primary mr-4"
              onClick={() => {
                downloadCSV();
              }}
            >
              <i className="fas fa-file-csv mx-2"></i>
              Download Group Members
            </Button>
          </CSVDownloader>
        </Col>
      </Row>
    </div>
  );
};

export default ManageGroup;
