import axios from 'axios';
import {
  STUDENT_REQUEST,
  STUDENT_REQUEST_SUCCESS,
  STUDENT_REQUEST_FAIL,
  STUDENT_INSERT,
  STUDENT_INSERT_SUCCESS,
  STUDENT_INSERT_FAIL,
  STUDENT_DETAILS_REQUEST,
  STUDENT_DETAILS_REQUEST_SUCCESS,
  STUDENT_DETAILS_REQUEST_FAIL,
  STUDENT_UPDATE_REQUEST,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_UPDATE_FAIL,
  STUDENT_DELETE_REQUEST,
  STUDENT_DELETE_SUCCESS,
  STUDENT_DELETE_FAIL,
  INVITE_REQUEST,
  INVITE_REQUEST_FAIL,
  INVITE_REQUEST_SUCCESS
} from './studentConstant';

const config = {
  headers: {
    headers: { 'Content-Type': 'application/json' }
  }
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export const getMeetingParticipantsByMeetingID =
  (id, token) => async (dispatch) => {
    dispatch({ type: INVITE_REQUEST });
    try {
      const { data } = await axiosInstance.get(
        `/api/meeting/participants/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({
        type: INVITE_REQUEST_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: INVITE_REQUEST_FAIL,
        payload: error.message
      });
    }
  };


export const getMeetingInviteesByMeetingID =
  (id, token) => async (dispatch) => {
    dispatch({ type: INVITE_REQUEST });
    try {
      const { data } = await axiosInstance.get(
        `/api/meeting/invitees/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({
        type: INVITE_REQUEST_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: INVITE_REQUEST_FAIL,
        payload: error.message
      });
    }
  };


export const insertStudentBulk = (ledger, token) => async (dispatch) => {
  dispatch({ type: STUDENT_DETAILS_REQUEST });
  const { data } = await axiosInstance.post(
    '/contributions/bulk',
    ledger,
    {
      headers: { Authorization: `Bearer ${token}` }
    },

    config
  );

  try {
    dispatch({
      type: STUDENT_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: STUDENT_INSERT_FAIL, payload: error.message });
  }
};
export const insertStudent = (student, token) => async (dispatch) => {
  dispatch({ type: STUDENT_INSERT });
  const { data } = await axiosInstance.post(
    '/api/student/',
    {
      headers: { Authorization: `Bearer ${token}` }
    },
    student,
    config
  );
  try {
    dispatch({
      type: STUDENT_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: STUDENT_INSERT_FAIL, payload: error.message });
  }
};

export const getAllStudents = (token) => async (dispatch) => {
  dispatch({ type: STUDENT_REQUEST });

  try {
    const { data } = await axiosInstance.get(`/api/students`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    dispatch({
      type: STUDENT_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: STUDENT_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getAllStudentsByGroup = (groupid, token) => async (dispatch) => {
  dispatch({ type: STUDENT_REQUEST });

  try {
    const { data } = await axiosInstance.get(`/api/students/group/${groupid}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    dispatch({
      type: STUDENT_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: STUDENT_REQUEST_FAIL,
      payload: error.message
    });
  }
};
export const getAllStudentsByGroupInvite =
  (groupid, meeting, token) => async (dispatch) => {
    dispatch({ type: STUDENT_REQUEST });

    try {
      const { data } = await axiosInstance.get(
        `/api/students/meeting/group/${groupid}/${meeting}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      dispatch({
        type: STUDENT_REQUEST_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: STUDENT_REQUEST_FAIL,
        payload: error.message
      });
    }
  };

export const getStudentDetailsByID = (id, token) => async (dispatch) => {
  dispatch({ type: STUDENT_DETAILS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/student/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    dispatch({
      type: STUDENT_DETAILS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: STUDENT_DETAILS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getMemberDetailsByID = (id, token) => async (dispatch) => {
  dispatch({ type: STUDENT_DETAILS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/member/details/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    dispatch({
      type: STUDENT_DETAILS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: STUDENT_DETAILS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getMemberByNationalID = (id, token) => async (dispatch) => {
  dispatch({ type: STUDENT_DETAILS_REQUEST });
  try {
    const { data } = await axiosInstance.get(
      `/api/member/details/nationalid/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    dispatch({
      type: STUDENT_DETAILS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: STUDENT_DETAILS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getStudentDetailsByMobile =
  (mobile, token) => async (dispatch) => {
    dispatch({ type: STUDENT_DETAILS_REQUEST });
    try {
      const { data } = await axiosInstance.get(
        `/api/student/mobile/${mobile}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({
        type: STUDENT_DETAILS_REQUEST_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: STUDENT_DETAILS_REQUEST_FAIL,
        payload: error.message
      });
    }
  };
export const getStudentDetailsByNationalID =
  (mobile, token) => async (dispatch) => {
    dispatch({ type: STUDENT_DETAILS_REQUEST });
    try {
      const { data } = await axiosInstance.get(
        `/api/student/nationalid/${mobile}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({
        type: STUDENT_DETAILS_REQUEST_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: STUDENT_DETAILS_REQUEST_FAIL,
        payload: error.message
      });
    }
  };

export const updateStudentDetails = (student, token) => async (dispatch) => {
  dispatch({ type: STUDENT_UPDATE_REQUEST });
  try {
    const { data } = await axiosInstance.put(
      `/api/student/${student.id}`,
      student,
      {
        headers: { Authorization: `Bearer ${token}` }
      },

      config
    );
    dispatch({ type: STUDENT_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: STUDENT_UPDATE_FAIL,
      payload: error.message
    });
  }
};

export const changeStatus = (student, token) => async (dispatch) => {
  dispatch({ type: STUDENT_UPDATE_REQUEST });
  try {
    const { data } = await axiosInstance.put(
      `/api/student/changestatus/${student.id}`,
      student,
      {
        headers: { Authorization: `Bearer ${token}` }
      },

      config
    );
    dispatch({ type: STUDENT_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: STUDENT_UPDATE_FAIL,
      payload: error.message
    });
  }
};

export const deleteStudent = (id, token) => async (dispatch) => {
  dispatch({ type: STUDENT_DELETE_REQUEST });
  try {
    const { data } = await axiosInstance.delete(`/api/student/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    dispatch({ type: STUDENT_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: STUDENT_DELETE_FAIL,
      payload: error.message
    });
  }
};
