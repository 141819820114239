import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Barcode from 'react-barcode';

export const Membercard = React.forwardRef((props, ref) => {
  const student = props.student;

  alert(JSON.stringify(props));

  const StudentDetails = useSelector((state) => state.StudentDetails);

  const { student: studentFound, loading: detailsLoading } = StudentDetails;
  const [fullname, setFullname] = useState('');
  const [age, setAge] = useState(0);
  const [groupid, setGroup] = useState('');
  const [idnumber, setIdnumber] = useState('');
  const [mobile, setMobile] = useState('');
  const [position, setPosition] = useState('');
  const [occupation, setOccupation] = useState('');
  const [estate, setEstate] = useState('');
  const [ward, setWard] = useState('');
  useEffect(() => {
    setFullname(student.fullname);
    setIdnumber(student.idnumber);
    setAge(student.age);
    setMobile(student.mobile);
    setOccupation(student.occupation);
    setGroup(student.name);
    setWard(student.ward);
    setEstate(student.estate);
    setPosition(student.position);
  }, [student]);
  return (
    <div
      className='d-flex align-items-center pt-5 mt-5 justify-content-center bd-highlight'
      ref={ref}
    >
      <Card style={{ width: '288px' }}>
        <Card.Header>
          <h6> Name: {fullname} </h6>{' '}
        </Card.Header>
        <Col>
          <hr />
          <h6> Mobile Number : {mobile}</h6>
          <hr />
          <h6>{groupid}</h6>
        </Col>
        <Row>
          <Col className='col-6'>
            <Barcode value={mobile} />
          </Col>
        </Row>
      </Card>

      {/* <Row>
        <Col>{JSON.stringify(props)}</Col>
      </Row> */}
    </div>
  );
});
