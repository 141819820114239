import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import FormContainer from './FormContainer';
import { useSelector, useDispatch } from 'react-redux';
import { insertMeeting } from './actions/meetingAction';
import Alert from './Alert';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const RegisterMeeting = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [venue, setVenue] = useState('');
  const [purpose, setPurpose] = useState('');
  const [date, setDate] = useState(new Date());

  const meetingInsert = useSelector((state) => state.meetingInsert);

  const { success } = meetingInsert;
  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    toast('Meeting Added successfuly');

    // setTimeout(() => {
    //   setA('');
    // }, 3000);
  }, [success]);

  const submitHandler = () => {
    if (!!name) {
      dispatch(insertMeeting({ name, venue, purpose, date: new Date() }));
      setName('');
      setVenue('');
      setPurpose('');
    }
  };
  return (
    <div>
      <Row>
        <Col className='col-6 offset-4 mt-3'>
          <h2 className='text-primary text-center'>New MEETING DETAILS</h2>
          <hr />
          <span>
            <Link to='/meetings'>
              <Button className='btn btn-light btn-sm'>Back</Button>
            </Link>
          </span>
        </Col>
      </Row>
      <Row>
        <Col className='col-8 offset-2 my-3'>
          <div className='col-12'>
            {A && (
              <ToastContainer
                toast='Meeting Added successfuly'
                position='top-right'
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
              />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='col-8 offset-2 my-3'>
          <FormContainer>
            <Form onSubmit={submitHandler} className='border1'>
              <Form.Group className='my-2' as={Row} controlId='fullnames'>
                <Form.Label column sm={3}>
                  Meeting Name
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name='name'
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder='Meeting ABC'
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group className='my-2' as={Row} controlId='fullnames'>
                <Form.Label column sm={3}>
                  Purpose
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name='purpose'
                    value={purpose}
                    onChange={(e) => {
                      setPurpose(e.target.value);
                    }}
                    placeholder='Training on ABCD'
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group className='my-2' as={Row} controlId='fullnames'>
                <Form.Label column sm={3}>
                  Venue
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name='venue'
                    value={venue}
                    onChange={(e) => {
                      setVenue(e.target.value);
                    }}
                    placeholder='Nairobi'
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Button
                style={{ float: 'right' }}
                onClick={submitHandler}
                className='btn btn-sm'
              >
                Save
              </Button>
            </Form>
          </FormContainer>
        </Col>
      </Row>
    </div>
  );
};
export default RegisterMeeting;
