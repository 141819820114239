import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import List from './List';
import AddStudent from './student/AddStudent';
import ImportCSV from './ImportCSV';
import RegisterMeeting from './RegisterMeeting';
import Meetings from './components/Meetings';
import Meeting from './components/Meeting';
import UpdateBio from './components/UpdateBio';
import Groups from './components/Groups';
import Wards from './components/Wards';
import AddWard from './components/AddWard';
import AddGroup from './components/AddGroup';
import UpdateGroup from './components/UpdateGroup';
import Login from './components/Login';
import Logout from './components/Logout';
import ManageGroup from './components/ManageGroup';
import { Membercard } from './components/Membercard';
import MemberAdmin from './components/MemberAdmin';

import ReportMembers from './ReportMembers';
import ReportGroupMembers from './Report_GroupMember';
import UpdateWard from './components/UpdateWard';
import Constituency from './components/Constituency';
import Addcontribution from './components/contribution/Addcontribution';
import Home from './components/home/Home';

import Transaction from './components/transactions/Transaction';
import Balancecard from './components/balancecard/Balancecard';
import Addloan from './components/loan/Addloan';
import Loan from './components/loan/Loan';
import Systemadmin from './components/admin/Systemadmin';
import ProgramExpenses from './components/expenses/ProgramExpenses';
import GroupExpenses from './components/expenses/GroupExpenses';
import ContributionRefunds from './components/contribution/ContributionRefunds';
import ApproveRefund from './components/ApproveRefund';
import ReceiveContribution from './components/contribution/ReceiveContribution';
import Memberdetails from './components/mobilecards/Memberdetails';
import MemberCardNew from './components/MemberCardNew';
import MeetingInvites from './components/MeetingInvites';
import Redirect from './Redirect';
import Sysadmin from './components/home/Sysadmin';

function App() {
  const url = 'https://client.airtouch.co.ke:9012/';

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />,
      children: [
        {
          path: '/logout',
          element: <Logout />
        },
        {
          path: '/list',
          element: <List />
        },
        {
          path: '/contribution/add/:meeting/:member',
          element: <Addcontribution />
        },
        {
          path: '/groups',
          element: <Groups />
        },
        {
          path: '/contribution/receive',
          element: <ReceiveContribution />
        },
        { path: '/transactions', element: <Transaction /> },
        // { path: '/dashboard', element: <Dashboard /> },
        { path: '/wards', element: <Wards /> },
        { path: '/loan/details/:id', element: <Loan /> },
        { path: '/addward', element: <AddWard /> },
        { path: '/systemadmin', element: <Systemadmin /> },
        { path: '/ward/update/:id', element: <UpdateWard /> },
        { path: '/constituencies', element: <Constituency /> },
        { path: '/member/print/', element: <Membercard /> },
        { path: '/member/detail/:id', element: <Memberdetails /> },
        { path: '/member/printcard/:id', element: <MemberCardNew /> },
        { path: '/member/printcard/:id', element: <MemberCardNew /> },
        { path: '/redirect', element: <Redirect url={url} /> },

        { path: '/addgroup', element: <AddGroup /> },
        { path: '/member/bio/:id', element: <UpdateBio /> },
        { path: '/member/bio/:id/admin', element: <MemberAdmin /> },
        { path: '/member/:id/refund', element: <ApproveRefund /> },
        { path: '/group/update/:id', element: <UpdateGroup /> },
        { path: '/meetings', element: <Meetings /> },
        { path: '/meeting/:id', element: <Meeting /> },
        { path: '/meeting/invite/:id', element: <MeetingInvites /> },
        { path: '/loan/process/:meeting/:member', element: <Addloan /> },
        { path: '/registermeeting', element: <RegisterMeeting /> },
        { path: '/addstudent', element: <AddStudent /> },
        { path: '/import', element: <ImportCSV /> },
        { path: '/groupmembers', element: <ManageGroup /> },
        { path: '/membersreport', element: <ReportMembers /> },
        { path: '/member/card', element: <ReportGroupMembers /> },
        { path: '/group/card', element: <Balancecard /> },
        { path: '/group/expenses', element: <GroupExpenses /> },
        { path: '/member/refund', element: <ContributionRefunds /> },
        { path: '/configuration', element: <Sysadmin /> }
      ]
    },
    {
      path: '/login',
      element: <Login />
    }
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}

export default App;

{
  /* <Routes> */
}
{
  /* <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout />} /> */
}

{
  /* <Route element={<RequireAuth />}>
    {/* <Route exact path='/' element={<Home />} /> */
}
{
  /* <Route path='/list' element={<List />} /> */
}
{
  /* <Route
              path='/contribution/add/:meeting/:member'
              element={<Addcontribution />}
            />{' '} */
}
{
  /* <Route path='/groups' element={<Groups />} /> */
}
{
  /* <Route path='/transactions' element={<Transaction />} /> */
}
{
  /* <Route path='/wards' element={<Wards />} /> */
}
{
  /* <Route path='/addward' element={<AddWard />} /> */
}
{
  /* <Route path='/ward/update/:id' element={<UpdateWard />} /> */
}
{
  /* <Route path='/constituencies' element={<Constituency />} /> */
}
{
  /* <Route path='/member/print/:id' element={<Membercard />} /> */
}
{
  /* <Route path='/member/printcard/:id' element={<PrintCard />} /> */
}
{
  /* <Route path='/addgroup' element={<AddGroup />} /> */
}
{
  /* <Route path='/member/bio/:id' element={<UpdateBio />} /> */
}
{
  /* <Route path='/group/update/:id' element={<UpdateGroup />} /> */
}
{
  /* <Route path='/meetings' element={<Meetings />} />
            <Route path='/meeting/:id' element={<Meeting />} />
            <Route path='/registermeeting' element={<RegisterMeeting />} />
            <Route path='/addstudent' element={<AddStudent />} />
            <Route path='/import' element={<ImportCSV />} />
            <Route path='/import Addloan from './components/loan/Addloan';
groupmembers' element={<Maimport MemberAdmin from './components/MemberAdmin';
nageGroup />} />
            <Route path='/membersreport' element={<Report_Members />} />
            <Route path='/member/card' element={<ReportGroupMembers />} /> */
}
{
  /* </Route>
</Routes>; */
}
