export const GROUPADMINS_REQUEST = 'GROUPADMIN_REQUEST';
export const GROUPADMINS_REQUEST_SUCCESS = 'GROUPADMIN_REQUEST_SUCCESS';
export const GROUPADMINS_REQUEST_FAIL = 'GROUPADMIN_REQUEST_FAIL';

export const GROUPADMIN_INSERT = 'GROUPADMIN_INSERT';
export const GROUPADMIN_INSERT_SUCCESS = 'GROUPADMIN_INSERT_SUCCESS';
export const GROUPADMIN_INSERT_FAIL = 'GROUPADMIN_INSERT_FAIL';

export const GROUPADMIN_UPDATE_REQUEST = 'GROUPADMIN_UPDATE_REQUEST';
export const GROUPADMIN_UPDATE_SUCCESS = 'GROUPADMIN_UPDATE_SUCCESS';
export const GROUPADMIN_UPDATE_FAIL = 'GROUPADMIN_UPDATE_FAIL';

export const GROUPADMIN_DELETE_REQUEST = 'GROUPADMIN_DELETE_REQUEST';
export const GROUPADMIN_DELETE_SUCCESS = 'GROUPADMIN_DELETE_SUCCESS';
export const GROUPADMIN_DELETE_FAIL = 'GROUPADMIN_DELETE_FAIL';

export const GROUPADMIN_DETAILS_REQUEST = 'GROUPADMIN_DETAILS_REQUEST';
export const GROUPADMIN_DETAILS_REQUEST_SUCCESS = 'GROUPADMIN_DETAILS_REQUEST_SUCCESS';
export const GROUPADMIN_DETAILS_REQUEST_FAIL = 'GROUPADMIN_DETAILS_REQUEST_FAIL';
