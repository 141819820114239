import {
  EXPENSES_REQUEST,
  EXPENSES_REQUEST_SUCCESS,
  EXPENSES_REQUEST_FAIL,
  EXPENSE_INSERT,
  EXPENSE_INSERT_SUCCESS,
  EXPENSE_INSERT_FAIL,
  EXPENSE_UPDATE_REQUEST,
  EXPENSE_UPDATE_FAIL,
  EXPENSE_UPDATE_SUCCESS,
  EXPENSE_DELETE_FAIL,
  EXPENSE_DELETE_SUCCESS,
  EXPENSE_DELETE_REQUEST,
  EXPENSE_DETAILS_REQUEST,
  EXPENSE_DETAILS_REQUEST_SUCCESS,
  EXPENSE_DETAILS_REQUEST_FAIL
} from '../constants/expenseConstant';

export const expenseListReducer = (state = { expenses: [] }, action) => {
  switch (action.type) {
    case EXPENSES_REQUEST:
      return {
        loading: true,
        expenses: []
      };
    case EXPENSES_REQUEST_SUCCESS:
      return {
        loading: false,
        expenses: action.payload
      };
    case EXPENSES_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const expenseInsertReducer = (state, action) => {
  switch (action.type) {
    case EXPENSE_INSERT:
      return {
        loading: true,
        error: [],
        expense: {}
      };
    case EXPENSE_INSERT_SUCCESS:
      return {
        loading: false,
        expense: {},
        success: action.payload
      };
    case EXPENSE_INSERT_FAIL:
      return {
        loading: false,
        expenses: [],
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export const expenseUpdateReducer = (state, action) => {
  switch (action.type) {
    case EXPENSE_UPDATE_REQUEST:
      return {
        loading: true,
        error: [],
        expense: {}
      };
    case EXPENSE_UPDATE_SUCCESS:
      return {
        loading: true,
        success: action.payload,
        expense: {}
      };
    case EXPENSE_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export const expenseDeleteReducer = (state, action) => {
  switch (action.type) {
    case EXPENSE_DELETE_REQUEST:
      return {
        loading: false,
        error: action.payload,
        expense: {}
      };
    case EXPENSE_DELETE_SUCCESS:
      return {
        loading: false,

        expense: {}
      };
    case EXPENSE_DELETE_FAIL:
      return {
        loading: false,
        expenses: [],
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export const expenseDetailsReducer = (state = { expense: {} }, action) => {
  switch (action.type) {
    case EXPENSE_DETAILS_REQUEST:
      return {
        loading: true,
        error: [],
        expense: {}
      };
    case EXPENSE_DETAILS_REQUEST_SUCCESS:
      return {
        expense: action.payload,
        loading: false,
        error: []
      };
    case EXPENSE_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        expense: {},
        error: action.payload
      };
    default:
      return { ...state };
  }
};
