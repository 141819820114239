import {
  GROUPS_REQUEST,
  GROUPS_REQUEST_SUCCESS,
  GROUPS_REQUEST_FAIL,
  GROUP_INSERT,
  GROUP_INSERT_SUCCESS,
  GROUP_INSERT_FAIL,
  GROUP_UPDATE_REQUEST,
  GROUP_UPDATE_FAIL,
  GROUP_UPDATE_SUCCESS,
  GROUP_DELETE_FAIL,
  GROUP_DELETE_SUCCESS,
  GROUP_DELETE_REQUEST,
  GROUP_DETAILS_REQUEST,
  GROUP_DETAILS_REQUEST_SUCCESS,
  GROUP_DETAILS_REQUEST_FAIL
} from '../constants/groupConstant';

export const groupListReducer = (state = { groups: [] }, action) => {
  switch (action.type) {
    case GROUPS_REQUEST:
      return {
        loading: true,
        groups: []
      };
    case GROUPS_REQUEST_SUCCESS:
      return {
        loading: false,
        groups: action.payload
      };
    case GROUPS_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const groupInsertReducer = (state, action) => {
  switch (action.type) {
    case GROUP_INSERT:
      return {
        loading: true,
        grouperror: [],
        group: {}
      };
    case GROUP_INSERT_SUCCESS:
      return {
        loading: false,
        group: {},
        success: action.payload
      };
    case GROUP_INSERT_FAIL:
      return {
        loading: false,
        groups: [],
        grouperror: action.payload
      };
    default:
      return { ...state };
  }
};

export const groupUpdateReducer = (state, action) => {
  switch (action.type) {
    case GROUP_UPDATE_REQUEST:
      return {
        loading: true,
        groupsError: [],
        group: {}
      };
    case GROUP_UPDATE_SUCCESS:
      return {
        loading: true,
        success: 'Details Updated',
        group: {}
      };
    case GROUP_UPDATE_FAIL:
      return {
        loading: false,
        groupsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const groupDeleteReducer = (state, action) => {
  switch (action.type) {
    case GROUP_DELETE_REQUEST:
      return {
        loading: false,
        groupsError: action.payload,
        group: {}
      };
    case GROUP_DELETE_SUCCESS:
      return {
        loading: false,

        group: {}
      };
    case GROUP_DELETE_FAIL:
      return {
        loading: false,
        groups: [],
        groupsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const groupDetailsReducer = (state = { group: {} }, action) => {
  switch (action.type) {
    case GROUP_DETAILS_REQUEST:
      return {
        loading: true,
        groupsError: [],
        group: {}
      };
    case GROUP_DETAILS_REQUEST_SUCCESS:
      return {
        group: action.payload,
        loading: false,
        groupsError: []
      };
    case GROUP_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        group: {},
        groupsError: action.payload
      };
    default:
      return { ...state };
  }
};
