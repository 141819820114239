import axios from 'axios';
import {
  EXPENSES_REQUEST,
  EXPENSES_REQUEST_SUCCESS,
  EXPENSES_REQUEST_FAIL,
  EXPENSE_INSERT,
  EXPENSE_INSERT_SUCCESS,
  EXPENSE_INSERT_FAIL,
  EXPENSE_DETAILS_REQUEST,
  EXPENSE_DETAILS_REQUEST_SUCCESS,
  EXPENSE_DETAILS_REQUEST_FAIL,
  EXPENSE_UPDATE_REQUEST,
  EXPENSE_UPDATE_SUCCESS,
  EXPENSE_UPDATE_FAIL
} from '../constants/expenseConstant';

const config = {
  headers: {
    headers: { 'Content-Type': 'application/json' }
  }
};

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const insertExpense = (expense) => async (dispatch) => {
  dispatch({ type: EXPENSE_INSERT });
  const { data } = await axiosInstance.post('/api/expense/', expense, config);
  try {
    dispatch({
      type: EXPENSE_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: EXPENSE_INSERT_FAIL, payload: error.message });
  }
};

export const getAllExpenses = (group) => async (dispatch) => {
  dispatch({ type: EXPENSES_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/expenses/${group}`);
    dispatch({
      type: EXPENSES_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: EXPENSES_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getExpenseDetailsByID = (id) => async (dispatch) => {
  dispatch({ type: EXPENSE_DETAILS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/expense/id/${id}`);

    dispatch({
      type: EXPENSE_DETAILS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: EXPENSE_DETAILS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const updateExpenseDetails = (expense) => async (dispatch) => {
  dispatch({ type: EXPENSE_UPDATE_REQUEST });

  try {
    const { data } = await axiosInstance.put(
      `/api/expense/update/${expense.id}`,
      expense,
      config
    );
    dispatch({ type: EXPENSE_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EXPENSE_UPDATE_FAIL,
      payload: error.message
    });
  }
};

export const deleteExpense = (id) => async (dispatch) => {};
