import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAllLoanRepayment,
  getLoanDetailsByID
} from '../../actions/loanAction';
import AddRepayment from './AddRepayment';
import { MDBDataTableV5 } from 'mdbreact';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Loader from '../../Loader';
import Message from '../../Message';
import DataTable from 'datatables.net-dt';

const Loan = () => {
  const loanId = useParams('id').id;
  const dispatch = useDispatch();
  const loanDetails = useSelector((state) => state.loanDetails);
  const { loan } = loanDetails;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const loanRepaymentsList = useSelector((state) => state.loanRepaymentsList);

  const { loanrepayments, loading, error } = loanRepaymentsList;

  useEffect(() => {
    dispatch(getLoanDetailsByID(loanId));
    dispatch(getAllLoanRepayment(loanId));
  }, []);
  const handleDelete = () => {};

  const data2 = {
    columns: [{ data: 'repaymentdate' }, { data: 'amount' }],
    data: loanrepayments
  };

  const data = {
    columns: [
      { label: 'Date', field: 'date', sort: 'asc', width: 100 },
      {
        label: 'Amount(KSh)',
        field: 'amount',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Actions',
        field: 'update',
        sort: 'asc',
        width: 100
      }
    ],
    rows:
      loanrepayments &&
      loanrepayments?.length &&
      loanrepayments.map((meeting) => {
        return {
          amount: new Intl.NumberFormat().format(meeting.amount),
          date: new Date(meeting.repaymentdate).toLocaleDateString(),
          update: (
            <>
              <LinkContainer to={`/meeting/${meeting.id}`}>
                <Button variant='success' className='btn-sm mr-2'>
                  Details <i className='fas fa-angle-double-right'></i>
                </Button>
              </LinkContainer>{' '}
              {'  '}
              <Button
                variant='danger'
                className='btn-sm mr-2'
                onClick={() => {
                  handleDelete(meeting.id);
                }}
              >
                Delete <i className='fas fa-trash'></i>
              </Button>
            </>
          )
        };
      })
  };

  return (
    <div>
      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8'>
          <h3>Loan Details</h3>
          <hr />
          <div>
            <label>
              Name <span>{loan?.fullname}</span>{' '}
            </label>
          </div>
          <hr />
          <div>
            <label>
              Mobile <span>{loan?.mobile}</span>{' '}
            </label>
          </div>
          <hr />
          <div>
            <label>
              ID Number <span>{loan?.idnumber}</span>{' '}
            </label>
          </div>
          <hr />

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className='text-danger'>
                <h4 className='text-center'> LOAN REPAYMENT</h4>
              </Modal.Title>
            </Modal.Header>
            <AddRepayment balance={loan?.balance} handleClose={handleClose} />
            <Modal.Footer></Modal.Footer>
          </Modal>

          <div>
            <label>
              LOAN DETAILS {'  =>  '}
              <span>
                Principal : {new Intl.NumberFormat().format(loan?.principal)}
              </span>{' '}
              | Interest : {loan?.interest} | Loan Sum : {loan?.loansum} |
              Balance : {new Intl.NumberFormat().format(loan?.balance)}
            </label>
          </div>
        </Col>
        {/* <Col>
          <AddRepayment />
        </Col> */}
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <div className='loan-details'>{/* {JSON.stringify(loan)} */}</div>
      <div className='loan-repayments'>
        <Row className='dflex justify-content-center'>
          <Col className='col-lg-6 col-md-12 '>
            <h3 className='text text-center'>
              Repayments{' '}
              <span style={{ float: 'right' }}>
                <Button variant='primary' onClick={handleShow}>
                  Repayment
                </Button>
              </span>{' '}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row className='dflex justify-content-center'>
          <Col className='col-lg-6 col-md-12 '>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger' children={error} />
            ) : loanrepayments && loanrepayments.length ? (
              <>
                <MDBDataTableV5
                  small
                  //cellEdit={cellEditFactory({ mode: 'click' })}

                  entriesOptions={[5, 25, 50, 75, 100, 150, 200, 250, 300]}
                  entries={5}
                  hover
                  striped
                  responsiveSm
                  sortable
                  exportToCSV
                  fullPagination
                  data={data}
                />
              </>
            ) : (
              <Row>
                <h3>No Records Found</h3>
              </Row>
            )}
          </Col>
        </Row>

        {/* <DataTable data={data2} /> */}
        {/* <Row className='d-flex justify-content-center'>
          <Col className='col-8 mt-3'>
            <Table className='table table-responsive-md table-striped table-hover table-sm'>
              <thead>
                <tr>
                  <th scope='col'>#</th>
                  <th scope='col'>Date</th>
                  <th scope='col'>Amount Paid(Kshs)</th>
                </tr>
              </thead>
              <tbody>
                {loanrepayments?.length > 0 &&
                  loanrepayments?.map((r, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{new Date(r?.repaymentdate).toLocaleDateString()}</td>
                      <td>{new Intl.NumberFormat().format(r.amount)}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default Loan;
