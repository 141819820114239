export const REFUNDSCHEDULES_REQUEST = 'REFUNDSCHEDULE_REQUEST';
export const REFUNDSCHEDULES_REQUEST_SUCCESS = 'REFUNDSCHEDULE_REQUEST_SUCCESS';
export const REFUNDSCHEDULES_REQUEST_FAIL = 'REFUNDSCHEDULE_REQUEST_FAIL';

export const REFUNDSCHEDULE_INSERT = 'REFUNDSCHEDULE_INSERT';
export const REFUNDSCHEDULE_INSERT_SUCCESS = 'REFUNDSCHEDULE_INSERT_SUCCESS';
export const REFUNDSCHEDULE_INSERT_FAIL = 'REFUNDSCHEDULE_INSERT_FAIL';

export const REFUNDSCHEDULE_UPDATE_REQUEST = 'REFUNDSCHEDULE_UPDATE_REQUEST';
export const REFUNDSCHEDULE_UPDATE_SUCCESS = 'REFUNDSCHEDULE_UPDATE_SUCCESS';
export const REFUNDSCHEDULE_UPDATE_FAIL = 'REFUNDSCHEDULE_UPDATE_FAIL';

export const REFUNDSCHEDULE_DELETE_REQUEST = 'REFUNDSCHEDULE_DELETE_REQUEST';
export const REFUNDSCHEDULE_DELETE_SUCCESS = 'REFUNDSCHEDULE_DELETE_SUCCESS';
export const REFUNDSCHEDULE_DELETE_FAIL = 'REFUNDSCHEDULE_DELETE_FAIL';

export const REFUNDSCHEDULE_DETAILS_REQUEST = 'REFUNDSCHEDULE_DETAILS_REQUEST';
export const REFUNDSCHEDULE_DETAILS_REQUEST_SUCCESS =
  'REFUNDSCHEDULE_DETAILS_REQUEST_SUCCESS';
export const REFUNDSCHEDULE_DETAILS_REQUEST_FAIL = 'REFUNDSCHEDULE_DETAILS_REQUEST_FAIL';
