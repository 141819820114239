export const GROUPS_REQUEST = 'GROUP_REQUEST';
export const GROUPS_REQUEST_SUCCESS = 'GROUP_REQUEST_SUCCESS';
export const GROUPS_REQUEST_FAIL = 'GROUP_REQUEST_FAIL';

export const GROUP_INSERT = 'GROUP_INSERT';
export const GROUP_INSERT_SUCCESS = 'GROUP_INSERT_SUCCESS';
export const GROUP_INSERT_FAIL = 'GROUP_INSERT_FAIL';

export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST';
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS';
export const GROUP_UPDATE_FAIL = 'GROUP_UPDATE_FAIL';

export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST';
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS';
export const GROUP_DELETE_FAIL = 'GROUP_DELETE_FAIL';

export const GROUP_DETAILS_REQUEST = 'GROUP_DETAILS_REQUEST';
export const GROUP_DETAILS_REQUEST_SUCCESS =
  'GROUP_DETAILS_REQUEST_SUCCESS';
export const GROUP_DETAILS_REQUEST_FAIL = 'GROUP_DETAILS_REQUEST_FAIL';
