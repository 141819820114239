import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAIL,
  LOGIN_INSERT,
  LOGIN_INSERT_SUCCESS,
  LOGIN_INSERT_FAIL,
  LOGIN_LOGOUT,
  LOGIN_UPDATE_REQUEST,
  LOGIN_UPDATE_REQUEST_SUCCESS,
  LOGIN_UPDATE_REQUEST_FAIL,
  LOGIN_DETAILS_REQUEST,
  LOGIN_DETAILS_REQUEST_SUCCESS,
  LOGIN_DETAILS_REQUEST_FAIL
} from '../constants/loginConstants';

export const userLoginReducer = (state = { login: {} }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loginsLoading: true
      };
    case LOGIN_REQUEST_SUCCESS:
      return {
        success: 'Login Success',
        loginsLoading: false,
        login: action.payload
      };
    case LOGIN_REQUEST_FAIL:
      return {
        
        login: {},
        loginsLoading: false,
        error: action.payload
      };
    case LOGIN_LOGOUT:
      localStorage.clear();
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { logins: [] }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loginsLoading: true,
        logins: []
      };
    case LOGIN_REQUEST_SUCCESS:
      return {
        loginsLoading: false,
        logins: action.payload
      };
    case LOGIN_REQUEST_FAIL:
      return {
        loginsLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const loginDetailsReducer = (state = { login: {} }, action) => {
  switch (action.type) {
    case LOGIN_DETAILS_REQUEST:
      return {
        loading: true,
        login: {}
      };
    case LOGIN_DETAILS_REQUEST_SUCCESS:
      return {
        loading: false,
        login: action.payload
      };
    case LOGIN_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        login: {},
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export const loginInsertReducer = (state, action) => {
  switch (action.type) {
    case LOGIN_INSERT:
      return {
        loginsLoading: true,
        loginsError: []
        //LOGIN: null,
      };
    case LOGIN_INSERT_SUCCESS:
      return {
        loginsLoading: false,
        login: {},
        success: action.payload
      };
    case LOGIN_INSERT_FAIL:
      return {
        loginsLoading: false,
        logins: [],
        loginsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const loginUpdateReducer = (state, action) => {
  switch (action.type) {
    case LOGIN_UPDATE_REQUEST:
      return {
        loginsLoading: true,
        loginsError: []
        //LOGIN: null,
      };
    case LOGIN_UPDATE_REQUEST_SUCCESS:
      return {
        loginsLoading: false,
        success: action.payload
      };
    case LOGIN_UPDATE_REQUEST_FAIL:
      return {
        loginsLoading: false,
        logins: [],
        loginsError: action.payload
      };
    default:
      return { ...state };
  }
};
