import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Table, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { MDBDataTableV5 } from 'mdbreact';
import Loader from './Loader';
import Message from './Message';
import { useReactToPrint } from 'react-to-print';
//import {Membercard} from './components/Membercard';
import MemberCard from './components/MemberCardNew';
import { getAllGroups, getGroupDetailsByID } from './actions/groupAction';
import { getAllStudentsByGroup } from './studentAction';

const ReportMembers = () => {
  const dispatch = useDispatch();
  const groupList = useSelector((state) => state.groupList);
  const studentList = useSelector((state) => state.studentList);
  const { students, loading } = studentList;
  const groupDetails = useSelector((state) => state.groupDetails);

  const { group, loading: groupLoading } = groupDetails;

  const { groups, loading: groupsLoading, error } = groupList;

  const [groupid, setGroup] = useState(0);

  useEffect(() => {
    dispatch(getAllGroups());
  }, []);

  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 100
      },

      {
        label: 'ID Number',
        field: 'idnumber',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Group',
        field: 'group',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Position',
        field: 'position',
        sort: 'asc',
        width: 100
      }
    ],
    rows:
      students &&
      students.length &&
      students.map((meeting) => {
        return {
          name: meeting.fullname,
          idnumber: meeting.idnumber,
          group: meeting.groupname,
          position: meeting.position,
          mobile: meeting.mobile
        };
      })
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <div>
      <Row>
        <Col className='col-8 offset-2 my-5 pt-4'>
          <h2 className='text-center'>
            MAMA TWENDE - TUNAWEZA MEMBERS REGISTER
          </h2>
          <hr />
          <Row>
            <Col className='col-4'>
              <h3>Group Members</h3>
              <Form.Control
                as='select'
                name='groupid'
                value={groupid}
                onChange={(e) => {
                  setGroup(e.target.value);
                  dispatch(getGroupDetailsByID(e.target.value));
                  dispatch(getAllStudentsByGroup(e.target.value));
                }}
              >
                <option value='0'>Choose group name</option>
                {!groupsLoading &&
                  groups?.map((g) => (
                    <option value={g.id} key={g.id}>
                      {g.name}
                    </option>
                  ))}
              </Form.Control>
            </Col>
            <Col className='offset-2 py-5'>
              <Button
                variant='success'
                onClick={handlePrint}
                className='btn-sm mr-2'
              >
                Print Card <i className='fas fa-print'></i>
              </Button>
              <div style={{ display: 'none' }}>
                <MemberCard id={groupid} ref={componentRef} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className='col-8 offset-2'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger' children={error} />
          ) : students && students.length ? (
            <>
              <MDBDataTableV5
                small
                //cellEdit={cellEditFactory({ mode: 'click' })}

                entriesOptions={[5, 25, 50, 75, 100, 150, 200, 250, 300]}
                // entries={10}
                hover
                striped
                responsiveSm
                sortable
                exportToCSV
                fullPagination
                data={data}
              />
            </>
          ) : (
            <Row>
              <h3>No Records Found</h3>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ReportMembers;
