import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Alert from '../../Alert';
import { insertLogin } from '../../actions/loginAction';
import { useDispatch, useSelector } from 'react-redux';
const Createuser = ({ handleClose }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [role, setRole] = useState(1);
  const [A, setA] = useState('');
  const dispatch = useDispatch();
  const loginInsert = useSelector((state) => state.loginInsert);
  const { success } = loginInsert;

  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
    }, 3000);
  }, [success]);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!!name && name !== '') {
      if (!!email && email !== '') {
        if (!!mobile && mobile !== '') {
          dispatch(
            insertLogin({
              name,
              email,
              mobile,
              password: 'change@2023',
              role
            })
          );

          setName('');
          setEmail('default@mamatwende.com');
          setMobile('2547000000000');
        }
      }
    }
    handleClose();
  };
  return (
    <div className='pt-3'>
      <h4 className='text-center '>NEW MAMATWENDE APP USER</h4>
      <hr />

      <Form onSubmit={submitHandler} className='border1 p-4 pb-3'>
        <Form.Group className='my-2' as={Row} controlId='fullnames'>
          <Form.Label column sm={3}>
            <label>Username</label>
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              name='name'
              value={name}
              className='form-control form-control-sm'
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder='User Name'
            ></Form.Control>
          </Col>
        </Form.Group>

        <Form.Group className='my-2' as={Row} controlId='fullnames'>
          <Form.Label column sm={3}>
            Email
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              name='email'
              value={email}
              className='form-control form-control-sm'
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder='default@mamatwende.com'
            ></Form.Control>
          </Col>
        </Form.Group>

        <Form.Group className='my-2' as={Row} controlId='fullnames'>
          <Form.Label column sm={3}>
            Mobile
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              name='mobile'
              value={mobile}
              className='form-control form-control-sm'
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              placeholder='0700000000'
            ></Form.Control>
          </Col>
        </Form.Group>

        <Form.Group className='my-2' as={Row} controlId='ward'>
          <Form.Label column sm={3}>
            Role
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              as='select'
              name='role'
              value={role}
              className='form-control form-control-sm'
              onChange={(e) => {
                setRole(e.target.value);
              }}
              placeholder='0'
            >
              <option value={''}>--Select System Role--</option>
              <option key={1} value={1}>
                System User
              </option>
              <option key={2} value={2}>
                Group Admin
              </option>
              <option key={100} value={100}>
                System Admin
              </option>

              {/* {!constituenciesLoading &&
                    constituencies &&
                    constituencies.length &&
                    constituencies.map((constituency) => (
                      <option key={constituency.id} value={constituency.id}>
                        {constituency.name}
                      </option>
                    ))} */}
            </Form.Control>
          </Col>
        </Form.Group>

        <Button onClick={submitHandler} className='btn btn-sm btn-block'>
          Save And Close
        </Button>
      </Form>
    </div>
  );
};

export default Createuser;
