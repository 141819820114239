import React from 'react';
import './sidebar.css';
import { NavLink } from 'react-router-dom';
const Sidebar = () => {
  return (
    <>
      <div className='nav'>
        <h3 className='sidebarTitle d-none d-sm-inline'>System Admin </h3>
        <NavLink className='links active' exact={`${true}`} to='/meetings'>
          <i className='fas fa-users'></i>
          <span className='linkname d-none d-sm-inline'>Meetings</span>
        </NavLink>

        <NavLink className='links' exact={`${true}`} to='/systemadmin'>
          <i className='bi bi-person-fill-gear'></i>{' '}
          <span className='linkname d-none d-sm-inline'>Users</span>
        </NavLink>
        <NavLink className='links' exact={`${true}`} to='/list'>
          <i className='bi bi-people-fill'></i>{' '}
          <span className='linkname d-none d-sm-inline'>Members</span>
          <nav className='submenu'></nav>
        </NavLink>
        <NavLink className='links' exact={`${true}`} to='/configuration'>
          <i className='bi bi-person-fill-gear'></i>{' '}
          <span className='linkname d-none d-sm-inline'>Configuration</span>
        </NavLink>
      </div>
    </>
  );
};

export default Sidebar;
