import axios from 'axios';
import {
  REFUNDSCHEDULES_REQUEST,
  REFUNDSCHEDULES_REQUEST_SUCCESS,
  REFUNDSCHEDULES_REQUEST_FAIL,
  REFUNDSCHEDULE_INSERT,
  REFUNDSCHEDULE_INSERT_SUCCESS,
  REFUNDSCHEDULE_INSERT_FAIL,
  REFUNDSCHEDULE_DETAILS_REQUEST,
  REFUNDSCHEDULE_DETAILS_REQUEST_SUCCESS,
  REFUNDSCHEDULE_DETAILS_REQUEST_FAIL,
  REFUNDSCHEDULE_UPDATE_REQUEST,
  REFUNDSCHEDULE_UPDATE_SUCCESS,
  REFUNDSCHEDULE_UPDATE_FAIL
} from '../constants/refundscheduleConstant';

const config = {
  headers: {
    headers: { 'Content-Type': 'application/json' }
  }
};

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const insertRefundschedule = (refundschedule) => async (dispatch) => {
  dispatch({ type: REFUNDSCHEDULE_INSERT });
  const { data } = await axiosInstance.post(
    '/api/refundschedule/',
    refundschedule,
    config
  );
  try {
    dispatch({
      type: REFUNDSCHEDULE_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: REFUNDSCHEDULE_INSERT_FAIL, payload: error.message });
  }
};

export const getAllRefundschedules = (group) => async (dispatch) => {
  dispatch({ type: REFUNDSCHEDULES_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/refundschedules/${group}`);
    dispatch({
      type: REFUNDSCHEDULES_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: REFUNDSCHEDULES_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getRefundscheduleDetailsByID = (id) => async (dispatch) => {
  dispatch({ type: REFUNDSCHEDULE_DETAILS_REQUEST });

  try {
    const { data } = await axiosInstance.get(`/api/refundschedule/id/${id}`);

    dispatch({
      type: REFUNDSCHEDULE_DETAILS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: REFUNDSCHEDULE_DETAILS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const updateRefundscheduleDetails =
  (refundschedule) => async (dispatch) => {
    dispatch({ type: REFUNDSCHEDULE_UPDATE_REQUEST });

    try {
      const { data } = await axiosInstance.put(
        `/api/refundschedule/update/${refundschedule.id}`,
        refundschedule,
        config
      );
      dispatch({ type: REFUNDSCHEDULE_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: REFUNDSCHEDULE_UPDATE_FAIL,
        payload: error.message
      });
    }
  };

export const refundApprove = (refundschedule) => async (dispatch) => {
  dispatch({ type: REFUNDSCHEDULE_UPDATE_REQUEST });

  try {
    const { data } = await axiosInstance.put(
      `/api/refundschedule/approverefund/${refundschedule.id}`,
      refundschedule,
      config
    );
    dispatch({ type: REFUNDSCHEDULE_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REFUNDSCHEDULE_UPDATE_FAIL,
      payload: error.message
    });
  }
};

export const deleteRefundschedule = (id) => async (dispatch) => {};
