import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Barcode from 'react-barcode';
import {
  deleteStudent,
  getMemberByNationalID,
  getMemberDetailsByID,
  getStudentDetailsByID,
  insertStudent,
  updateStudentDetails
} from '../../studentAction';
import banknote from '../../assets/img/banknote.jpg';
import {
  getContributionByMeeting,
  insertContribution,
  getContributionByMemberID
} from '../../actions/contributionAction';

import FormContainer from '../../FormContainer';
import { useParams } from 'react-router-dom';
import Alert from '../../Alert';

import './addcontribution.css';
import MemberDashboard from '../dashboard/MemberDashboard';
const ReceiveContribution = () => {
  const { meeting, member } = useParams('meeting');
  const dispatch = useDispatch();
  const StudentDetails = useSelector((state) => state.StudentDetails);

  const { student, loading: detailsLoading } = StudentDetails;

  const [amount, setAmount] = useState(0);
  const [nationalid, setNationalId] = useState('');

  const studentUpdate = useSelector((state) => state.studentUpdate);

  const contributionInsert = useSelector((state) => state.contributionInsert);
  const contributionList = useSelector((state) => state.contributionList);

  const { contributions } = contributionList;

  const { success } = contributionInsert;

  const groupList = useSelector((state) => state.groupList);

  const { groups, loading: groupsLoading, error } = groupList;

  // useEffect(() => {
  //   if (!!detailsLoading)
  //     if (!!student && student?.id > 0)
  //       dispatch(getContributionByMemberID(student?.id));
  // }, [detailsLoading]);

  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    // setTimeout(() => {
    //   setA('');
    //   if (!!student && student?.id > 0)
    //     dispatch(getContributionByMemberID(student?.id));
    // }, 3000);
  }, [success]);

  // useEffect(() => {
  //   dispatch(getMemberDetailsByID(student?.id));
  // }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (student?.id)
      dispatch(insertContribution({ member: student?.id, meeting: 0, amount }));

    setAmount(0);

    // setTimeout(() => {
    //   if (!!student && student?.id > 0)
    //     dispatch(getMemberDetailsByID(student.id));
    // }, 3000);
    //Get contributions for a member for all meetings

    // Get contributions for Group during both Meeting and total Contributions
  };

  return (
    <div>
      <Row className='d-flex justify-content-center'>
        <Col className='col-12  col-md-8'>
          <h2 className='text-center'>
            RECEIVE MEMBER CONTRIBUTION
            <span className='d-none d-md-block' style={{ float: 'right' }}>
              <Link to={`/meeting/${meeting}`}>
                <Button className='btn btn-light btn-outline-danger btn-sm'>
                  Back to Meeting
                </Button>
              </Link>
            </span>
          </h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col className='col-8 offset-2 my-3'>
          <FormContainer>
            <Row>
              <Col className='mt-3'>
                <div className='col-12'>
                  {A && <Alert type='success' message={A} />}
                </div>
              </Col>
            </Row>
            <Form onSubmit={submitHandler} className='border1'>
              <Form.Group as={Row} controlId='fullnames'>
                <Form.Label column sm={12} md={3}>
                  National ID
                </Form.Label>
                <Col sm={12} md={9}>
                  <Form.Control
                    name='nationalid'
                    value={nationalid}
                    className='form-control form-control-sm'
                    onChange={(e) => {
                      dispatch(getMemberByNationalID(e.target.value));
                      setNationalId(e.target.value);
                    }}
                    placeholder='22000000'
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='fullnames'>
                <Form.Label column sm={12} md={3}>
                  Amount (Kshs)
                </Form.Label>
                <Col sm={12} md={9}>
                  <Form.Control
                    name='amount'
                    type='number'
                    className='form-control form-control-sm'
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    placeholder='0.00'
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group>
                <Form.Label className='d-flex justify-content-end'>
                  <h3 style={{ float: 'right' }}>
                    {amount && new Intl.NumberFormat().format(amount)}
                  </h3>
                </Form.Label>
              </Form.Group>
              <Form.Group>
                <Button
                  style={{ float: 'right' }}
                  onClick={submitHandler}
                  className='btn btn-sm'
                >
                  Receive Contribution
                </Button>
              </Form.Group>
            </Form>
          </FormContainer>
        </Col>
      </Row>

      <Row className='d-flex justify-content-center'>
        <Col className='col-lg-8 d-flex justify-content-center'>
          <hr />
        </Col>
      </Row>
      <Row className='d-flex justify-content-center align-items-center'>
        <Col className='d-flex justify-content-center col-8 col-md-6'>
          {!detailsLoading && student && student?.id > 0 ? (
            <MemberDashboard student={student} />
          ) : (
            <></>
          )}
        </Col>
        <Col className='d-flex mt-4 mt-md-0 justify-content-center col-8 col-md-6'>
          <Card className='summary '>
            <div
              style={{ backgroundImage: <banknote></banknote> }}
              className='contribution-card'
            >
              <i
                style={{ fontSize: '48px' }}
                className='fas fa-duotone fa-coins text-success'
              ></i>{' '}
            </div>
            <div>
              <Row>
                <Col className=''>
                  <hr />
                </Col>
              </Row>
            </div>

            <div className='amount text-center'>
              Total Contributions today{' '}
              <span className='text-success'>
                {contributions?.length > 0 &&
                  new Intl.NumberFormat().format(
                    contributions.reduce(
                      (acc, contribution) => acc + Number(contribution.amount),
                      0
                    )
                  )}{' '}
              </span>
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Row className='d-flex justify-content-center'>
        <Col className='d-flex justify-content-center mt-2 col-9'>
          <span className='d-block col-12 d-md-none'>
            <Link to={`/meeting/${meeting}`}>
              <Button className='btn  btn-sm btn-block'>Back to Meeting</Button>
            </Link>
          </span>
        </Col>
      </Row> */}
    </div>
  );
};

export default ReceiveContribution;
