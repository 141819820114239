import axios from 'axios';
import {
  WARDS_REQUEST,
  WARDS_REQUEST_SUCCESS,
  WARDS_REQUEST_FAIL,
  WARD_INSERT,
  WARD_INSERT_SUCCESS,
  WARD_INSERT_FAIL,
  WARD_DETAILS_REQUEST,
  WARD_DETAILS_REQUEST_SUCCESS,
  WARD_DETAILS_REQUEST_FAIL,
  WARD_UPDATE_REQUEST,
  WARD_UPDATE_SUCCESS,
  WARD_UPDATE_FAIL,
} from '../constants/wardConstant';

const config = {
  headers: {
    headers: { 'Content-Type': 'application/json' },
  },
};

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const insertWard = (ward) => async (dispatch) => {
  dispatch({ type: WARD_INSERT });
  const { data } = await axiosInstance.post('/api/ward/', ward, config);
  try {
    dispatch({
      type: WARD_INSERT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: WARD_INSERT_FAIL, payload: error.message });
  }
};

export const getAllWards = () => async (dispatch) => {
  dispatch({ type: WARDS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/wards`);
    dispatch({
      type: WARDS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WARDS_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const getWardDetailsByID = (id) => async (dispatch) => {
  dispatch({ type: WARD_DETAILS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/ward/id/${id}`);

    dispatch({
      type: WARD_DETAILS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WARD_DETAILS_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const updateWardDetails = (ward) => async (dispatch) => {
  dispatch({ type: WARD_UPDATE_REQUEST });
 
  try {
    const {data} = await axiosInstance.put(`/api/ward/update/${ward.id}`, ward, config);
    dispatch({ type: WARD_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: WARD_UPDATE_FAIL,
      payload: error.message,
    });
  }
};

export const deleteWard = (id) => async (dispatch) => {};
