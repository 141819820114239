import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table, Modal } from 'react-bootstrap';
import Createuser from './Createuser';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLogins } from '../../actions/loginAction';
import { LinkContainer } from 'react-router-bootstrap';
const Systemadmin = () => {
  const userList = useSelector((state) => state.userList);
  const dispatch = useDispatch();
  const { logins } = userList;
  useEffect(() => {
    dispatch(getAllLogins());
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8 mt-5'>
          <h3 className='text-center'>
            LIST OF SYSTEM USERS{' '}
            <span style={{ float: 'right' }}>
              {' '}
              <Button
                onClick={() => {
                  setShow(true);
                }}
                className='btn btn-sm btn-primary'
              >
                New User
              </Button>
            </span>{' '}
          </h3>
        </Col>
      </Row>

      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8 mt-5'>
          <hr></hr>
        </Col>
      </Row>

      <Row className='dflex mt-3 justify-content-center'>
        <Col className='col-8 dflex   justify-content-center'>
          <Table className='table table-responsive-md table-hover table-striped table-sm'>
            <thead>
              <tr>
                <th scope='col'>#</th>
                <th scope='col'>Username</th>
                <th scope='col'>Email</th>
                <th scope='col'>Mobile</th>
                <th scope='col'>Role</th>
              </tr>
            </thead>
            <tbody>
              {logins?.length > 0 &&
                logins?.map((r, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{r.name}</td>
                    <td>{r.email}</td>
                    <td>{r?.mobile}</td>
                    <td>
                      {r?.role == '1'
                        ? 'User'
                        : r?.role == '2'
                        ? 'Group Admin'
                        : r.role == '100'
                        ? 'System Admin'
                        : ''}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Createuser handleClose={handleClose} />
      </Modal>
    </div>
  );
};

export default Systemadmin;
