export const WARDS_REQUEST = 'WARD_REQUEST';
export const WARDS_REQUEST_SUCCESS = 'WARD_REQUEST_SUCCESS';
export const WARDS_REQUEST_FAIL = 'WARD_REQUEST_FAIL';

export const WARD_INSERT = 'WARD_INSERT';
export const WARD_INSERT_SUCCESS = 'WARD_INSERT_SUCCESS';
export const WARD_INSERT_FAIL = 'WARD_INSERT_FAIL';

export const WARD_UPDATE_REQUEST = 'WARD_UPDATE_REQUEST';
export const WARD_UPDATE_SUCCESS = 'WARD_UPDATE_SUCCESS';
export const WARD_UPDATE_FAIL = 'WARD_UPDATE_FAIL';

export const WARD_DELETE_REQUEST = 'WARD_DELETE_REQUEST';
export const WARD_DELETE_SUCCESS = 'WARD_DELETE_SUCCESS';
export const WARD_DELETE_FAIL = 'WARD_DELETE_FAIL';

export const WARD_DETAILS_REQUEST = 'WARD_DETAILS_REQUEST';
export const WARD_DETAILS_REQUEST_SUCCESS = 'WARD_DETAILS_REQUEST_SUCCESS';
export const WARD_DETAILS_REQUEST_FAIL = 'WARD_DETAILS_REQUEST_FAIL';
