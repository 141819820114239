import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../sidebar/Sidebar';
import './home.css';
import RequireAuth from '../RequireAuth';
const Home = () => {
  const user = localStorage.getItem('mamatwendelogin');
  const b = JSON.parse(user);

  const [auth, setAuth] = useState(false);

  useEffect(() => {
    if (b) {
      setAuth(true);
    }
  }, [b]);

  return (
    <div className='main'>
      {<Header />}

      {auth == true ? <Sidebar /> : ''}
      <main className='content-div mt-5'>{<RequireAuth />}</main>
    </div>
  );
};

export default Home;
