import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStudents } from './studentAction';
import { LinkContainer } from 'react-router-bootstrap';
import { MDBDataTableV5 } from 'mdbreact';
import Loader from './Loader';
import Message from './Message';
import Memberdetails from './components/mobilecards/Memberdetails';

const List = () => {
  const dispatch = useDispatch();
  const studentList = useSelector((state) => state.studentList);
  const userLogin = useSelector((state) => state.userLogin);

  const { login } = userLogin;

  const { students, loading, error } = studentList;

  const mamatwendeLogin = JSON.parse(localStorage.getItem('mamatwendelogin'));

  useEffect(() => {
    dispatch(getAllStudents(mamatwendeLogin.token));
  }, []);

  const handleDelete = () => {};

  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 100
      },

      {
        label: 'ID Number',
        field: 'idnumber',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Group',
        field: 'group',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Position',
        field: 'position',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Card',
        field: 'card',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Action',
        field: 'update',
        sort: 'asc',
        width: 10
      }
    ],
    rows:
      students &&
      students.length &&
      students.map((meeting) => {
        return {
          name: meeting.fullname,
          idnumber: meeting.idnumber,
          group: meeting.groupname,
          position: meeting.position,
          mobile: meeting.mobile,
          card: meeting.barcode,

          update: (
            <>
              <LinkContainer to={`/member/bio/${meeting.id}`}>
                <Button variant='success' className='btn btn-sm mr-2'>
                  <i className='fas fa-angle-double-right'></i>
                </Button>
              </LinkContainer>{' '}
              {'  '}
              <Button
                variant='danger'
                className='btn btn-sm mr-2'
                onClick={() => {
                  handleDelete(meeting.id);
                }}
              >
                <i className='fas fa-trash'></i>
              </Button>
            </>
          )
        };
      })
  };

  return (
    <div>
      <Row className='d-flex justify-content-center'>
        <Col className='col-12 pt-5'>
          <h2 className='text-center'>
            MAMA TWENDE - TUNAWEZA MEMBERS REGISTER
          </h2>

          <hr />
        </Col>
      </Row>

      {/* {students?.length > 0 &&
        students.map((s) => <Memberdetails student={s}></Memberdetails>)} */}

      {/* {students?.length >0 && } */}
      <Row className='d-flex justify-content-center'>
        <Col className='col-lg-8 col-md-6'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger' children={error} />
          ) : (
            <MDBDataTableV5
              small
              //cellEdit={cellEditFactory({ mode: 'click' })}

              entriesOptions={[5, 25, 50, 75, 100, 150, 200, 250, 300]}
              entries={5}
              hover
              striped
              responsiveSm
              sortable
              exportToCSV
              fullPagination
              data={data}
            />
          )}
        </Col>
      </Row>
      <Row className=''>
        <Col className='col-9 offset-1'>
          <span style={{ float: 'right' }}>
            <LinkContainer to='/import'>
              <Button className='btn btn-sm btn-success'>Import Members</Button>
            </LinkContainer>
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default List;
