import axios from 'axios';
import {
  GROUPS_REQUEST,
  GROUPS_REQUEST_SUCCESS,
  GROUPS_REQUEST_FAIL,
  GROUP_INSERT,
  GROUP_INSERT_SUCCESS,
  GROUP_INSERT_FAIL,
  GROUP_DETAILS_REQUEST,
  GROUP_DETAILS_REQUEST_SUCCESS,
  GROUP_DETAILS_REQUEST_FAIL,
  GROUP_UPDATE_REQUEST,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_FAIL
} from '../constants/groupConstant';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const config = {
  headers: {
    headers: { 'Content-Type': 'application/json' }
  }
};

export const insertGroup = (group, token) => async (dispatch) => {
  dispatch({ type: GROUP_INSERT });
  const { data } = await axiosInstance.post(
    `/api/group/`,
    { headers: { Authorization: `Bearer ${token}` } },
    group,
    config
  );
  try {
    dispatch({
      type: GROUP_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: GROUP_INSERT_FAIL, payload: error.message });
  }
};

export const getAllGroups = (token) => async (dispatch) => {
  dispatch({ type: GROUPS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/groups`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    dispatch({
      type: GROUPS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: GROUPS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getAllGroupsByward = (ward, token) => async (dispatch) => {
  dispatch({ type: GROUPS_REQUEST });

  try {
    const { data } = await axiosInstance.get(`/api/groups/ward/${ward}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    dispatch({
      type: GROUPS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: GROUPS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getGroupDetailsByID = (id, token) => async (dispatch) => {
  dispatch({ type: GROUP_DETAILS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/group/${id}`);
    dispatch({
      type: GROUP_DETAILS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: GROUP_DETAILS_REQUEST_FAIL,
      payload: error.message
    });
  }
};
export const getGroupDetailsByIDFinancials =
  (id, token) => async (dispatch) => {
    dispatch({ type: GROUP_DETAILS_REQUEST });
    try {
      const { data } = await axiosInstance.get(`/api/group/${id}`);
      dispatch({
        type: GROUP_DETAILS_REQUEST_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: GROUP_DETAILS_REQUEST_FAIL,
        payload: error.message
      });
    }
  };

export const updateGroupDetails = (group) => async (dispatch) => {
  dispatch({ type: GROUP_UPDATE_REQUEST });
  try {
    const res = await axiosInstance.put(
      `/api/group/${group.id}`,
      group,
      config
    );
    dispatch({ type: GROUP_UPDATE_SUCCESS, payload: 'success' });
  } catch (error) {
    dispatch({
      type: GROUP_UPDATE_FAIL,
      payload: error.message
    });
  }
};

export const deleteGroup = (id, token) => async (dispatch) => {};
