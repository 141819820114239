import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import FormContainer from '../FormContainer';
import Alert from '../Alert';
import { insertGroup } from '../actions/groupAction';
import { getAllWards, getWardDetailsByID } from '../actions/wardAction';
const AddGroup = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [ward, setWard] = useState('');

  const groupInsert = useSelector((state) => state.groupInsert);
  const wardList = useSelector((state) => state.wardList);

  const { wards, loading: wardsLoading } = wardList;

  useEffect(() => {
    dispatch(getAllWards());
  }, [dispatch]);

  const { success } = groupInsert;
  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
    }, 3000);
  }, [success]);

  const submitHandler = () => {
    if (!!ward) {
      if (!!name) {
        dispatch(
          insertGroup({
            name,
            ward
          })
        );
      }
    }
  };

  return (
    <div>
      <Row>
        <Col className='col-6 offset-3 mt-3 pt-3'>
          <h2 className='text-center'>
            ADD GROUP
            <span style={{ float: 'right' }}>
              <Link to='/groups'>
                <Button className='btn btn-light btn-sm'>All Groups</Button>
              </Link>
            </span>
          </h2>
          <hr />
        </Col>
      </Row>
      <Row></Row>
      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8 my-3'>
          <FormContainer>
            <Row>
              <Col className='mt-3'>
                <div className='col-12'>
                  {A && <Alert type='success' message={A} />}
                </div>
              </Col>
            </Row>
            <Form onSubmit={submitHandler} className='border1'>
              <Form.Group className='my-2' as={Row} controlId='fullnames'>
                <Form.Label column sm={3}>
                  GROUP NAME
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name='name'
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder='Group Name'
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group className='my-2' as={Row} controlId='ward'>
                <Form.Label column sm={3}>
                  CHOOSE WARD
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as='select'
                    name='ward'
                    value={ward}
                    onChange={(e) => {
                      setWard(e.target.value);
                    }}
                    placeholder='0'
                  >
                    {!wardsLoading &&
                      wards &&
                      wards.length &&
                      wards.map((ward) => (
                        <option key={ward.id} value={ward.id}>
                          {ward.name}
                        </option>
                      ))}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Button
                style={{ float: 'right' }}
                onClick={submitHandler}
                className='btn btn-sm'
              >
                SAVE
              </Button>
            </Form>
          </FormContainer>
        </Col>
      </Row>
    </div>
  );
};

export default AddGroup;
