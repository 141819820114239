export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAIL = 'LOGIN_REQUEST_FAIL';

export const LOGIN_INSERT = 'LOGIN_INSERT';
export const LOGIN_INSERT_SUCCESS = 'LOGIN_INSERT_SUCCESS';
export const LOGIN_INSERT_FAIL = 'LOGIN_INSERT_FAIL';

export const LOGIN_LOGOUT = 'LOGIN_LOGOUT';

export const LOGIN_UPDATE_REQUEST = 'LOGIN_UPDATE_REQUEST';
export const LOGIN_UPDATE_REQUEST_SUCCESS = 'LOGIN_UPDATE_REQUEST_SUCCESS';
export const LOGIN_UPDATE_REQUEST_FAIL = 'LOGIN_UPDATE_REQUEST_FAIL';

export const LOGIN_DETAILS_REQUEST = 'LOGIN_DETAILS_REQUEST';
export const LOGIN_DETAILS_REQUEST_SUCCESS = 'LOGIN_DETAILS_REQUEST_SUCCESS';
export const LOGIN_DETAILS_REQUEST_FAIL = 'LOGIN_DETAILS_REQUEST_FAIL';
