export const STUDENT_REQUEST = 'STUDENT_REQUEST';
export const STUDENT_REQUEST_SUCCESS = 'STUDENT_REQUEST_SUCCESS';
export const STUDENT_REQUEST_FAIL = 'STUDENT_REQUEST_FAIL';

export const STUDENT_INSERT = 'STUDENT_INSERT';
export const STUDENT_INSERT_SUCCESS = 'STUDENT_INSERT_SUCCESS';
export const STUDENT_INSERT_FAIL = 'STUDENT_INSERT_FAIL';

export const STUDENT_UPDATE_REQUEST = 'STUDENT_UPDATE_REQUEST';
export const STUDENT_UPDATE_SUCCESS = 'STUDENT_UPDATE_SUCCESS';
export const STUDENT_UPDATE_FAIL = 'STUDENT_UPDATE_FAIL';

export const STUDENT_DELETE_REQUEST = 'STUDENT_DELETE_REQUEST';
export const STUDENT_DELETE_SUCCESS = 'STUDENT_DELETE_SUCCESS';
export const STUDENT_DELETE_FAIL = 'STUDENT_DELETE_FAIL';

export const STUDENT_DETAILS_REQUEST = 'STUDENT_DETAILS_REQUEST';
export const STUDENT_DETAILS_REQUEST_SUCCESS =
  'STUDENT_DETAILS_REQUEST_SUCCESS';
export const STUDENT_DETAILS_REQUEST_FAIL = 'STUDENT_DETAILS_REQUEST_FAIL';

export const INVITE_REQUEST = 'INVITE_REQUEST';
export const INVITE_REQUEST_SUCCESS = 'INVITE_REQUEST_SUCCESS';
export const INVITE_REQUEST_FAIL = 'INVITE_REQUEST_FAIL';
