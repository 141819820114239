import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Barcode from 'react-barcode';
import {
  deleteStudent,
  getMemberDetailsByID,
  getStudentDetailsByID,
  insertStudent,
  updateStudentDetails
} from '../../studentAction';
import banknote from '../../assets/img/banknote.jpg';
import {
  getContributionByMeeting,
  insertContribution,
  getContributionByMemberID
} from '../../actions/contributionAction';

import FormContainer from '../../FormContainer';
import { useParams } from 'react-router-dom';
import Alert from '../../Alert';

import './addcontribution.css';
import MemberDashboard from '../dashboard/MemberDashboard';
const Addcontribution = () => {
  const { meeting, member } = useParams('meeting');
  const dispatch = useDispatch();

  const [amount, setAmount] = useState(0);

  const studentUpdate = useSelector((state) => state.studentUpdate);

  const contributionInsert = useSelector((state) => state.contributionInsert);
  const contributionList = useSelector((state) => state.contributionList);

  const { contributions } = contributionList;

  const { success } = contributionInsert;

  const groupList = useSelector((state) => state.groupList);

  const { groups, loading: groupsLoading, error } = groupList;

  useEffect(() => {
    dispatch(getContributionByMeeting(meeting));
    dispatch(getContributionByMemberID(member));
  }, []);

  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
      dispatch(getContributionByMeeting(meeting));
      dispatch(getContributionByMemberID(member));
    }, 3000);
  }, [success]);
  useEffect(() => {
    dispatch(getMemberDetailsByID(member));
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(insertContribution({ member, meeting, amount }));

    setAmount(0);

    setTimeout(() => {
      dispatch(getMemberDetailsByID(student.id));
    }, 3000);
    //Get contributions for a member for all meetings

    // Get contributions for Group during both Meeting and total Contributions
  };

  const StudentDetails = useSelector((state) => state.StudentDetails);

  const { student, loading: detailsLoading } = StudentDetails;

  const handleDelete = () => {
    if (!!member) {
      dispatch(deleteStudent(member));
    }
  };

  return (
    <div>
      <Row className='d-flex justify-content-center'>
        <Col className='col-12  '>
          <h2 className='text-center'>
            RECEIVE MEMBER CONTRIBUTION
            <span style={{ float: 'right' }}>
              <Link to={`/meeting/${meeting}`}>
                <Button className='btn btn-light btn-outline-danger btn-sm'>
                  Back to Meeting
                </Button>
              </Link>
            </span>
          </h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col className='col-8 offset-2 my-3'>
          <FormContainer>
            <Row>
              <Col className='mt-3'>
                <div className='col-12'>
                  {A && <Alert type='success' message={A} />}
                </div>
              </Col>
            </Row>
            <Form onSubmit={submitHandler} className='border1'>
              <Form.Group className='my-2' as={Row} controlId='fullnames'>
                <Form.Label column sm={3}>
                  Amount (Kshs)
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name='amount'
                    type='number'
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    placeholder='0.00'
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group>
                <Form.Label className='d-flex justify-content-end'>
                  <h3 style={{ float: 'right' }}>
                    {amount && new Intl.NumberFormat().format(amount)}
                  </h3>
                </Form.Label>
              </Form.Group>
              <Form.Group>
                <Button
                  style={{ float: 'right' }}
                  onClick={submitHandler}
                  className='btn btn-sm'
                >
                  Receive Contribution
                </Button>
              </Form.Group>
            </Form>
          </FormContainer>
        </Col>
      </Row>
      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8 dflex justify-content-center'>
          <hr />
        </Col>
      </Row>
      <Row className='d-flex justify-content-center align-items-center'>
        {student && <MemberDashboard student={student} />}

        <Col className='col-6  d-flex justify-content-center align-items-center'>
          <Card className='summary'>
            <div
              style={{ backgroundImage: <banknote></banknote> }}
              className='contribution-card'
            >
              <i
                style={{ fontSize: '48px' }}
                className='fas fa-duotone fa-coins text-success'
              ></i>{' '}
            </div>
            <div>
              <Row>
                <Col className=''>
                  <hr />
                </Col>
              </Row>
            </div>

            <div className='amount text-center'>
              Total Contributions today{' '}
              <span className='text-success'>
                {contributions?.length > 0 &&
                  new Intl.NumberFormat().format(
                    contributions.reduce(
                      (acc, contribution) => acc + Number(contribution.amount),
                      0
                    )
                  )}{' '}
              </span>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Addcontribution;
