import axios from 'axios';
import {
  CONSTITUENCIES_REQUEST,
  CONSTITUENCIES_REQUEST_SUCCESS,
  CONSTITUENCIES_REQUEST_FAIL,
  CONSTITUENCY_INSERT,
  CONSTITUENCY_INSERT_SUCCESS,
  CONSTITUENCY_INSERT_FAIL,
  CONSTITUENCY_DETAILS_REQUEST,
  CONSTITUENCY_DETAILS_REQUEST_SUCCESS,
  CONSTITUENCY_DETAILS_REQUEST_FAIL,
  CONSTITUENCY_UPDATE_REQUEST,
  CONSTITUENCY_UPDATE_SUCCESS,
  CONSTITUENCY_UPDATE_FAIL,
} from '../constants/constituencyConstant';

const config = {
  headers: {
    headers: { 'Content-Type': 'application/json' },
  },
};

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const insertConstituency = (constituency) => async (dispatch) => {
  dispatch({ type: CONSTITUENCY_INSERT });
  const { data } = await axiosInstance.post('/api/constituency/', constituency, config);
  try {
    dispatch({
      type: CONSTITUENCY_INSERT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: CONSTITUENCY_INSERT_FAIL, payload: error.message });
  }
};

export const getAllConstituency = () => async (dispatch) => {
  dispatch({ type: CONSTITUENCIES_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/constituencies`);
    dispatch({
      type: CONSTITUENCIES_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONSTITUENCIES_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const getConstituencyDetailsByID = (id) => async (dispatch) => {
  dispatch({ type: CONSTITUENCY_DETAILS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/constituency/${id}`);

    dispatch({
      type: CONSTITUENCY_DETAILS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONSTITUENCY_DETAILS_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const updateConstituencyDetails = (constituency) => async (dispatch) => {
  dispatch({ type: CONSTITUENCY_UPDATE_REQUEST });
  try {
    const res = await axiosInstance.put(`/api/constituency/${constituency}`, constituency.id, config);
    dispatch({ type: CONSTITUENCY_UPDATE_SUCCESS, payload: 'success' });
  } catch (error) {
    dispatch({
      type: CONSTITUENCY_UPDATE_FAIL,
      payload: error.message,
    });
  }
};

export const deleteWard = (id) => async (dispatch) => {};
