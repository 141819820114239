import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import {
  getAllLoanRepayment,
  getLoanDetailsByID,
  insertRepayment
} from '../../actions/loanAction';

import './Addloan.css';

const AddRepayment = ({ balance, handleClose }) => {
  const dispatch = useDispatch();

  const params = useParams('loan');

  const loan = params.id;
  // const [meeting, setMeeting] = useState('');
  // const [member, setMember] = useState('');
  const [principal, setPrincipal] = useState(1);
  const [interestrate, setInterestrate] = useState(1);
  const [type, setType] = useState('M');
  const [period, setPeriod] = useState(1);
  const [repayments, setRepayments] = useState([]);
  const [amount, setAmount] = useState(0);

  const loanInsert = useSelector((state) => state.loanInsert);

  const StudentDetails = useSelector((state) => state.StudentDetails);

  const { student, loading: studentLoading } = StudentDetails;

  const meetingFinancials = useSelector((state) => state.meetingFinancials);
  const { meetingfinancial } = meetingFinancials;

  const { success } = loanInsert;
  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);

    if (success !== '') {
      toast('Repayment Captured successfuly');
    }

    // setTimeout(() => {
    //   setA('');
    // }, 3000);
  }, [success]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (!!loan) {
      if (Number(amount) <= Number(balance)) {
        dispatch(
          insertRepayment({
            loan,
            amount
          })
        );
        setAmount(0);
        dispatch(getLoanDetailsByID(loan));
        dispatch(getAllLoanRepayment(loan));
        setTimeout(() => {
          handleClose();
        }, 1000);
      } else {
        alert('Repayment Cannot be more that outstanding balance');
      }
    }
  };

  const data = {
    columns: [
      {
        label: '#',
        field: 'repayment',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Repayment',
        field: 'amount',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Interest Part',
        field: 'interest',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Principal Part',
        field: 'principal',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Outstanding',
        field: 'balance',
        sort: 'asc',
        width: 100
      }
    ],
    rows:
      repayments &&
      repayments?.length &&
      repayments.map((r, idx) => {
        return {
          repayment: idx + 1,
          amount: new Intl.NumberFormat().format(r.installment),
          interest: new Intl.NumberFormat().format(r.interest),
          principal: new Intl.NumberFormat().format(r.capital),
          balance: new Intl.NumberFormat().format(r.remain)
        };
      })
  };

  return (
    <div>
      {/*       <Row className='d-flex justify-content-center'>
        <Col>{JSON.stringify(student)}</Col>
      </Row> */}

      <div className='col-12'>
        {A && (
          <ToastContainer
            toast='Meeting Added successfuly'
            position='top-right'
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
          />
        )}
      </div>

      <Form onSubmit={submitHandler} className='border1 p-5'>
        Repayment Amount
        <Form.Control
          className='form-control form-control-sm my-3'
          name='amount'
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          type='number'
          placeholder='0.00'
        ></Form.Control>
        <Form.Group>
          <Form.Label className='d-flex justify-content-end'>
            <label>
              <h4>{new Intl.NumberFormat().format(amount)}</h4>
            </label>
          </Form.Label>
        </Form.Group>
        <Form.Group>
          <Button
            disabled={
              meetingfinancial?.length > 0 &&
              Number(
                meetingfinancial
                  .filter((m) => m.type == 'contribution')
                  .reduce((acc, m) => acc + Number(m.amount), 0) -
                  Number(
                    meetingfinancial
                      .filter((m) => m.type == 'loan')
                      .reduce((acc, m) => acc + Number(m.amount), 0)
                  ) <
                  Number(principal)
              )
            }
            style={{ float: 'right' }}
            onClick={submitHandler}
            className='btn btn-sm btn-block'
          >
            Save Repayment
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default AddRepayment;
