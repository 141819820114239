import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Button, Form, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  changeStatus,
  deleteStudent,
  getMemberDetailsByID,
  updateStudentDetails
} from '../studentAction';
import { useParams } from 'react-router-dom';
import Alert from '../Alert';
import { getAllGroups } from '../actions/groupAction';
import { LinkContainer } from 'react-router-bootstrap';
import MemberDashboard from './dashboard/MemberDashboard';
import { getContributionByMemberID } from '../actions/contributionAction';
import {
  approveRefunds,
  getRefundscheduleDetailsByID,
  refundApprove
} from '../actions/refundscheduleAction';

const ApproveRefund = () => {
  const { id } = useParams('id');

  const dispatch = useDispatch();
  const [fullname, setFullname] = useState('');
  const [age, setAge] = useState(0);
  const [groupid, setGroup] = useState('');
  const [idnumber, setIdnumber] = useState('');
  const [mobile, setMobile] = useState('');
  const [position, setPosition] = useState('');
  const [occupation, setOccupation] = useState('');
  const [estate, setEstate] = useState('');
  const [ward, setWard] = useState('');
  const [statusDate, setStatusdate] = useState(new Date());
  const [status, setStatus] = useState('1');

  const studentUpdate = useSelector((state) => state.studentUpdate);
  const contributionList = useSelector((state) => state.contributionList);

  const refundscheduleDetails = useSelector(
    (state) => state.refundscheduleDetails
  );

  const [member, setMember] = useState('');

  const { refundschedule } = refundscheduleDetails;

  const { contributions } = contributionList;

  let login = localStorage.getItem('mamatwendelogin');

  let token = JSON.parse(login).token;

  const { success } = studentUpdate;
  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
    }, 3000);
  }, [success]);

  useEffect(() => {
    dispatch(getRefundscheduleDetailsByID(id));
  }, []);

  useEffect(() => {
    if (refundschedule?.member && refundschedule?.member > 0) {
      dispatch(getMemberDetailsByID(refundschedule?.member));
      dispatch(getContributionByMemberID(refundschedule?.member));
    }
  }, [refundschedule]);

  const submitHandler = () => {
    if (!!id) {
      if (!!fullname && fullname !== '') {
        if (!!idnumber && idnumber !== '') {
          if (!!mobile && mobile !== '') {
            if (!!groupid && groupid !== '') {
              dispatch(
                updateStudentDetails(
                  {
                    fullname,
                    age,
                    idnumber,
                    mobile,
                    occupation,
                    groupid,
                    ward,
                    estate,
                    position,
                    id
                  },
                  token
                )
              );
            } else {
              alert('Please select group');
            }
          } else {
            alert('Mobile Number Required');
          }
        } else {
          alert('ID Number Required');
        }
      } else {
        alert('Full Name required');
      }
    }
  };
  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current
  // });

  const StudentDetails = useSelector((state) => state.StudentDetails);

  const { student, loading: detailsLoading } = StudentDetails;

  useEffect(() => {
    if (student.id == id) {
      setFullname(student.fullname);
      setIdnumber(student.idnumber);
      setAge(student.age);
      setMobile(student.mobile);
      setOccupation(student.occupation);
      setGroup(student.groupid);
      setWard(student.ward);
      setEstate(student.estate);
      setPosition(student.position);
    }
  }, [student]);

  const handleDelete = () => {
    if (!!id) {
      dispatch(deleteStudent(id));
    }
  };

  const approveRefund = (e) => {
    e.preventDefault();
    dispatch(
      refundApprove({
        contributionIds: contributions.map((c) => c.id),
        id: id,
        amountapproved:
          contributions?.length > 0 &&
          contributions?.reduce((acc, r) => acc + Number(r.amount), 0),
        approvedby: 1,
        dateapproved: new Date()
      })
    );
  };

  return (
    <div>
      <Row className='d-flex justify-content-center'>
        <Col className='col-4'>
          <h3 className='text-center'>Contributions</h3>
        </Col>
      </Row>

      <Row className='d-flex justify-content-center'>
        <Col className='col-4'>
          {contributions?.length > 0 && (
            <Table className='table-sm table-hover table-striped'>
              <thead>
                <tr>
                  <th scope='col'>#</th>

                  <th scope='col'>Contribution Date</th>
                  <th scope='col'>Contribution Amount</th>
                </tr>
              </thead>
              <tbody>
                {contributions?.length > 0 &&
                  contributions?.map((r, idx) => (
                    <tr>
                      <td>{idx + 1}</td>

                      <td>{new Date(r?.date).toDateString()}</td>
                      <td>{new Intl.NumberFormat().format(r?.amount)}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          <Button onClick={approveRefund} className='btn btn-primary'>
            Approve Refund
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
    </div>
  );
};

export default ApproveRefund;
