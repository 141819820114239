import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Wards from '../Wards';
import Constituency from '../Constituency';
import Groups from '../Groups';
const Sysadmin = () => {
  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>GROUP ADMINISTRATION</Tab>
          <Tab>Ward ADMINISTRATION</Tab>
          <Tab>CONSTITUENCY ADMINISTRATION</Tab>
        </TabList>
        <TabPanel>
          <Groups />
        </TabPanel>
        <TabPanel>
          <Wards />
        </TabPanel>
        <TabPanel>
          <Constituency />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Sysadmin;
