import React, { useEffect } from 'react';
import './dashboard.css';
import { useSelector, useDispatch } from 'react-redux';
import { getMemberDetailsByID } from '../../studentAction';
const MemberDashboard = () => {
  const StudentDetails = useSelector((state) => state.StudentDetails);
  const dispatch = useDispatch();
  const { student, loading: studentLoading } = StudentDetails;

  // useEffect(() => {
  //   if (student?.id > 0) {
  //     dispatch(getMemberDetailsByID(student.id));
  //   }
  // }, []);

  return (
    <div className='card'>
      <div className='card-row'>
        <div className='card-label'>Group</div>
        <div className='card-data'>{student?.name}</div>
      </div>
      <div className='card-row'>
        <div className='card-label'>Name</div>
        <div className='card-data'>{student?.fullname}</div>
      </div>
      <div className='card-row'>
        <div className='card-label'>ID #</div>
        <div className='card-data'>{student?.idnumber}</div>
      </div>
      <div className='card-row'>
        <div className='card-label'>Mobile</div>
        <div className='card-data'>+254{student?.mobile}</div>
      </div>
      <div className='card-row'>
        <div className='card-label'>Outstanding Loans Total</div>
        <div className='card-data loan'>
          {new Intl.NumberFormat().format(student?.totalloan)}
        </div>
      </div>

      <div className='card-row'>
        <div className='card-label'>Total Contributions</div>
        <div className='card-data contribution'>
          {new Intl.NumberFormat().format(student?.contributions)}
        </div>
      </div>
    </div>
  );
};

export default MemberDashboard;
