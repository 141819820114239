import axios from 'axios';
import {
  TRANSACTIONS_REQUEST,
  TRANSACTIONS_REQUEST_SUCCESS,
  TRANSACTIONS_REQUEST_FAIL,
  TRANSACTION_DETAILS_REQUEST,
  TRANSACTION_DETAILS_REQUEST_SUCCESS,
  TRANSACTION_DETAILS_REQUEST_FAIL
} from '../constants/transactionConstant';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const config = {
  headers: {
    headers: { 'Content-Type': 'application/json' }
  }
};

export const getMamatwendestatus = () => async (dispatch) => {
  dispatch({ type: TRANSACTIONS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/group/transactions/`);
    dispatch({
      type: TRANSACTIONS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: TRANSACTIONS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getAllGroupTransactions = (group) => async (dispatch) => {
  dispatch({ type: TRANSACTIONS_REQUEST });
  try {
    const { data } = await axiosInstance.get(
      `/api/group/transactions/${group}`
    );
    dispatch({
      type: TRANSACTIONS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: TRANSACTIONS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getTransactionByID = (id) => async (dispatch) => {
  dispatch({ type: TRANSACTION_DETAILS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/group/transaction/${id}`);

    dispatch({
      type: TRANSACTION_DETAILS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: TRANSACTION_DETAILS_REQUEST_FAIL,
      payload: error.message
    });
  }
};
