export const EXPENSES_REQUEST = 'EXPENSE_REQUEST';
export const EXPENSES_REQUEST_SUCCESS = 'EXPENSE_REQUEST_SUCCESS';
export const EXPENSES_REQUEST_FAIL = 'EXPENSE_REQUEST_FAIL';

export const EXPENSE_INSERT = 'EXPENSE_INSERT';
export const EXPENSE_INSERT_SUCCESS = 'EXPENSE_INSERT_SUCCESS';
export const EXPENSE_INSERT_FAIL = 'EXPENSE_INSERT_FAIL';

export const EXPENSE_UPDATE_REQUEST = 'EXPENSE_UPDATE_REQUEST';
export const EXPENSE_UPDATE_SUCCESS = 'EXPENSE_UPDATE_SUCCESS';
export const EXPENSE_UPDATE_FAIL = 'EXPENSE_UPDATE_FAIL';

export const EXPENSE_DELETE_REQUEST = 'EXPENSE_DELETE_REQUEST';
export const EXPENSE_DELETE_SUCCESS = 'EXPENSE_DELETE_SUCCESS';
export const EXPENSE_DELETE_FAIL = 'EXPENSE_DELETE_FAIL';

export const EXPENSE_DETAILS_REQUEST = 'EXPENSE_DETAILS_REQUEST';
export const EXPENSE_DETAILS_REQUEST_SUCCESS =
  'EXPENSE_DETAILS_REQUEST_SUCCESS';
export const EXPENSE_DETAILS_REQUEST_FAIL = 'EXPENSE_DETAILS_REQUEST_FAIL';
