import axios from 'axios';
import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAIL,
  LOGIN_INSERT,
  LOGIN_INSERT_SUCCESS,
  LOGIN_INSERT_FAIL,
  LOGIN_LOGOUT,
  LOGIN_UPDATE_REQUEST,
  LOGIN_UPDATE_REQUEST_SUCCESS,
  LOGIN_UPDATE_REQUEST_FAIL
} from '../constants/loginConstants';
const config = {
  headers: {
    headers: { 'Content-Type': 'application/json' }
  }
};
const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const insertLogin = (login, token) => async (dispatch) => {
  login.author = 1;

  dispatch({ type: LOGIN_INSERT });
  const { data } = await axiosInstance.post(
    '/api/admin/user',
    login,
    {
      headers: { Authorization: `Bearer ${token}` }
    },
    config
  );
  try {
    dispatch({
      type: LOGIN_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: LOGIN_INSERT_FAIL, payload: error.message });
  }
};

export const updateLogin = (login, token) => async (dispatch) => {
  login.author = 1;

  dispatch({ type: LOGIN_UPDATE_REQUEST });
  const { data } = await axiosInstance.put(
    `/api/admin/user/edit/${login.id}`,
    {
      headers: { Authorization: `Bearer ${token}` }
    },
    login,
    config
  );
  try {
    dispatch({
      type: LOGIN_UPDATE_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: LOGIN_UPDATE_REQUEST_FAIL, payload: error.message });
  }
};

export const auserLogin = (username, password) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const { data } = await axiosInstance.post(
      `/api/user/login/`,
      { username, password },
      config
    );

    dispatch({
      type: LOGIN_REQUEST_SUCCESS,
      payload: data
    });
    localStorage.setItem('mamatwendelogin', JSON.stringify(data));
    sessionStorage.setItem('mamatwendelogin', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: LOGIN_REQUEST_FAIL,
      payload: 'Invalid Credentials/Try Again!'
    });
  }
};
export const getLoginToken = (id) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const { data } = await axiosInstance.get(
      `/api/admin/user/logintoken/${id}`
    );
    dispatch({
      type: LOGIN_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: LOGIN_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({ type: LOGIN_LOGOUT });
  localStorage.clear();
};

export const getLogin = (id) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const { data } = await axiosInstance.get(`/api/admin/user/login/${id}`);
    dispatch({
      type: LOGIN_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: LOGIN_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getAllLogins = (token) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const { data } = await axiosInstance.get(`/api/admin/users`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    dispatch({
      type: LOGIN_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: LOGIN_REQUEST_FAIL,
      payload: error.message
    });
  }
};
