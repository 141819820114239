import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getAllGroups, getGroupDetailsByID } from './actions/groupAction';
import { getAllStudentsByGroup } from './studentAction';
import { MDBDataTableV5 } from 'mdbreact';
import Loader from './Loader';
import Message from './Message';
const ReportGroupMembers = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const groupList = useSelector((state) => state.groupList);
  const studentList = useSelector((state) => state.studentList);
  const { students, loading: studentsLoading } = studentList;
  const groupDetails = useSelector((state) => state.groupDetails);

  const { group, loading: groupLoading } = groupDetails;

  const { groups, loading: groupsLoading, error } = groupList;

  const id = props.id;

  useEffect(() => {
    dispatch(getGroupDetailsByID(id));
    dispatch(getAllStudentsByGroup(id));
  }, []);
  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 100,
      },

      {
        label: 'ID Number',
        field: 'idnumber',
        sort: 'asc',
        width: 100,
      },

      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc',
        width: 100,
      },

      {
        label: 'Position',
        field: 'position',
        sort: 'asc',
        width: 100,
      },
    ],
    rows:
      students &&
      students.length &&
      students.map((meeting) => {
        return {
          name: meeting.fullname,
          idnumber: meeting.idnumber,
          position: meeting.position,
          mobile: meeting.mobile,
        };
      }),
  };
  return (
    <div ref={ref}>
      <Row>
        <div>
          <Row>
            <Col className='col-8 offset-2 my-5 pt-4'>
              <h2 className='text-center'>{group.name} MEMBERS REGISTER</h2>

              <hr />
            </Col>
          </Row>
          <Row>
            <Col className='col-8 offset-2'>
              {studentsLoading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger' children={error} />
              ) : (
                students &&
                students.length ? (
                  <MDBDataTableV5
                    data={data}
                    searching={false}
                    paging={false}
                  />
                ) : <Row><h3>No Records Found</h3></Row>
              )}
            </Col>
          </Row>
        </div>{' '}
      </Row>
    </div>
  );
});

export default ReportGroupMembers;
