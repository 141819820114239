import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Barcode from "react-barcode";
import { getAllStudentsByGroup } from "../studentAction";
import { useParams } from "react-router-dom";

const MemberCardNew = () => {
  const dispatch = useDispatch();
  const { id } = useParams("id");
  const mamatwendeLogin = JSON.parse(localStorage.getItem("mamatwendelogin"));

  useEffect(() => {
    dispatch(getAllStudentsByGroup(id, mamatwendeLogin.token));
  }, []);

  const studentList = useSelector((state) => state.studentList);
  const { students } = studentList;
  return (
    <div>
      <Row className="col-12">
        {students?.map((meeting) => {
          var y = meeting.fullname.split(" ").slice(0, 2).join(" ");
          return (
            <Col className="col-4">
              <Row>
                <br />
                <br />
              </Row>
              <Card>
                <h2 className="text-center">MEMBER CARD</h2>
                <hr />
                <h3 className="text-center">{y.toUpperCase()}</h3>
                <h4 className="text-center">
                  {meeting.position.toUpperCase()}
                </h4>
                <h5 className="text-center">{meeting.groupname}</h5>
                <Row className="text-center">
                  <Form.Group className="my-2" as={Row}>
                    <Col sm={8}>
                      <Form.Control
                        readOnly="true"
                        className="text-center"
                        value={"ID Number : " + meeting.idnumber}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group className="my-1" as={Row}>
                    <Form.Label column sm={4}>
                      Ward :
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        readOnly="true"
                        className="text-center"
                        value={meeting.ward}
                        placeholder="0"
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Col>
                    <Barcode format="code39" value={meeting.barcode} />
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default MemberCardNew;
