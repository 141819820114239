import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';

import { LinkContainer } from 'react-router-bootstrap';

import { getMamatwendestatus } from '../../actions/transactionAction';
import './transaction.css';
const Transaction = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMamatwendestatus());
  }, []);
  const transactionList = useSelector((state) => state.transactionList);

  const { transactions, error, loading } = transactionList;
  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this record?')) {
      //   dispatch(deleteMeeting(id));
    }
  };
  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 100
      },

      {
        label: 'ID #',
        field: 'venue',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Amount(KSh)',
        field: 'purpose',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 100
      }
    ],
    rows:
      transactions &&
      transactions?.length &&
      transactions.map((meeting) => {
        return {
          name: meeting.fullname,
          venue: meeting.idnumber,
          purpose: new Intl.NumberFormat().format(meeting.amount),
          date: new Date(meeting.date).toLocaleDateString(),
          update: (
            <>
              <LinkContainer to={`/meeting/${meeting.id}`}>
                <Button variant='success' className='btn-sm mr-2'>
                  Details <i className='fas fa-angle-double-right'></i>
                </Button>
              </LinkContainer>{' '}
              {'  '}
              <Button
                variant='danger'
                className='btn-sm mr-2'
                onClick={() => {
                  handleDelete(meeting.id);
                }}
              >
                Delete <i className='fas fa-trash'></i>
              </Button>
            </>
          )
        };
      })
  };
  return (
    <>
      <Row>
        <Col className='mt-5 pt-5 col-10 dflex justify-content-center'>
          <h3 className='dash-h1 text-center'>
            <span className='dash-h1-highlight'>DASHBOARD</span>
            {/* <Title cla title={'MAMA TWENDE DASHBOARD '}></Title> */}
          </h3>
        </Col>
      </Row>
      {/* <Row className='dflex justify-content-center'>
        <Col className='col-lg-8 dflex justify-content-center'>
          <div className='card'>
            <label>
              Total Collections All Groups: Kshs{' '}
              <span style={{ float: 'right' }}>
                {transactions?.length > 0
                  ? new Intl.NumberFormat().format(
                      transactions.filter(
                        (t) => t.summarytype == 'contribution'
                      )[0].amount
                    )
                  : 0}
              </span>
            </label>
          </div>
          <div className='card'>
            <label>
              Total Loans Disbursed: Kshs{' '}
              <span style={{ float: 'right' }}>
                {transactions?.length > 0
                  ? new Intl.NumberFormat().format(
                      transactions.filter((t) => t.summarytype == 'loans')[0]
                        .amount
                    )
                  : 0}
              </span>
            </label>
          </div>
          <div className='card'>
            <label>
              Funds Available for LOANS
              <span style={{ float: 'right' }}>
                {transactions?.length > 0
                  ? new Intl.NumberFormat().format(
                      transactions.filter(
                        (t) => t.summarytype == 'contribution'
                      )[0].amount -
                        transactions.filter((t) => t.summarytype == 'loans')[0]
                          .amount
                    )
                  : 0}{' '}
              </span>
            </label>
          </div>
        </Col>
      </Row> */}

      <Row className='details mt-5'>
        <Col className='dash-col'>
          <label className='dash-label'>
            Total Collections All Groups: Kshs{' '}
          </label>
          <div className='dash-stat'>
            {transactions?.length > 0
              ? new Intl.NumberFormat().format(
                  transactions.filter((t) => t.summarytype == 'contribution')[0]
                    ?.amount
                )
              : 0}
          </div>
        </Col>
        <Col className='dash-col'>
          <label className='dash-label'>Total Loans Disbursed: Kshs </label>
          <div className='dash-stat'>
            {transactions?.length > 0
              ? new Intl.NumberFormat().format(
                  transactions.filter((t) => t.summarytype == 'loans')[0].amount
                )
              : 0}
          </div>
        </Col>
        <Col className='dash-col'>
          <label className='dash-label'>Funds Available for LOANS</label>
          <div className='dash-stat'>
            {transactions?.length > 0
              ? new Intl.NumberFormat().format(
                  transactions.filter((t) => t.summarytype == 'contribution')[0]
                    ?.amount -
                    transactions.filter((t) => t.summarytype == 'loans')[0]
                      ?.amount +
                    transactions.filter((t) => t.summarytype == 'repayments')[0]
                      ?.amount
                )
              : 0}{' '}
          </div>
        </Col>
      </Row>

      <Row className='details'>
        <Col className='dash-col'>
          <label className='dash-label'>Total Number of Members</label>
          <div className='dash-stat'>
            {transactions?.length > 0
              ? new Intl.NumberFormat().format(
                  transactions.filter((t) => t.summarytype == 'members')[0]
                    ?.amount
                )
              : 0}
          </div>
        </Col>
        <Col className='dash-col'>
          <label className='dash-label'>Total Number of Groups </label>
          <div className='dash-stat'>
            {transactions?.length > 0
              ? new Intl.NumberFormat().format(
                  transactions.filter((t) => t.summarytype == 'groups')[0]
                    ?.amount
                )
              : 0}
          </div>
        </Col>
        <Col className='dash-col'>
          {/* <label className='dash-label'>Funds Available for LOANS</label>
          <div className='dash-stat'>
            {transactions?.length > 0
              ? new Intl.NumberFormat().format(
                  transactions.filter((t) => t.summarytype == 'contribution')[0]
                    .amount -
                    transactions.filter((t) => t.summarytype == 'loans')[0]
                      .amount
                )
              : 0}{' '}
          </div> */}
        </Col>
      </Row>
    </>
  );
};

export default Transaction;
