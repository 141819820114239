import React, { useState, useEffect } from 'react';
import { parse } from 'papaparse';
// import { insertDocumentregisterBulk } from '../../actions/controldocumentAction'
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { insertStudentBulk } from './studentAction';
// import { insertBulkLedger } from '../../Actions/ledgerAction';
import { Link } from 'react-router-dom';

const ImportCSV = () => {
  const [highlighted, setHighlighted] = React.useState(false);
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);

  const token = JSON.parse(localStorage.getItem('login')).token;

  return (
    <>
      <Row>
        <Col className='mt-4 pt-4 col-10'>
          <h1 className='text-center pt-3 m5-3'>
            IMPORT CONTRIBUTION
            <span style={{ float: 'right' }}>
              <Link to='/list' className='btn btn-light my-3'>
                Go Back
              </Link>
            </span>
          </h1>
          <div
            className={`p-6 my-2 mx-auto col-6 offset-3 ${
              highlighted ? 'border border-success' : 'border border-warning'
            }`}
            onDragEnter={() => {
              setHighlighted(true);
            }}
            onDragLeave={() => {
              setHighlighted(false);
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={(e) => {
              e.preventDefault();
              setHighlighted(false);
              Array.from(e.dataTransfer.files)
                .filter((file) => file.type === 'text/csv')
                .forEach(async (file) => {
                  const text = await file.text();
                  const result = parse(
                    text,
                    { header: true },
                    { skipEmptyLines: true }
                  );
                  setMembers((existing) => [...existing, ...result.data]);
                });
            }}
          >
            DROP FILE HERE
          </div>
          <Row>
            <Col className='col-10 offset-2 mt-3'>
              <Table className='table table-striped table-hover table-sm'>
                <thead className='thead-dark'>
                  <th>ID Number</th>
                  <th>Full Names</th>
                  <th>Mobile</th>
                  <th>Group</th>
                  <th>Position</th>
                </thead>
                <tbody>
                  {members.map((member) => (
                    <tr key={member.member} value={member.member}>
                      <td>{member.member}</td>

                      <td>{member.meeting}</td>
                      <td>{member.amount}</td>
                      <td>{member.month}</td>
                      {/* <td>{member.position}</td>
                      <td>{member.occupation}</td>
                      <td>{member.estate}</td>
                      <td>{member.ward}</td>
                      <td>{member.age}</td> */}
                    </tr>

                    // <tr key={member.idnumber} value={member.idnumber}>
                    //   <td>{member.idnumber}</td>

                    //   <td>{member.fullname}</td>
                    //   <td>{member.mobile}</td>
                    //   <td>{member.groupid}</td>
                    //   <td>{member.position}</td>
                    //   <td>{member.occupation}</td>
                    //   <td>{member.estate}</td>
                    //   <td>{member.ward}</td>
                    //   <td>{member.age}</td>
                    // </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          {/* {JSON.stringify(token)} */}
          <Row>
            <Col className='col-8 offset-3'>
              <span>
                <Button
                  style={{ float: 'right' }}
                  className=''
                  onClick={() => {
                    dispatch(insertStudentBulk(members, token));
                  }}
                >
                  Save
                </Button>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ImportCSV;
