import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { approveLoan, getAllLoansBygroup } from '../../actions/loanAction';
import { Col, Row, Table, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Loans = () => {
  const group = useParams('group');
  const dispatch = useDispatch();

  const loanList = useSelector((state) => state.loanList);

  const { loans, loading } = loanList;

  let login = localStorage.getItem('mamatwendelogin');

  let approver = JSON.parse(login).id;

  useEffect(() => {}, []);

  return (
    <div>
      <Row className='d-flex justify-content-center'>
        <Col className='col-9 mt-3'>
          <Table className='table table-responsive-md table-hover table-sm'>
            <thead>
              <tr>
                <th scope='col'>#</th>
                <th scope='col'>Loanee</th>
                <th scope='col'>Repayment</th>
                <th scope='col'>Interest Part</th>
                <th scope='col'>Principal Part</th>
                <th scope='col'>Total</th>
              </tr>
            </thead>
            <tbody>
              {loans?.length > 0 &&
                loans?.map((r, idx) => (
                  <tr
                    className={`${
                      r.approved == 0 ? 'text-danger' : 'text-primary'
                    }`}
                  >
                    <td>{idx + 1}</td>
                    <td>{r.fullname}</td>
                    <td>{new Intl.NumberFormat().format(r.loansum)}</td>
                    <td>{new Intl.NumberFormat().format(r.interest)}</td>
                    <td>{new Intl.NumberFormat().format(r.principal)}</td>
                    <td>{new Intl.NumberFormat().format(r.balance)}</td>
                    <td>
                      <Button
                        onClick={(e) => {
                          dispatch(
                            approveLoan({ id: r.id, approvedby: approver })
                          );
                        }}
                        className='btn btn-sm btn-success'
                      >
                        Approve
                        <i className='bi bi-check-lg'></i>
                      </Button>
                      <LinkContainer to={`/loan/details/${r.id}`}>
                        <Button
                          onClick={(e) => {
                            dispatch(
                              approveLoan({ id: r.id, approvedby: approver })
                            );
                          }}
                          className='btn btn-sm btn-warning mx-3'
                        >
                          Details
                        </Button>
                      </LinkContainer>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default Loans;
