import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import FormContainer from '../../FormContainer';
import { insertLoan } from '../../actions/loanAction';
import { getMemberDetailsByID } from '../../studentAction';
import './Addloan.css';
import { getMeetingFinancingTablebanking } from '../../actions/meetingAction';
import Meetingcard from '../balancecard/Meetingcard';

var LoanJS = require('loanjs');

const Addloan = () => {
  const dispatch = useDispatch();
  const { meeting, member } = useParams('meeting');

  const params = useParams();
  // const [meeting, setMeeting] = useState('');
  // const [member, setMember] = useState('');
  const [principal, setPrincipal] = useState(1);
  const [interestrate, setInterestrate] = useState(15);
  const [type, setType] = useState('M');
  const [period, setPeriod] = useState(6);
  const [repayments, setRepayments] = useState([]);

  const loanInsert = useSelector((state) => state.loanInsert);

  const StudentDetails = useSelector((state) => state.StudentDetails);

  const { student, loading: studentLoading } = StudentDetails;

  const meetingFinancials = useSelector((state) => state.meetingFinancials);
  const { meetingfinancial } = meetingFinancials;

  useEffect(() => {
    dispatch(getMemberDetailsByID(member));
    dispatch(getMeetingFinancingTablebanking(member));
  }, []);

  const { success } = loanInsert;
  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);

    if (success !== '') {
      toast('Loan Added successfuly');
    }

    // setTimeout(() => {
    //   setA('');
    // }, 3000);
  }, [success]);
  let loan;
  const [newLoan, setLoan] = useState(loan);

  useEffect(() => {
    if (principal > 0 && period > 0 && interestrate > 0) {
      if (type == 'M') {
        loan = new LoanJS.Loan(principal, period, interestrate * 12, true);
        setLoan(loan);
      } else if (type == 'A') {
        loan = new LoanJS.Loan(principal, period, interestrate, true);
        setLoan(loan);
      }
      setRepayments(loan.installments);
    }
  }, [principal, interestrate, period, type]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (!!member) {
      dispatch(
        insertLoan({
          principal,
          member,
          meeting,
          interestrate,
          period,
          type,
          loansum: newLoan?.sum,
          interest: newLoan?.interestSum
        })
      );
    }

    setTimeout(() => {
      dispatch(getMemberDetailsByID(member));
    }, 3000);
  };

  const data = {
    columns: [
      {
        label: '#',
        field: 'repayment',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Repayment',
        field: 'amount',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Interest Part',
        field: 'interest',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Principal Part',
        field: 'principal',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Outstanding',
        field: 'balance',
        sort: 'asc',
        width: 100
      }
    ],
    rows:
      repayments &&
      repayments?.length &&
      repayments.map((r, idx) => {
        return {
          repayment: idx + 1,
          amount: new Intl.NumberFormat().format(r.installment),
          interest: new Intl.NumberFormat().format(r.interest),
          principal: new Intl.NumberFormat().format(r.capital),
          balance: new Intl.NumberFormat().format(r.remain)
        };
      })
  };

  return (
    <div>
      <Row className='d-flex justify-content-center'>
        <Col className='col-md-6 col-lg-9 mt-3'>
          <h2 className='text-primary text-center'>
            PROCESS LOAN
            <span style={{ float: 'right' }}>
              <Link to='/meetings'>
                <Button className='btn button-outline-danger btn-sm'>
                  <div className='fas fa-angle-double-left mr-1' />
                  Go Back
                </Button>
              </Link>
            </span>
          </h2>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col>
          <hr />
        </Col>
      </Row>

      {/*       <Row className='d-flex justify-content-center'>
        <Col>{JSON.stringify(student)}</Col>
      </Row> */}

      <Row className='d-flex justify-content-center'>
        <Col className='col-8 offset-2 my-3'>
          <div className='col-12'>
            {A && (
              <ToastContainer
                toast='Meeting Added successfuly'
                position='top-right'
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
              />
            )}
          </div>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col className='col-lg-3 col-md-6'>
          <div className='card'>
            <div className='card-row'>
              <div className='card-label'>Group</div>
              <div className='card-data'>{student?.name}</div>
            </div>
            <div className='card-row'>
              <div className='card-label'>Name</div>
              <div className='card-data'>{student?.fullname}</div>
            </div>
            <div className='card-row'>
              <div className='card-label'>ID #</div>
              <div className='card-data'>{student?.idnumber}</div>
            </div>
            <div className='card-row'>
              <div className='card-label'>Mobile</div>
              <div className='card-data'>+254{student?.mobile}</div>
            </div>
            <div className='card-row'>
              <div className='card-label'>Outstanding Loans Total</div>
              <div className='card-data loan'>
                {new Intl.NumberFormat().format(student?.totalloan)}
              </div>
            </div>

            <div className='card-row'>
              <div className='card-label'>Total Contributions</div>
              <div className='card-data contribution'>
                {new Intl.NumberFormat().format(student?.contributions)}
              </div>
            </div>
          </div>
        </Col>
        <Col className='col-lg-6 col-md-6'>
          <FormContainer>
            <Form onSubmit={submitHandler} className='border1'>
              <Form.Group>
                <Button className='btn btn-block btn-danger mb-3'>
                  Group Funds Available :{' '}
                  {new Intl.NumberFormat().format(
                    meetingfinancial?.length > 0 &&
                      Number(
                        meetingfinancial
                          .filter((m) => m.type == 'contribution')
                          .reduce((acc, m) => acc + Number(m.amount), 0) -
                          Number(
                            meetingfinancial
                              .filter((m) => m.type == 'loan')
                              .reduce((acc, m) => acc + Number(m.amount), 0)
                          )
                      )
                  )}
                </Button>
              </Form.Group>
              <Form.Group className='my-2' as={Row} controlId='fullnames'>
                <Form.Label column sm={3}>
                  <label>Principal</label>
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name='principal'
                    value={principal}
                    className='form-control form-control-sm'
                    onChange={(e) => {
                      setPrincipal(e.target.value);
                    }}
                    type='number'
                    placeholder='0.00'
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group>
                <Form.Label className='d-flex justify-content-end'>
                  <label>
                    <span>
                      Max Loan Amount :{' '}
                      <h4>
                        {Number(2 * student?.contributions) > Number(principal)
                          ? new Intl.NumberFormat().format(principal)
                          : new Intl.NumberFormat().format(
                              Number(2 * student?.contributions)
                            )}
                      </h4>
                    </span>
                  </label>
                </Form.Label>
              </Form.Group>

              <Form.Group className='my-2' as={Row} controlId='Type'>
                <Form.Label column sm={6}>
                  Interest Type
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    as='select'
                    name='type'
                    value={type}
                    className='form-control form-control-sm'
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    <option value='M'>Monthly</option>
                    <option value='A'>Annualy</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group className='my-2' as={Row} controlId='interestrate'>
                <Form.Label column sm={6}>
                  Interest Rate (Annual)
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    className='form-control form-control-sm'
                    name='interestrate'
                    value={interestrate}
                    onChange={(e) => {
                      setInterestrate(e.target.value);
                    }}
                    type='number'
                    placeholder='0.00'
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group className='my-2' as={Row} controlId='repaymentperiod'>
                <Form.Label column sm={6}>
                  Repayment Period
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    className='form-control form-control-sm'
                    name='period'
                    value={period}
                    onChange={(e) => {
                      setPeriod(e.target.value);
                    }}
                    type='number'
                    placeholder='0.00'
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group>
                <Button
                  disabled={
                    Number(2 * student?.contributions) < Number(principal)
                  }
                  style={{ float: 'right' }}
                  onClick={submitHandler}
                  className='btn btn-sm btn-block'
                >
                  Save Loan | Amount Repayable :{' '}
                  {new Intl.NumberFormat().format(newLoan?.sum)}
                </Button>

                {/* <Button
                  disabled={
                    meetingfinancial?.length > 0 &&
                    Number(
                      meetingfinancial
                        .filter((m) => m.type == 'contribution')
                        .reduce((acc, m) => acc + Number(m.amount), 0) -
                        Number(
                          meetingfinancial
                            .filter((m) => m.type == 'loan')
                            .reduce((acc, m) => acc + Number(m.amount), 0)
                        ) <
                        Number(principal)
                    )
                  }
                  style={{ float: 'right' }}
                  onClick={submitHandler}
                  className='btn btn-sm btn-block'
                >
                  Save Loan | Amount Repayable :{' '}
                  {new Intl.NumberFormat().format(newLoan?.sum)}
                </Button> */}
              </Form.Group>
            </Form>
          </FormContainer>
        </Col>{' '}
      </Row>

      <Row className='d-flex justify-content-center'>
        <Col className='mt-3'>
          <hr />
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col className='mt-3'>
          <h1 className='text-center'>Armotization Schedule</h1>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col className='col-9 mt-3'>
          <Table className='table table-responsive-md table-striped table-hover table-sm'>
            <thead>
              <tr>
                <th scope='col'>#</th>
                <th scope='col'>Repayment</th>
                <th scope='col'>Interest Part</th>
                <th scope='col'>Principal Part</th>
                <th scope='col'>Outstanding</th>
              </tr>
            </thead>
            <tbody>
              {repayments?.length > 0 &&
                repayments?.map((r, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{new Intl.NumberFormat().format(r.installment)}</td>
                    <td>{new Intl.NumberFormat().format(r.interest)}</td>
                    <td>{new Intl.NumberFormat().format(r.capital)}</td>
                    <td>{new Intl.NumberFormat().format(r.remain)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      {/* <Row className='d-flex justify-content-center'>
        <Col className='col-9 mt-3'>
          {repayments?.length > 0 && (
            <MDBDataTableV5
              small
              entriesOptions={[5, 25, 50, 75, 100, 150, 200, 250]}
              entries={10}
              hover
              striped
              sortable
              exportToCSV
              fullPagination
              data={data}
            />
          )}
        </Col>
      </Row> */}
      {/*       <Row className='d-flex justify-content-center'>
        <Col>
          {JSON.stringify({
            principal,
            member,
            meeting,
            interestrate,
            period,
            repayments
          })}
        </Col>
      </Row> */}
    </div>
  );
};

export default Addloan;
