import axios from 'axios';
import {
  LOANS_REQUEST,
  LOANS_REQUEST_SUCCESS,
  LOANS_REQUEST_FAIL,
  LOAN_INSERT,
  LOAN_INSERT_SUCCESS,
  LOAN_INSERT_FAIL,
  LOAN_DETAILS_REQUEST,
  LOAN_DETAILS_REQUEST_SUCCESS,
  LOAN_DETAILS_REQUEST_FAIL,
  LOAN_UPDATE_REQUEST,
  LOAN_UPDATE_SUCCESS,
  LOAN_UPDATE_FAIL,
  LOAN_DELETE_REQUEST,
  LOAN_DELETE_SUCCESS,
  LOAN_DELETE_FAIL,
  LOANREPAYMENTS_REQUEST,
  LOANREPAYMENTS_REQUEST_SUCCESS,
  LOANREPAYMENTS_REQUEST_FAIL
} from '../constants/loanConstant';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const config = {
  headers: {
    headers: { 'Content-Type': 'application/json' }
  }
};

export const insertLoan = (loan) => async (dispatch) => {
  dispatch({ type: LOAN_INSERT });
  const { data } = await axiosInstance.post('/api/loan/', loan, config);
  try {
    dispatch({
      type: LOAN_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: LOAN_INSERT_FAIL, payload: error.message });
  }
};
export const insertRepayment = (loan) => async (dispatch) => {
  dispatch({ type: LOAN_INSERT });
  const { data } = await axiosInstance.post(
    '/api/loan/repayment',
    loan,
    config
  );
  try {
    dispatch({
      type: LOAN_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: LOAN_INSERT_FAIL, payload: error.message });
  }
};

export const getAllLoans = () => async (dispatch) => {
  dispatch({ type: LOANS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/loans`);
    dispatch({
      type: LOANS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: LOANS_REQUEST_FAIL,
      payload: error.message
    });
  }
};
export const getAllLoanRepayment = (loan) => async (dispatch) => {
  dispatch({ type: LOANREPAYMENTS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/loan/repayments/${loan}`);
    dispatch({
      type: LOANREPAYMENTS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: LOANREPAYMENTS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getLoanDetailsByID = (id) => async (dispatch) => {
  dispatch({ type: LOAN_DETAILS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/loan/details/${id}`);

    dispatch({
      type: LOAN_DETAILS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: LOAN_DETAILS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const updateLoanDetails = (loan) => async (dispatch) => {
  dispatch({ type: LOAN_UPDATE_REQUEST });
  try {
    const res = await axiosInstance.put(`/api/loan/${loan}`, loan.id, config);
    dispatch({ type: LOAN_UPDATE_SUCCESS, payload: 'success' });
  } catch (error) {
    dispatch({
      type: LOAN_UPDATE_FAIL,
      payload: error.message
    });
  }
};

export const approveLoan = (loan) => async (dispatch) => {
  dispatch({ type: LOAN_UPDATE_REQUEST });
  try {
    const res = await axiosInstance.put(
      `/api/loan/approve/${loan.id}`,
      loan,
      config
    );
    dispatch({ type: LOAN_UPDATE_SUCCESS, payload: 'success' });
  } catch (error) {
    dispatch({
      type: LOAN_UPDATE_FAIL,
      payload: error.message
    });
  }
};

export const deleteLoan = (id) => async (dispatch) => {};

export const getAllLoansBygroup = (group) => async (dispatch) => {
  dispatch({ type: LOANS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/loans/${group}`);
    dispatch({
      type: LOANS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: LOANS_REQUEST_FAIL,
      payload: error.message
    });
  }
};
