import {
  CONSTITUENCIES_REQUEST,
  CONSTITUENCIES_REQUEST_SUCCESS,
  CONSTITUENCIES_REQUEST_FAIL,
  CONSTITUENCY_INSERT,
  CONSTITUENCY_INSERT_SUCCESS,
  CONSTITUENCY_INSERT_FAIL,
  CONSTITUENCY_UPDATE_REQUEST,
  CONSTITUENCY_UPDATE_FAIL,
  CONSTITUENCY_UPDATE_SUCCESS,
  CONSTITUENCY_DELETE_FAIL,
  CONSTITUENCY_DELETE_SUCCESS,
  CONSTITUENCY_DELETE_REQUEST,
  CONSTITUENCY_DETAILS_REQUEST,
  CONSTITUENCY_DETAILS_REQUEST_SUCCESS,
  CONSTITUENCY_DETAILS_REQUEST_FAIL,
} from '../constants/constituencyConstant';

export const constituencyListReducer = (state = { constituencies: [] }, action) => {
  switch (action.type) {
    case CONSTITUENCIES_REQUEST:
      return {
        loading: true,
        constituencies: [],
      };
    case CONSTITUENCIES_REQUEST_SUCCESS:
      return {
        loading: false,
        constituencies: action.payload,
      };
    case CONSTITUENCIES_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const constituencyInsertReducer = (state, action) => {
  switch (action.type) {
    case CONSTITUENCY_INSERT:
      return {
        loading: true,
        constituencyerror: [],
        constituency: {},
      };
    case CONSTITUENCY_INSERT_SUCCESS:
      return {
        loading: false,
        constituency: {},
        success: action.payload,
      };
    case CONSTITUENCY_INSERT_FAIL:
      return {
        loading: false,
        constituencies: [],
        constituencyerror: action.payload,
      };
    default:
      return { ...state };
  }
};

export const constituencyUpdateReducer = (state, action) => {
  switch (action.type) {
    case CONSTITUENCY_UPDATE_REQUEST:
      return {
        loading: true,
        constituenciesError: [],
        constituency: {},
      };
    case CONSTITUENCY_UPDATE_SUCCESS:
      return {
        loading: true,

        constituency: {},
      };
    case CONSTITUENCY_UPDATE_FAIL:
      return {
        loading: false,
        constituenciesError: action.payload,
      };
    default:
      return { ...state };
  }
};

export const constituencyDeleteReducer = (state, action) => {
  switch (action.type) {
    case CONSTITUENCY_DELETE_REQUEST:
      return {
        loading: false,
        constituenciesError: action.payload,
        constituency: {},
      };
    case CONSTITUENCY_DELETE_SUCCESS:
      return {
        loading: false,

        constituency: {},
      };
    case CONSTITUENCY_DELETE_FAIL:
      return {
        loading: false,
        constituencies: [],
        constituenciesError: action.payload,
      };
    default:
      return { ...state };
  }
};

export const constituencyDetailsReducer = (state = { constituency: {} }, action) => {
  switch (action.type) {
    case CONSTITUENCY_DETAILS_REQUEST:
      return {
        loading: true,
        constituenciesError: [],
        constituency: {},
      };
    case CONSTITUENCY_DETAILS_REQUEST_SUCCESS:
      return {
        constituency: action.payload,
        loading: false,
        constituenciesError: [],
      };
    case CONSTITUENCY_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        constituency: {},
        constituenciesError: action.payload,
      };
    default:
      return { ...state };
  }
};
