import {
  TRANSACTIONS_REQUEST,
  TRANSACTIONS_REQUEST_SUCCESS,
  TRANSACTIONS_REQUEST_FAIL,
  TRANSACTION_INSERT,
  TRANSACTION_INSERT_SUCCESS,
  TRANSACTION_INSERT_FAIL,
  TRANSACTION_UPDATE_REQUEST,
  TRANSACTION_UPDATE_FAIL,
  TRANSACTION_UPDATE_SUCCESS,
  TRANSACTION_DELETE_FAIL,
  TRANSACTION_DELETE_SUCCESS,
  TRANSACTION_DELETE_REQUEST,
  TRANSACTION_DETAILS_REQUEST,
  TRANSACTION_DETAILS_REQUEST_SUCCESS,
  TRANSACTION_DETAILS_REQUEST_FAIL
} from '../constants/transactionConstant';

export const transactionListReducer = (state = { transactions: [] }, action) => {
  switch (action.type) {
    case TRANSACTIONS_REQUEST:
      return {
        loading: true,
        transactions: []
      };
    case TRANSACTIONS_REQUEST_SUCCESS:
      return {
        loading: false,
        transactions: action.payload
      };
    case TRANSACTIONS_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const transactionInsertReducer = (state, action) => {
  switch (action.type) {
    case TRANSACTION_INSERT:
      return {
        loading: true,
        transactionerror: [],
        transaction: {}
      };
    case TRANSACTION_INSERT_SUCCESS:
      return {
        loading: false,
        transaction: {},
        success: action.payload
      };
    case TRANSACTION_INSERT_FAIL:
      return {
        loading: false,
        transactions: [],
        transactionerror: action.payload
      };
    default:
      return { ...state };
  }
};

export const transactionUpdateReducer = (state, action) => {
  switch (action.type) {
    case TRANSACTION_UPDATE_REQUEST:
      return {
        loading: true,
        transactionsError: [],
        transaction: {}
      };
    case TRANSACTION_UPDATE_SUCCESS:
      return {
        loading: true,

        transaction: {}
      };
    case TRANSACTION_UPDATE_FAIL:
      return {
        loading: false,
        transactionsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const transactionDeleteReducer = (state, action) => {
  switch (action.type) {
    case TRANSACTION_DELETE_REQUEST:
      return {
        loading: false,
        transactionsError: action.payload,
        transaction: {}
      };
    case TRANSACTION_DELETE_SUCCESS:
      return {
        loading: false,

        transaction: {}
      };
    case TRANSACTION_DELETE_FAIL:
      return {
        loading: false,
        transactions: [],
        transactionsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const transactionDetailsReducer = (state = { transaction: {} }, action) => {
  switch (action.type) {
    case TRANSACTION_DETAILS_REQUEST:
      return {
        loading: true,
        transactionsError: [],
        transaction: {}
      };
    case TRANSACTION_DETAILS_REQUEST_SUCCESS:
      return {
        transaction: action.payload,
        loading: false,
        transactionsError: []
      };
    case TRANSACTION_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        transaction: {},
        transactionsError: action.payload
      };
    default:
      return { ...state };
  }
};
