import React, { useEffect } from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { MDBDataTableV5 } from 'mdbreact';
import Loader from '../Loader';
import Message from '../Message';
import { getAllConstituency } from '../actions/constituencyAction';

const Constituency = () => {
  const dispatch = useDispatch();
  const constituencyList = useSelector((state) => state.constituencyList);

  const { constituencies, loading, error } = constituencyList;

  useEffect(() => {
    dispatch(getAllConstituency());
  }, []);

  const handleDelete = () => {};

  const data = {
    columns: [
      {
        label: 'Action',
        field: 'update',
        sort: 'asc',
        width: 10
      }
    ],
    rows:
      constituencies &&
      constituencies.length &&
      constituencies.map((ward) => {
        return {
          name: ward.name,
          id: ward.id,

          update: (
            <>
              <div className='table-card'>
                <div className='table-logo'>
                  {' '}
                  <i className='bi bi-geo-fill'></i>{' '}
                </div>
                <label className='table-data-label'>{ward.name}</label>
              </div>

              {/* <LinkContainer to={`/ward/update/${ward.id}`}>
                <Button variant='success' className='btn-sm mr-2'>
                  <i className='fas fa-angle-double-right'></i>
                </Button>
              </LinkContainer>

              <Button
                variant='danger'
                className='btn-sm mr-2'
                onClick={() => {
                  handleDelete(ward.id);
                }}
              >
                <i className='fas fa-trash'></i>
              </Button> */}
            </>
          )
        };
      })
  };

  return (
    <div>
      <Row className='dflex justify-content-center'>
        <Col className='dflex justify-content-center col-lg-8 pt-5'>
          <h2 className='text-center'>
            MAMA TWENDE - LIST OF CONSTITUENCIES
            <span style={{ float: 'right' }}>
              <Link to='/addward'>
                <Button className='btn btn-light btn-sm'>
                  Add Constituency
                </Button>
              </Link>
            </span>
          </h2>

          <hr />
        </Col>
      </Row>
      <Row className='dflex justify-content-center'>
        <Col className='dflex justify-content-center col-lg-6 col-md-8 col-sm-12'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger' children={error} />
          ) : (
            <MDBDataTableV5
              small
              //cellEdit={cellEditFactory({ mode: 'click' })}

              entriesOptions={[5, 25, 50, 75, 100, 150, 200, 250, 300]}
              entries={5}
              hover
              striped
              sortable
              exportToCSV
              data={data}
              paging={false}
              fullPagination={window.innerWidth < '768px' ? false : true}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Constituency;
