import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGroups, getRooms } from '../actions/groupAction';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { MDBDataTableV5 } from 'mdbreact';
import Loader from '../Loader';
import Message from '../Message';

const Groups = () => {
  const dispatch = useDispatch();
  const groupList = useSelector((state) => state.groupList);

  const { groups, loading, error } = groupList;

  useEffect(() => {
    //dispatch(getRooms());
    dispatch(getAllGroups());
  }, []);

  const handleDelete = () => {};

  const data = {
    columns: [
      {
        label: 'SN',
        field: 'id',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Ward',
        field: 'ward',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Action',
        field: 'update',
        sort: 'asc',
        width: 10
      }
    ],
    rows:
      groups &&
      groups.length &&
      groups.map((group) => {
        return {
          name: group.name,
          id: group.id,
          ward: group.ward,

          update: (
            <>
              <LinkContainer to={`/group/update/${group.id}`}>
                <Button variant='success' className='btn-sm mr-2'>
                  <i className='fas fa-angle-double-right'></i>
                </Button>
              </LinkContainer>{' '}
              {'  '}
              <Button
                variant='danger'
                className='btn-sm mr-2'
                onClick={() => {
                  handleDelete(group.id);
                }}
              >
                <i className='fas fa-trash'></i>
              </Button>
            </>
          )
        };
      })
  };

  return (
    <div>
      <Row className='dflex justify-content-center'>
        <Col className='dflex justify-content-center col-lg-8 mt-5'>
          <h2 className='text-center'>
            MAMA TWENDE - REGISTERED GROUPS
            <span style={{ float: 'right' }}>
              <Link to='/addgroup'>
                <Button className='btn btn-light btn-sm'>Add Group</Button>
              </Link>
            </span>
          </h2>

          <hr />
        </Col>
      </Row>
      <Row className='dflex justify-content-center'>
        <Col className='dflex justify-content-center col-lg-8'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger' children={error} />
          ) : (
            <MDBDataTableV5
              small
              //cellEdit={cellEditFactory({ mode: 'click' })}

              entriesOptions={[5, 25, 50, 75, 100, 150, 200, 250, 300]}
              entries={5}
              hover
              striped
              sortable
              exportToCSV
              fullPagination
              data={data}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Groups;
