export const CONTRIBUTIONS_REQUEST = 'CONTRIBUTION_REQUEST';
export const CONTRIBUTIONS_REQUEST_SUCCESS = 'CONTRIBUTION_REQUEST_SUCCESS';
export const CONTRIBUTIONS_REQUEST_FAIL = 'CONTRIBUTION_REQUEST_FAIL';

export const CONTRIBUTION_INSERT = 'CONTRIBUTION_INSERT';
export const CONTRIBUTION_INSERT_SUCCESS = 'CONTRIBUTION_INSERT_SUCCESS';
export const CONTRIBUTION_INSERT_FAIL = 'CONTRIBUTION_INSERT_FAIL';

export const CONTRIBUTION_UPDATE_REQUEST = 'CONTRIBUTION_UPDATE_REQUEST';
export const CONTRIBUTION_UPDATE_SUCCESS = 'CONTRIBUTION_UPDATE_SUCCESS';
export const CONTRIBUTION_UPDATE_FAIL = 'CONTRIBUTION_UPDATE_FAIL';

export const CONTRIBUTION_DELETE_REQUEST = 'CONTRIBUTION_DELETE_REQUEST';
export const CONTRIBUTION_DELETE_SUCCESS = 'CONTRIBUTION_DELETE_SUCCESS';
export const CONTRIBUTION_DELETE_FAIL = 'CONTRIBUTION_DELETE_FAIL';

export const CONTRIBUTION_DETAILS_REQUEST = 'CONTRIBUTION_DETAILS_REQUEST';
export const CONTRIBUTION_DETAILS_REQUEST_SUCCESS =
  'CONTRIBUTION_DETAILS_REQUEST_SUCCESS';
export const CONTRIBUTION_DETAILS_REQUEST_FAIL =
  'CONTRIBUTION_DETAILS_REQUEST_FAIL';
