import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  StudentDetailsReducer,
  studentDeleteReducer,
  studentInsertReducer,
  studentListReducer,
  studentUpdateReducer,
  invitedListReducer
} from './studentReducer';
import {
  meetingDeleteReducer,
  meetingListReducer,
  meetingUpdateReducer,
  meetingDetailsReducer,
  meetingInsertReducer,
  meetingFinancialsReducer
} from './reducers/meetingReducer';

import {
  groupDeleteReducer,
  groupDetailsReducer,
  groupInsertReducer,
  groupListReducer,
  groupUpdateReducer
} from './reducers/groupReducer';

import {
  wardDeleteReducer,
  wardDetailsReducer,
  wardInsertReducer,
  wardListReducer,
  wardUpdateReducer
} from './reducers/wardReducer';

import {
  constituencyDeleteReducer,
  constituencyDetailsReducer,
  constituencyInsertReducer,
  constituencyListReducer,
  constituencyUpdateReducer
} from './reducers/constituencyReducer';

import {
  loginInsertReducer,
  userLoginReducer,
  userListReducer,
  loginUpdateReducer,
  loginDetailsReducer
} from './reducers/loginReducers';

import {
  groupadminDeleteReducer,
  groupadminDetailsReducer,
  groupadminInsertReducer,
  groupadminListReducer,
  groupadminUpdateReducer
} from './reducers/groupadminReducer';

import {
  contributionDeleteReducer,
  contributionDetailsReducer,
  contributionInsertReducer,
  contributionListReducer,
  contributionUpdateReducer
} from './reducers/contributionReducer';

import {
  transactionDeleteReducer,
  transactionDetailsReducer,
  transactionInsertReducer,
  transactionListReducer,
  transactionUpdateReducer
} from './reducers/transactionsReducer';

import {
  loanDeleteReducer,
  loanDetailsReducer,
  loanInsertReducer,
  loanListReducer,
  loanUpdateReducer,
  loanRepaymentsListReducer
} from './reducers/loanReducer';

import {
  expenseDeleteReducer,
  expenseDetailsReducer,
  expenseInsertReducer,
  expenseListReducer,
  expenseUpdateReducer
} from './reducers/expenseReducer';

import {
  refundscheduleDeleteReducer,
  refundscheduleDetailsReducer,
  refundscheduleInsertReducer,
  refundscheduleListReducer,
  refundscheduleUpdateReducer
} from './reducers/refundscheduleReducer';

const reducer = combineReducers({
  refundscheduleDelete: refundscheduleDeleteReducer,
  refundscheduleDetails: refundscheduleDetailsReducer,
  refundscheduleInsert: refundscheduleInsertReducer,
  refundscheduleList: refundscheduleListReducer,
  refundscheduleUpdate: refundscheduleUpdateReducer,

  //Expenses
  expenseDelete: expenseDeleteReducer,
  expenseDetails: expenseDetailsReducer,
  expenseInsert: expenseInsertReducer,
  expenseList: expenseListReducer,
  expenseUpdate: expenseUpdateReducer,
  //loans
  loanDelete: loanDeleteReducer,
  loanDetails: loanDetailsReducer,
  loanInsert: loanInsertReducer,
  loanList: loanListReducer,
  loanUpdate: loanUpdateReducer,
  loanRepaymentsList: loanRepaymentsListReducer,

  //transactions
  transactionDelete: transactionDeleteReducer,
  transactionDetails: transactionDetailsReducer,
  transactionInsert: transactionInsertReducer,
  transactionList: transactionListReducer,
  transactionUpdate: transactionUpdateReducer,

  //CONTRIBUTIONS
  contributionDelete: contributionDeleteReducer,
  contributionDetails: contributionDetailsReducer,
  contributionInsert: contributionInsertReducer,
  contributionList: contributionListReducer,
  contributionUpdate: contributionUpdateReducer,

  //GROUP ADMIN
  groupadminDelete: groupadminDeleteReducer,
  groupadminDetails: groupadminDetailsReducer,
  groupadminInsert: groupadminInsertReducer,
  groupadminList: groupadminListReducer,
  groupadminUpdate: groupadminUpdateReducer,

  loginInsert: loginInsertReducer,
  userLogin: userLoginReducer,
  userList: userListReducer,
  loginUpdate: loginUpdateReducer,
  loginDetails: loginDetailsReducer,

  //WARDS
  wardDelete: wardDeleteReducer,
  wardDetails: wardDetailsReducer,
  wardInsert: wardInsertReducer,
  wardList: wardListReducer,
  wardUpdate: wardUpdateReducer,

  //CONSTITUENCY
  constituencyDelete: constituencyDeleteReducer,
  constituencyDetails: constituencyDetailsReducer,
  constituencyInsert: constituencyInsertReducer,
  constituencyList: constituencyListReducer,
  constituencyUpdate: constituencyUpdateReducer,

  //GROUP
  groupDelete: groupDeleteReducer,
  groupDetails: groupDetailsReducer,
  groupInsert: groupInsertReducer,
  groupList: groupListReducer,
  groupUpdate: groupUpdateReducer,

  //STUDENT
  studentDelete: studentDeleteReducer,
  studentInsert: studentInsertReducer,
  StudentDetails: StudentDetailsReducer,
  studentList: studentListReducer,
  studentUpdate: studentUpdateReducer,
  invitedList: invitedListReducer,

  //Meeting

  meetingDelete: meetingDeleteReducer,
  meetingInsert: meetingInsertReducer,
  meetingList: meetingListReducer,
  meetingUpdate: meetingUpdateReducer,
  meetingDetails: meetingDetailsReducer,
  meetingFinancials: meetingFinancialsReducer
});
const loginFromStorage = localStorage.getItem('mamatwendelogin')
  ? JSON.parse(localStorage.getItem('mamatwendelogin'))
  : null;

const initialState = {
  login: { loginInfo: loginFromStorage }
};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
