import {
  WARDS_REQUEST,
  WARDS_REQUEST_SUCCESS,
  WARDS_REQUEST_FAIL,
  WARD_INSERT,
  WARD_INSERT_SUCCESS,
  WARD_INSERT_FAIL,
  WARD_UPDATE_REQUEST,
  WARD_UPDATE_FAIL,
  WARD_UPDATE_SUCCESS,
  WARD_DELETE_FAIL,
  WARD_DELETE_SUCCESS,
  WARD_DELETE_REQUEST,
  WARD_DETAILS_REQUEST,
  WARD_DETAILS_REQUEST_SUCCESS,
  WARD_DETAILS_REQUEST_FAIL,
} from '../constants/wardConstant';

export const wardListReducer = (state = { wards: [] }, action) => {
  switch (action.type) {
    case WARDS_REQUEST:
      return {
        loading: true,
        wards: [],
      };
    case WARDS_REQUEST_SUCCESS:
      return {
        loading: false,
        wards: action.payload,
      };
    case WARDS_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const wardInsertReducer = (state, action) => {
  switch (action.type) {
    case WARD_INSERT:
      return {
        loading: true,
        warderror: [],
        ward: {},
      };
    case WARD_INSERT_SUCCESS:
      return {
        loading: false,
        ward: {},
        success: action.payload,
      };
    case WARD_INSERT_FAIL:
      return {
        loading: false,
        wards: [],
        warderror: action.payload,
      };
    default:
      return { ...state };
  }
};

export const wardUpdateReducer = (state, action) => {
  switch (action.type) {
    case WARD_UPDATE_REQUEST:
      return {
        loading: true,
        wardsError: [],
        ward: {},
      };
    case WARD_UPDATE_SUCCESS:
      return {
        loading: true,
        success: action.payload,
        ward: {},
      };
    case WARD_UPDATE_FAIL:
      return {
        loading: false,
        wardsError: action.payload,
      };
    default:
      return { ...state };
  }
};

export const wardDeleteReducer = (state, action) => {
  switch (action.type) {
    case WARD_DELETE_REQUEST:
      return {
        loading: false,
        wardsError: action.payload,
        ward: {},
      };
    case WARD_DELETE_SUCCESS:
      return {
        loading: false,

        ward: {},
      };
    case WARD_DELETE_FAIL:
      return {
        loading: false,
        wards: [],
        wardsError: action.payload,
      };
    default:
      return { ...state };
  }
};

export const wardDetailsReducer = (state = { ward: {} }, action) => {
  switch (action.type) {
    case WARD_DETAILS_REQUEST:
      return {
        loading: true,
        wardsError: [],
        ward: {},
      };
    case WARD_DETAILS_REQUEST_SUCCESS:
      return {
        ward: action.payload,
        loading: false,
        wardsError: [],
      };
    case WARD_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        ward: {},
        wardsError: action.payload,
      };
    default:
      return { ...state };
  }
};
