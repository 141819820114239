import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";
import Loader from "../Loader";
import Message from "../Message";
import { LinkContainer } from "react-router-bootstrap";
import { deleteMeeting, getAllMeetings } from "../actions/meetingAction";

const Meetings = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  useEffect(() => {
    dispatch(getAllMeetings(status));
  }, [status]);
  const meetingList = useSelector((state) => state.meetingList);

  const { meetings, error, loading } = meetingList;
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      dispatch(deleteMeeting(id));
    }
  };
  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 100,
      },

      {
        label: "Venue",
        field: "venue",
        sort: "asc",
        width: 100,
      },

      {
        label: "Purpose",
        field: "purpose",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "update",
        sort: "asc",
        width: 10,
      },
    ],
    rows:
      meetings &&
      meetings.length &&
      meetings.map((meeting) => {
        return {
          name: meeting.name,
          venue: meeting.venue,
          purpose: meeting.purpose,
          date: new Date(meeting.date).toLocaleDateString(),
          update: (
            <>
              <LinkContainer to={`/meeting/${meeting.id}`}>
                <Button variant="success" className="btn-sm mr-2">
                  Clear Attendees <i className="fas fa-angle-double-right"></i>
                </Button>
              </LinkContainer>{" "}
              <LinkContainer to={`/meeting/invite/${meeting.id}`}>
                <Button variant="warning" className="btn-sm mr-2">
                  Invite Attendees <i className="fas fa-angle-double-right"></i>
                </Button>
              </LinkContainer>{" "}
              <Button
                variant="danger"
                className="btn-sm mr-2"
                onClick={() => {
                  handleDelete(meeting.id);
                }}
              >
                Close Meeting
                <i className="fas fa-trash mx-1 "></i>
              </Button>
            </>
          ),
        };
      }),
  };
  return (
    <>
      <Row className="dflex justify-content-center">
        <Col className="mt-5 col-lg-8">
          <span style={{ float: "left" }}>
            <select
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="form-control form-control-sm"
            >
              <option disabled value={true}>
                Choose Meeting Status
              </option>
              <option value={true}>Active Meetings</option>
              <option value={false}>Closed Meetings</option>
            </select>
          </span>
          <h3 className="text-primary text-center">
            <span style={{ float: "right" }}>
              <Link to="/registermeeting">
                <Button className="btn btn-sm"> New Meeting</Button>
              </Link>
            </span>
            ALL MEETINGS
          </h3>
          <hr />
        </Col>
      </Row>

      <Row className="dflex justify-content-center">
        <Col className="col-lg-8 col-md-10 ">
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger" children={error} />
          ) : (
            <MDBDataTableV5
              small
              //cellEdit={cellEditFactory({ mode: 'click' })}

              entriesOptions={[5, 25, 50, 75, 100, 150, 200, 250, 300]}
              entries={5}
              hover
              striped
              sortable
              exportToCSV
              fullPagination
              data={data}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Meetings;
