export const CONSTITUENCIES_REQUEST = 'CONSTITUENCY_REQUEST';
export const CONSTITUENCIES_REQUEST_SUCCESS = 'CONSTITUENCY_REQUEST_SUCCESS';
export const CONSTITUENCIES_REQUEST_FAIL = 'CONSTITUENCY_REQUEST_FAIL';

export const CONSTITUENCY_INSERT = 'CONSTITUENCY_INSERT';
export const CONSTITUENCY_INSERT_SUCCESS = 'CONSTITUENCY_INSERT_SUCCESS';
export const CONSTITUENCY_INSERT_FAIL = 'CONSTITUENCY_INSERT_FAIL';

export const CONSTITUENCY_UPDATE_REQUEST = 'CONSTITUENCY_UPDATE_REQUEST';
export const CONSTITUENCY_UPDATE_SUCCESS = 'CONSTITUENCY_UPDATE_SUCCESS';
export const CONSTITUENCY_UPDATE_FAIL = 'CONSTITUENCY_UPDATE_FAIL';

export const CONSTITUENCY_DELETE_REQUEST = 'CONSTITUENCY_DELETE_REQUEST';
export const CONSTITUENCY_DELETE_SUCCESS = 'CONSTITUENCY_DELETE_SUCCESS';
export const CONSTITUENCY_DELETE_FAIL = 'CONSTITUENCY_DELETE_FAIL';

export const CONSTITUENCY_DETAILS_REQUEST = 'CONSTITUENCY_DETAILS_REQUEST';
export const CONSTITUENCY_DETAILS_REQUEST_SUCCESS = 'CONSTITUENCY_DETAILS_REQUEST_SUCCESS';
export const CONSTITUENCY_DETAILS_REQUEST_FAIL = 'CONSTITUENCY_DETAILS_REQUEST_FAIL';
