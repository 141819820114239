import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import FormContainer from '../FormContainer';
import Alert from '../Alert';
import {
  getGroupDetailsByID,
  insertGroup,
  updateGroupDetails
} from '../actions/groupAction';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import GroupDashboard from './dashboard/GroupDashboard';
import { getAllWards } from '../actions/wardAction';
const UpdateGroup = () => {
  const { id } = useParams('id');
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [ward, setWard] = useState('');

  const groupDetails = useSelector((state) => state.groupDetails);
  const groupUpdate = useSelector((state) => state.groupUpdate);
  const { success } = groupUpdate;

  const wardList = useSelector((state) => state.wardList);
  const { wards, loading: loadingWards } = wardList;

  const { group, loading: groupLoading } = groupDetails;

  useEffect(() => {
    dispatch(getGroupDetailsByID(id));
    dispatch(getAllWards());
  }, []);

  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
    }, 3000);
  }, [success]);

  useEffect(() => {
    if (id == group.id) {
      setName(group.name);
      setWard(group.ward);
    }
  }, [group]);

  const submitHandler = () => {
    if (!!ward) {
      if (!!name) {
        dispatch(
          updateGroupDetails({
            name,
            ward,
            id
          })
        );
      }
    }
  };

  return (
    <div>
      <Row>
        <Col className='col-6 offset-3 mt-3 pt-3'>
          <h2 className='text-center'>
            UPDATE GROUP DETAILS
            <span style={{ float: 'right' }}>
              <Link to='/groups'>
                <Button className='btn btn-light btn-sm'>All Groups</Button>
              </Link>
            </span>
          </h2>
          <hr />
        </Col>
      </Row>
      <Row className='dflex justify-content-end'>
        <Col className='col-4 mr-3'>
          <LinkContainer to='/groupmembers'>
            <Button className='btn-sm'>View Members</Button>
          </LinkContainer>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center align-items-center'>
        <Col className='col-6'>
          <Row>
            <Col className='mt-3'>
              <div className='col-12'>
                {A && <Alert type='success' message={A} />}
              </div>
            </Col>
          </Row>
          <Form onSubmit={submitHandler} className='border1'>
            <Form.Group className='my-2' as={Row} controlId='fullnames'>
              <Form.Label column sm={3}>
                GROUP NAME
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name='name'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder='Group Name'
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group className='my-2' as={Row} controlId='ward'>
              <Form.Label column sm={3}>
                WARD
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as='select'
                  name='ward'
                  value={ward}
                  onChange={(e) => {
                    setWard(e.target.value);
                  }}
                >
                  <option value={''}>--Select Constituency--</option>
                  {!loadingWards &&
                    wards &&
                    wards.length &&
                    wards.map((constituency) => (
                      <option key={constituency.id} value={constituency.id}>
                        {constituency.name}
                      </option>
                    ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Button
              style={{ float: 'right' }}
              onClick={submitHandler}
              className='btn btn-sm'
            >
              Update Details
            </Button>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
    </div>
  );
};

export default UpdateGroup;
