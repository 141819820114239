import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import Loader from '../Loader';
import Message from '../Message';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { LinkContainer } from 'react-router-bootstrap';
import {
  deleteInvitation,
  deleteMeeting,
  getAllMeetings,
  getMeetingDetailsByID,
  inviteMeeting
} from '../actions/meetingAction';
import {
  getAllStudentsByGroup,
  getAllStudentsByGroupInvite,
  getMeetingInviteesByMeetingID,
  getMeetingParticipantsByMeetingID
} from '../studentAction';
import { getAllGroups } from '../actions/groupAction';
import Papa from 'papaparse';
import { useCSVDownloader } from 'react-papaparse';
import { MDBDataTableV5 } from 'mdbreact';
import Alert from '../Alert';
import MeetingInvitationPerward from './MeetingInvitationPerward';
createTheme(
  'solarized',
  {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198'
    },
    background: {
      default: '#002b36'
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF'
    },
    divider: {
      default: '#073642'
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)'
    }
  },
  'dark'
);
const customStyles = {
  rows: {
    style: {
      minHeight: '72px' // override the row height
    }
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px'
    }
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px'
    }
  }
};
const MeetingInvites = () => {
  const dispatch = useDispatch();
  const { id } = useParams('id');
  useEffect(() => {
    dispatch(getAllMeetings(true));
    dispatch(getAllGroups());
    dispatch(getMeetingParticipantsByMeetingID(id));
  }, []);

  useEffect(() => {
    dispatch(getMeetingInviteesByMeetingID(id));
    dispatch(getMeetingDetailsByID(id));
  }, [id]);

  const groupList = useSelector((state) => state.groupList);
  const invitedList = useSelector((state) => state.invitedList);
  const { invites, loading: inviteLoading, error: invitesError } = invitedList;

  const { groups, loading: groupsLoading, error: groupError } = groupList;
  const studentList = useSelector((state) => state.studentList);
  const { students, loading, error } = studentList;
  const [groupid, setGroup] = useState(0);
  const meetingInsert = useSelector((state) => state.meetingInsert);
  const meetingDetails = useSelector((state) => state.meetingDetails);

  const {
    meeting,
    loading: meetingLoading,
    error: meetingError
  } = meetingDetails;

  const { success } = meetingInsert;
  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
    }, 3000);
  }, [success]);
  const handleDelete = () => {};

  const datas = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Group Name',
        field: 'groupname',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Action',
        field: 'update',
        sort: 'asc',
        width: 10
      }
    ],
    rows:
      invites &&
      invites.length &&
      invites.map((meeting) => {
        return {
          name: meeting.fullname,
          mobile: meeting.mobile,
          groupname: meeting.groupname,
          update: (
            <>
              <Button
                variant='danger'
                className='btn-sm mr-2'
                onClick={() => {
                  dispatch(deleteInvitation(meeting.id));
                }}
              >
                Remove Invitation
                <i className='fas fa-trash mx-1'> </i>
              </Button>
            </>
          )
        };
      })
  };

  const columns = [
    {
      name: 'R/No',
      selector: (row) => row.id
    },
    {
      name: 'Fullname',
      selector: (row) => row.fullname
    },
    {
      name: 'ID Number',
      selector: (row) => row.idnumber
    },
    {
      name: 'Position',
      selector: (row) => row.mobile
    },
    {
      name: 'Mobile',
      selector: (row) => row.position
    },
    {
      name: 'gender',
      selector: (row) => row.gender
    },
    {
      name: 'Code',
      selector: (row) => row.barcode
    }
  ];
  const [selected, setSelected] = useState('');
  const data =
    !loading &&
    students?.length > 0 &&
    students?.map((member) => {
      return {
        fullname: member.fullname.toUpperCase(),
        mobile: member.mobile,
        idnumber: member.idnumber,
        id: member.id,
        gender: member.gender,
        barcode: member.barcode,
        position: member.position
      };
    });

  const handleChange = ({ selectedRows }) => {
    // setTotalAmount(
    //   selectedRows
    //     .map((p) => p.amount)
    //     .reduce((total, amount) => Number(total) + Number(amount), 0)
    // );
    setSelected(selectedRows.map((s) => s.id.toString()));
  };
  const { CSVDownloader, Type } = useCSVDownloader();

  const downloadCSV = () => {
    const csv = Papa.unparse({ invites });
    const blob = new Blob([csv]);
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob, { type: 'text/plain' });
    a.download = 'CSV Export File';
  };
  return (
    <>
      <Tabs>
        <TabList>
          <Tab>INVITATION PER GROUP</Tab>
          <Tab>INVITE GROUPS PER WARD</Tab>
        </TabList>
        <TabPanel>
          <Row className='dflex justify-content-center'>
            <Col className='mt-5 col-lg-8'>
              <h3 className=' text-center'>
                {/* <span style={{ float: "right" }}>
              <Link to="/registermeeting">
                <Button className="btn btn-sm"> New Meeting</Button>
              </Link>
            </span> */}
                INVITE MEMBERS FOR {meeting.name?.toUpperCase()}
              </h3>
              <hr />
            </Col>
          </Row>

          <Row className='dflex justify-content-center'>
            <Row className='dflex justify-content-center'>
              <Col className='dflex flex-row col-lg-8 col-md-6 py-3'>
                <label>Choose Group</label>
                <Form.Control
                  as='select'
                  name='groupid'
                  value={groupid}
                  className='form-control form-control-sm'
                  onChange={(e) => {
                    setGroup(e.target.value);

                    dispatch(getAllStudentsByGroupInvite(e.target.value, id));
                  }}
                >
                  <option value='0'>Choose group name</option>
                  {!groupsLoading &&
                    groups?.map((g) => (
                      <option value={g.id} key={g.id}>
                        {g.name}
                      </option>
                    ))}
                </Form.Control>
              </Col>
            </Row>
            <Col className='col-lg-8 col-md-10 '>
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger' children={error} />
              ) : students?.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={data}
                  selectableRows
                  onSelectedRowsChange={handleChange}
                  customStyles={customStyles}
                  pagination
                  dense
                />
              ) : (
                0
              )}
            </Col>
          </Row>
          <Row className='d-flex col-10 justify-content-center mt-5'>
            <Col className='col-6 d-flex justify-content-end'>
              {A && <Alert type='success' message={A} />}
            </Col>{' '}
            <Col className='d-flex justify-content-end'>
              <Button
                className='btn btn-sm btn-primary mr-4'
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(inviteMeeting({ selected, meeting: id }));
                }}
              >
                Schedule Invitation
              </Button>
            </Col>
          </Row>
          <Row className='dflex justify-content-end mt-5'>
            <Col className='dflex justify-content-end col-lg-8 col-md-10'>
              <h4>MEMBERS INVITED TO {meeting.name?.toUpperCase()}</h4>
            </Col>
          </Row>
          <Row className='dflex justify-content-center  mt-5'>
            <Col className='dflex justify-content-center col-lg-8 col-md-10'>
              {' '}
              {inviteLoading ? (
                <Loader />
              ) : invitesError ? (
                <Message variant='danger' children={error} />
              ) : (
                !inviteLoading &&
                invites?.length > 0 && (
                  <MDBDataTableV5
                    small
                    //cellEdit={cellEditFactory({ mode: 'click' })}

                    entriesOptions={[5, 25, 50, 75, 100, 150, 200, 250, 300]}
                    entries={5}
                    hover
                    striped
                    sortable
                    exportToCSV
                    fullPagination
                    data={datas}
                  />
                )
              )}
            </Col>
          </Row>
          <Row className='d-flex col-10 justify-content-center mt-5'>
            <Col className='d-flex justify-content-end'>
              <CSVDownloader data={invites} filename='invitees'>
                <Button
                  className='btn btn-sm btn-warning'
                  onClick={() => {
                    downloadCSV();
                  }}
                >
                  <i className='fas fa-file-csv mx-2'></i>
                  Download Attendance List
                </Button>
              </CSVDownloader>
            </Col>
          </Row>
        </TabPanel>
        <TabPanel>
          <MeetingInvitationPerward />
        </TabPanel>
        <TabPanel>{/* <Constituency /> */}</TabPanel>
      </Tabs>
    </>
  );
};

export default MeetingInvites;
