import {
  LOANS_REQUEST,
  LOANS_REQUEST_SUCCESS,
  LOANS_REQUEST_FAIL,
  LOAN_INSERT,
  LOAN_INSERT_SUCCESS,
  LOAN_INSERT_FAIL,
  LOAN_UPDATE_REQUEST,
  LOAN_UPDATE_FAIL,
  LOAN_UPDATE_SUCCESS,
  LOAN_DELETE_FAIL,
  LOAN_DELETE_SUCCESS,
  LOAN_DELETE_REQUEST,
  LOAN_DETAILS_REQUEST,
  LOAN_DETAILS_REQUEST_SUCCESS,
  LOAN_DETAILS_REQUEST_FAIL,
  LOANREPAYMENTS_REQUEST,
  LOANREPAYMENTS_REQUEST_SUCCESS,
  LOANREPAYMENTS_REQUEST_FAIL
} from '../constants/loanConstant';

export const loanRepaymentsListReducer = (
  state = { loanrepayments: [] },
  action
) => {
  switch (action.type) {
    case LOANREPAYMENTS_REQUEST:
      return {
        loading: true,
        loanrepayments: []
      };
    case LOANREPAYMENTS_REQUEST_SUCCESS:
      return {
        loading: false,
        loanrepayments: action.payload
      };
    case LOANREPAYMENTS_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
export const loanListReducer = (state = { loans: [] }, action) => {
  switch (action.type) {
    case LOANS_REQUEST:
      return {
        loading: true,
        loans: []
      };
    case LOANS_REQUEST_SUCCESS:
      return {
        loading: false,
        loans: action.payload
      };
    case LOANS_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const loanInsertReducer = (state, action) => {
  switch (action.type) {
    case LOAN_INSERT:
      return {
        loading: true,
        loanerror: [],
        loan: {}
      };
    case LOAN_INSERT_SUCCESS:
      return {
        loading: false,
        loan: {},
        success: action.payload
      };
    case LOAN_INSERT_FAIL:
      return {
        loading: false,
        loans: [],
        loanerror: action.payload
      };
    default:
      return { ...state };
  }
};

export const loanUpdateReducer = (state, action) => {
  switch (action.type) {
    case LOAN_UPDATE_REQUEST:
      return {
        loading: true,
        loansError: [],
        loan: {}
      };
    case LOAN_UPDATE_SUCCESS:
      return {
        loading: true,

        loan: {}
      };
    case LOAN_UPDATE_FAIL:
      return {
        loading: false,
        loansError: action.payload
      };
    default:
      return { ...state };
  }
};

export const loanDeleteReducer = (state, action) => {
  switch (action.type) {
    case LOAN_DELETE_REQUEST:
      return {
        loading: false,
        loansError: action.payload,
        loan: {}
      };
    case LOAN_DELETE_SUCCESS:
      return {
        loading: false,

        loan: {}
      };
    case LOAN_DELETE_FAIL:
      return {
        loading: false,
        loans: [],
        loansError: action.payload
      };
    default:
      return { ...state };
  }
};

export const loanDetailsReducer = (state = { loan: {} }, action) => {
  switch (action.type) {
    case LOAN_DETAILS_REQUEST:
      return {
        loading: true,
        loansError: [],
        loan: {}
      };
    case LOAN_DETAILS_REQUEST_SUCCESS:
      return {
        loan: action.payload,
        loading: false,
        loansError: []
      };
    case LOAN_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        loan: {},
        loansError: action.payload
      };
    default:
      return { ...state };
  }
};
