import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table, Modal } from 'react-bootstrap';
import AddExpense from './AddExpense';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExpenses } from '../../actions/expenseAction';
const GroupExpenses = () => {
  const expenseList = useSelector((state) => state.expenseList);
  const dispatch = useDispatch();
  const { expenses } = expenseList;
  useEffect(() => {
    dispatch(getAllExpenses(2));
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8 mt-5'>
          <h3 className='text-center'>
            GROUP EXPENSES
            <span style={{ float: 'right' }}>
              {' '}
              <Button
                onClick={() => {
                  setShow(true);
                }}
                className='btn btn-sm btn-primary'
              >
                Expense
              </Button>
            </span>{' '}
          </h3>
        </Col>
      </Row>

      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8 mt-5'>
          <hr></hr>
        </Col>
      </Row>

      <Row className='dflex mt-3 justify-content-center'>
        <Col className='col-8 dflex   justify-content-center'>
          <Table className='table table-responsive-md table-hover table-striped table-sm'>
            <thead>
              <tr>
                <th scope='col'>#</th>
                <th scope='col'>Description</th>
                <th scope='col'>Remarks</th>
                <th scope='col'>Date</th>
                <th scope='col'>Amount</th>
              </tr>
            </thead>
            <tbody>
              {expenses?.length > 0 &&
                expenses?.map((r, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{r.description}</td>
                    <td>{r.remarks}</td>
                    <td>{new Date(r?.date).toDateString()}</td>
                    <td>{new Intl.NumberFormat().format(r?.amount)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <AddExpense handleClose={handleClose} />
      </Modal>
    </div>
  );
};

export default GroupExpenses;
