import React, { useEffect, useState } from 'react';
import {
  deleteAttendance,
  getMeetingDetailsByID,
  insertAttendance,
} from '../actions/meetingAction';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MDBDataTableV5 } from 'mdbreact';
import Alert from '../Alert';

import Loader from '../Loader';
import Message from '../Message';
import { LinkContainer } from 'react-router-bootstrap';
import { deleteMeeting, getAllMeetings } from '../actions/meetingAction';
import {
  getStudentDetailsByMobile,
  getMeetingParticipantsByMeetingID,
} from '../studentAction';
const ReportMeeting = React.forwardRef((props, ref) => {
 // const { id } = useParams('id');
  const id = props.id;
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState('');

  const meetingDetails = useSelector((state) => state.meetingDetails);
  const { meeting, loading, error } = meetingDetails;

  const studentList = useSelector((state) => state.studentList);
  const { students, loading: studentsLoading } = studentList;

  const StudentDetails = useSelector((state) => state.StudentDetails);
  const meetingInsert = useSelector((state) => state.meetingInsert);
  const { success } = meetingInsert;
  const { student, loading: studentLoading } = StudentDetails;

  useEffect(() => {
    dispatch(getMeetingParticipantsByMeetingID(id));
  }, []);
  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
    }, 3000);
  }, [success]);

  useEffect(() => {
    dispatch(getMeetingDetailsByID(id));
    dispatch(getMeetingParticipantsByMeetingID(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!!student.id) {
      dispatch(
        insertAttendance({
          meeting: id,
          member: student.id,
        })
      );
      setMobile('');
    }
    dispatch(getMeetingParticipantsByMeetingID(id));
  }, [studentLoading, student]);

  useEffect(() => {
    dispatch(getMeetingParticipantsByMeetingID(id));
  }, []);

  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 100,
      },

      {
        label: 'Mobile',
        field: 'venue',
        sort: 'asc',
        width: 100,
      },

      {
        label: 'ID Number',
        field: 'purpose',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Group',
        field: 'group',
        sort: 'asc',
        width: 100,
      },
    ],
    rows:
      students &&
      students.length &&
      students.map((meeting) => {
        return {
          name: meeting.fullname,
          venue: meeting.mobile,
          purpose: meeting.idnumber,
          group: meeting.groupname
        };
      }),
  };

  return (
    <div ref={ref}>
      <Row>
        <Col className='col-8 mt-3 pt-3 offset-2'>
          <h2 className='text-center'>{meeting.name}</h2>
          <hr />
          <h3 className='text-center'>List of Participants</h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <Col className='col-8 offset-2'>
            {studentsLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger' children={error} />
            ) : (
              students &&
              students.length &&
              students.length > 0 && (
                <MDBDataTableV5
                  data={data}
                  searching={false}
                  paging={false}
                />
              )
            )}
          </Col>
        </Col>
      </Row>
    </div>
  );
});

export default ReportMeeting;
