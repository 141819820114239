import {
  GROUPADMINS_REQUEST,
  GROUPADMINS_REQUEST_SUCCESS,
  GROUPADMINS_REQUEST_FAIL,
  GROUPADMIN_INSERT,
  GROUPADMIN_INSERT_SUCCESS,
  GROUPADMIN_INSERT_FAIL,
  GROUPADMIN_UPDATE_REQUEST,
  GROUPADMIN_UPDATE_FAIL,
  GROUPADMIN_UPDATE_SUCCESS,
  GROUPADMIN_DELETE_FAIL,
  GROUPADMIN_DELETE_SUCCESS,
  GROUPADMIN_DELETE_REQUEST,
  GROUPADMIN_DETAILS_REQUEST,
  GROUPADMIN_DETAILS_REQUEST_SUCCESS,
  GROUPADMIN_DETAILS_REQUEST_FAIL
} from '../constants/groupadminConstant';

export const groupadminListReducer = (state = { groupadmins: [] }, action) => {
  switch (action.type) {
    case GROUPADMINS_REQUEST:
      return {
        loading: true,
        groupadmins: []
      };
    case GROUPADMINS_REQUEST_SUCCESS:
      return {
        loading: false,
        groupadmins: action.payload
      };
    case GROUPADMINS_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const groupadminInsertReducer = (state, action) => {
  switch (action.type) {
    case GROUPADMIN_INSERT:
      return {
        loading: true,
        groupadminerror: [],
        groupadmin: {}
      };
    case GROUPADMIN_INSERT_SUCCESS:
      return {
        loading: false,
        groupadmin: {},
        success: action.payload
      };
    case GROUPADMIN_INSERT_FAIL:
      return {
        loading: false,
        groupadmins: [],
        groupadminerror: action.payload
      };
    default:
      return { ...state };
  }
};

export const groupadminUpdateReducer = (state, action) => {
  switch (action.type) {
    case GROUPADMIN_UPDATE_REQUEST:
      return {
        loading: true,
        groupadminsError: [],
        groupadmin: {}
      };
    case GROUPADMIN_UPDATE_SUCCESS:
      return {
        loading: true,
        success: action.payload,
        error: {}
      };
    case GROUPADMIN_UPDATE_FAIL:
      return {
        loading: false,
        groupadminsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const groupadminDeleteReducer = (state, action) => {
  switch (action.type) {
    case GROUPADMIN_DELETE_REQUEST:
      return {
        loading: false,
        groupadminsError: action.payload,
        groupadmin: {}
      };
    case GROUPADMIN_DELETE_SUCCESS:
      return {
        loading: false,

        groupadmin: {}
      };
    case GROUPADMIN_DELETE_FAIL:
      return {
        loading: false,
        groupadmins: [],
        groupadminsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const groupadminDetailsReducer = (
  state = { groupadmin: {} },
  action
) => {
  switch (action.type) {
    case GROUPADMIN_DETAILS_REQUEST:
      return {
        loading: true,
        groupadminsError: [],
        groupadmin: {}
      };
    case GROUPADMIN_DETAILS_REQUEST_SUCCESS:
      return {
        groupadmin: action.payload,
        loading: false,
        groupadminsError: []
      };
    case GROUPADMIN_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        groupadmin: {},
        groupadminsError: action.payload
      };
    default:
      return { ...state };
  }
};
