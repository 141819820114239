import {
  REFUNDSCHEDULES_REQUEST,
  REFUNDSCHEDULES_REQUEST_SUCCESS,
  REFUNDSCHEDULES_REQUEST_FAIL,
  REFUNDSCHEDULE_INSERT,
  REFUNDSCHEDULE_INSERT_SUCCESS,
  REFUNDSCHEDULE_INSERT_FAIL,
  REFUNDSCHEDULE_UPDATE_REQUEST,
  REFUNDSCHEDULE_UPDATE_FAIL,
  REFUNDSCHEDULE_UPDATE_SUCCESS,
  REFUNDSCHEDULE_DELETE_FAIL,
  REFUNDSCHEDULE_DELETE_SUCCESS,
  REFUNDSCHEDULE_DELETE_REQUEST,
  REFUNDSCHEDULE_DETAILS_REQUEST,
  REFUNDSCHEDULE_DETAILS_REQUEST_SUCCESS,
  REFUNDSCHEDULE_DETAILS_REQUEST_FAIL
} from '../constants/refundscheduleConstant';

export const refundscheduleListReducer = (state = { refundschedules: [] }, action) => {
  switch (action.type) {
    case REFUNDSCHEDULES_REQUEST:
      return {
        loading: true,
        refundschedules: []
      };
    case REFUNDSCHEDULES_REQUEST_SUCCESS:
      return {
        loading: false,
        refundschedules: action.payload
      };
    case REFUNDSCHEDULES_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const refundscheduleInsertReducer = (state, action) => {
  switch (action.type) {
    case REFUNDSCHEDULE_INSERT:
      return {
        loading: true,
        error: [],
        refundschedule: {}
      };
    case REFUNDSCHEDULE_INSERT_SUCCESS:
      return {
        loading: false,
        refundschedule: {},
        success: action.payload
      };
    case REFUNDSCHEDULE_INSERT_FAIL:
      return {
        loading: false,
        refundschedules: [],
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export const refundscheduleUpdateReducer = (state, action) => {
  switch (action.type) {
    case REFUNDSCHEDULE_UPDATE_REQUEST:
      return {
        loading: true,
        error: [],
        refundschedule: {}
      };
    case REFUNDSCHEDULE_UPDATE_SUCCESS:
      return {
        loading: true,
        success: action.payload,
        refundschedule: {}
      };
    case REFUNDSCHEDULE_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export const refundscheduleDeleteReducer = (state, action) => {
  switch (action.type) {
    case REFUNDSCHEDULE_DELETE_REQUEST:
      return {
        loading: false,
        error: action.payload,
        refundschedule: {}
      };
    case REFUNDSCHEDULE_DELETE_SUCCESS:
      return {
        loading: false,

        refundschedule: {}
      };
    case REFUNDSCHEDULE_DELETE_FAIL:
      return {
        loading: false,
        refundschedules: [],
        error: action.payload
      };
    default:
      return { ...state };
  }
};

export const refundscheduleDetailsReducer = (state = { refundschedule: {} }, action) => {
  switch (action.type) {
    case REFUNDSCHEDULE_DETAILS_REQUEST:
      return {
        loading: true,
        error: [],
        refundschedule: {}
      };
    case REFUNDSCHEDULE_DETAILS_REQUEST_SUCCESS:
      return {
        refundschedule: action.payload,
        loading: false,
        error: []
      };
    case REFUNDSCHEDULE_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        refundschedule: {},
        error: action.payload
      };
    default:
      return { ...state };
  }
};
