import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Barcode from "react-barcode";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  changeStatus,
  deleteStudent,
  getMemberDetailsByID,
  updateStudentDetails,
} from "../studentAction";
import { useParams } from "react-router-dom";
import Alert from "../Alert";
import { getAllGroups } from "../actions/groupAction";
import { LinkContainer } from "react-router-bootstrap";
import MemberDashboard from "./dashboard/MemberDashboard";

const UpdateBio = () => {
  const { id } = useParams("id");

  const dispatch = useDispatch();
  const [fullname, setFullname] = useState("");
  const [age, setAge] = useState(0);
  const [groupid, setGroup] = useState("");
  const [idnumber, setIdnumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [position, setPosition] = useState("");
  const [occupation, setOccupation] = useState("");
  const [estate, setEstate] = useState("");
  const [ward, setWard] = useState("");
  const [statusDate, setStatusdate] = useState(new Date());
  const [status, setStatus] = useState("1");

  const studentUpdate = useSelector((state) => state.studentUpdate);
  const groupList = useSelector((state) => state.groupList);

  const { groups, loading: groupsLoading, error } = groupList;

  useEffect(() => {
    dispatch(getAllGroups());
  }, []);

  let login = localStorage.getItem("mamatwendelogin");

  let token = JSON.parse(login).token;

  const { success } = studentUpdate;
  const [A, setA] = useState("");
  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA("");
    }, 3000);
  }, [success]);
  useEffect(() => {
    // dispatch(getStudentDetailsByID(id));
    dispatch(getMemberDetailsByID(id));
  }, []);

  const submitHandler = () => {
    if (!!id) {
      if (!!fullname && fullname !== "") {
        if (!!idnumber && idnumber !== "") {
          if (!!mobile && mobile !== "") {
            if (!!groupid && groupid !== "") {
              dispatch(
                updateStudentDetails(
                  {
                    fullname,
                    age,
                    idnumber,
                    mobile,
                    occupation,
                    groupid,
                    ward,
                    estate,
                    position,
                    id,
                  },
                  token
                )
              );
            } else {
              alert("Please select group");
            }
          } else {
            alert("Mobile Number Required");
          }
        } else {
          alert("ID Number Required");
        }
      } else {
        alert("Full Name required");
      }
    }
  };
  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current
  // });

  const StudentDetails = useSelector((state) => state.StudentDetails);

  const { student, loading: detailsLoading } = StudentDetails;

  useEffect(() => {
    if (student.id == id) {
      setFullname(student.fullname);
      setIdnumber(student.idnumber);
      setAge(student.age);
      setMobile(student.mobile);
      setOccupation(student.occupation);
      setGroup(student.groupid);
      setWard(student.ward);
      setEstate(student.estate);
      setPosition(student.position);
    }
  }, [student]);

  const handleDelete = () => {
    if (!!id) {
      dispatch(deleteStudent(id));
    }
  };

  return (
    <div>
      <Row className="d-flex justify-content-center">
        <Col className="col-6 mt-3 pt-3">
          <h2 className="text-center">
            UPDATE MEMBER BIODATA
            <span style={{ float: "right" }}>
              <Link to="/list">
                <Button className="btn btn-light btn-outline-primary btn-sm">
                  All Members
                </Button>
              </Link>
            </span>
          </h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <div className="col-12">
            {A && <Alert type="success" message={A} />}
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col className="col-4">
          <Form onSubmit={submitHandler} className="border1">
            <Form.Group>
              <h4 className="text text-secondary text-center">Member Bio</h4>
              <hr />
            </Form.Group>

            <Form.Group className="my-2" as={Row} controlId="fullnames">
              <Form.Label column sm={3}>
                Full Names
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="fullname"
                  required
                  value={fullname?.toUpperCase()}
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    setFullname(e.target.value);
                  }}
                  placeholder="Full Names"
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group className="my-2" as={Row} controlId="age">
              <Form.Label column sm={3}>
                ID Number
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="idnumber"
                  value={idnumber}
                  required
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    setIdnumber(e.target.value);
                  }}
                  placeholder="0"
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group className="my-2" as={Row} controlId="age">
              <Form.Label column sm={3}>
                Age
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="age"
                  value={age}
                  required
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                  placeholder="0"
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group>
              <hr />

              <h4 className="text text-secondary text-center">Group Details</h4>
            </Form.Group>
            <Form.Group className="my-2" as={Row} controlId="age">
              <Form.Label column sm={3}>
                Group
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="groupid"
                  value={groupid}
                  required
                  className="form-control form-control-sm"
                  as="select"
                  onChange={(e) => {
                    setGroup(e.target.value);
                  }}
                >
                  {!groupsLoading &&
                    groups &&
                    groups.length &&
                    groups.map((g) => (
                      <option key={groupid} value={g.id}>
                        {g.name}
                      </option>
                    ))}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group className="my-2" as={Row} controlId="age">
              <Form.Label column sm={3}>
                Position
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="position"
                  value={position?.toUpperCase()}
                  required
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    setPosition(e.target.value);
                  }}
                  placeholder=""
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group>
              <hr />
              <h4 className="text text-secondary text-center">
                Contact & Residential Address
              </h4>
            </Form.Group>
            <Form.Group className="my-2" as={Row} controlId="age">
              <Form.Label column sm={3}>
                Mobile
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="mobile"
                  value={mobile}
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                  placeholder="07000"
                ></Form.Control>
              </Col>
            </Form.Group>

            {/* <Form.Group className='d-flex justify-content-end'>
              <Barcode value={mobile} />
            </Form.Group> */}

            <Form.Group className="my-2" as={Row} controlId="age">
              <Form.Label column sm={3}>
                Ward
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="ward"
                  value={ward}
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    setWard(e.target.value);
                  }}
                  placeholder="0"
                ></Form.Control>
              </Col>
            </Form.Group>

            <Form.Group className="my-2" as={Row} controlId="age">
              <Form.Label column sm={3}>
                Estate
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="estate"
                  value={estate}
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    setEstate(e.target.value);
                  }}
                  placeholder="0"
                ></Form.Control>
              </Col>
            </Form.Group>

            <Button
              style={{ float: "right" }}
              onClick={submitHandler}
              className="btn btn-sm"
            >
              Update Bio
            </Button>
            <Button
              style={{ float: "right" }}
              onClick={handleDelete}
              className="btn btn-sm mx-4 "
              variant="danger"
            >
              Delete
            </Button>
          </Form>
        </Col>
      </Row>

      <Row className="d-flex flex-row justify-content-center mt-3 ">
        <Col className="col-4 d-flex justify-content-end">
          <LinkContainer to={`/member/printcard/${groupid}`}>
            <Button className="btn btn-sm" variant="warning">
              Print Member Card
            </Button>
          </LinkContainer>
        </Col>
      </Row>

      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
    </div>
  );
};

export default UpdateBio;
