import React, { useState, useEffect } from 'react';
import '../assets/Login.css';
import { Row, Col, Form, Button } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { auserLogin } from '../actions/loginAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../App.css';
import uwepa from '../assets/uwepa.jpeg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  const { login, loginsLoading, error } = userLogin;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [e, setE] = useState('');

  useEffect(() => {
    if (!loginsLoading && error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'loginError'
      });
      setUsername('');
      setPassword('');
    }
  }, [error]);

  const redirect = location.search ? location.search.split('=')[1] : '/login';

  useEffect(() => {
    if (!loginsLoading && login && login?.name) {
      window.location.href = '/';
    }
  }, [login, redirect, navigate, loginsLoading]);

  const submitHandler = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (!!username && !!password) {
      dispatch(auserLogin(username, password));
    } else {
      alert('Username and password required');
    }
  };

  return (
    <div className='login'>
      <Row className='form'>
        <Col>
          <img alt='logo' src={uwepa} width={300} height={100} />
          <hr />
          <Form
            className='formitself'
            noValidate
            validated={validated}
            onSubmit={submitHandler}
          >
            <Form.Group as={Row} controlId='username'>
              {/* <Form.Label column sm={3}>
                UserId
              </Form.Label> */}
              <label className='loginlabel'> Username</label>
              <Col sm={12}>
                <Form.Control
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  required
                  value={username}
                  name='username'
                  placeholder='User Name'
                  className='form-control form-control-sm'
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>
                  User Name Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group className='my-2' as={Row} controlId='password'>
              <label className='loginlabel'>Password</label>
              <Col sm={12}>
                <Form.Control
                  required
                  autoComplete='off'
                  type='password'
                  name='password'
                  value={password}
                  className='form-control form-control-sm'
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  // onKeyDown={(e) => something(e)}
                  placeholder='*****'
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>
                  Password Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Row>
              <Col>
                <Button
                  className='btn btn-secondary btn-sm btn-block my-4'
                  variant='success'
                  onClick={submitHandler}
                >
                  Log In
                </Button>{' '}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>

    // </Modal>
  );
};

export default Login;
