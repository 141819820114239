import { useEffect } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import RequireAuth from './RequireAuth';
const Logout = () => {
  useEffect(() => {
    localStorage.clear();
  }, []);

  return <RequireAuth />;
};

export default Logout;
