import {
  MEETINGS_REQUEST,
  MEETINGS_REQUEST_SUCCESS,
  MEETINGS_REQUEST_FAIL,
  MEETING_INSERT,
  MEETING_INSERT_SUCCESS,
  MEETING_INSERT_FAIL,
  MEETING_UPDATE_REQUEST,
  MEETING_UPDATE_FAIL,
  MEETING_UPDATE_SUCCESS,
  MEETING_DELETE_FAIL,
  MEETING_DELETE_SUCCESS,
  MEETING_DELETE_REQUEST,
  MEETING_DETAILS_REQUEST,
  MEETING_DETAILS_REQUEST_SUCCESS,
  MEETING_DETAILS_REQUEST_FAIL,
  MEETING_CONTRIBUTIONS_REQUEST,
  MEETING_CONTRIBUTIONS_REQUEST_SUCCESS,
  MEETING_CONTRIBUTIONS_REQUEST_FAIL,
  MEETING_LOANS_REQUEST,
  MEETING_LOANS_REQUEST_SUCCESS,
  MEETING_LOANS_REQUEST_FAIL
} from '../constants/MeetingConstant';

export const meetingListReducer = (state = { meetings: [] }, action) => {
  switch (action.type) {
    case MEETINGS_REQUEST:
      return {
        loading: true,
        meetings: []
      };
    case MEETINGS_REQUEST_SUCCESS:
      return {
        loading: false,
        meetings: action.payload
      };
    case MEETINGS_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const meetingFinancialsReducer = (
  state = { meetingfinancial: [] },
  action
) => {
  switch (action.type) {
    case MEETING_CONTRIBUTIONS_REQUEST:
      return {
        loading: true,
        meetingfinancial: []
      };
    case MEETING_CONTRIBUTIONS_REQUEST_SUCCESS:
      return {
        loading: false,
        meetingfinancial: action.payload
      };
    case MEETING_CONTRIBUTIONS_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const meetingInsertReducer = (state, action) => {
  switch (action.type) {
    case MEETING_INSERT:
      return {
        loading: true,
        meetingerror: [],
        meeting: {}
      };
    case MEETING_INSERT_SUCCESS:
      return {
        loading: false,
        meeting: {},
        success: action.payload
      };
    case MEETING_INSERT_FAIL:
      return {
        loading: false,
        meetings: [],
        meetingerror: action.payload
      };
    default:
      return { ...state };
  }
};

export const meetingUpdateReducer = (state, action) => {
  switch (action.type) {
    case MEETING_UPDATE_REQUEST:
      return {
        loading: true,
        meetingsError: [],
        meeting: {}
      };
    case MEETING_UPDATE_SUCCESS:
      return {
        loading: true,

        meeting: {}
      };
    case MEETING_UPDATE_FAIL:
      return {
        loading: false,
        meetingsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const meetingDeleteReducer = (state, action) => {
  switch (action.type) {
    case MEETING_DELETE_REQUEST:
      return {
        loading: false,
        meetingsError: action.payload,
        meeting: {}
      };
    case MEETING_DELETE_SUCCESS:
      return {
        loading: false,

        meeting: {}
      };
    case MEETING_DELETE_FAIL:
      return {
        loading: false,
        meetings: [],
        meetingsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const meetingDetailsReducer = (state = { meeting: {} }, action) => {
  switch (action.type) {
    case MEETING_DETAILS_REQUEST:
      return {
        loading: true,
        meetingsError: [],
        meeting: {}
      };
    case MEETING_DETAILS_REQUEST_SUCCESS:
      return {
        meeting: action.payload,
        loading: false,
        meetingsError: []
      };
    case MEETING_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        meeting: {},
        meetingsError: action.payload
      };
    default:
      return { ...state };
  }
};
