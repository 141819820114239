import axios from 'axios';
import {
  CONTRIBUTIONS_REQUEST,
  CONTRIBUTIONS_REQUEST_SUCCESS,
  CONTRIBUTIONS_REQUEST_FAIL,
  CONTRIBUTION_INSERT,
  CONTRIBUTION_INSERT_SUCCESS,
  CONTRIBUTION_INSERT_FAIL,
  CONTRIBUTION_DETAILS_REQUEST,
  CONTRIBUTION_DETAILS_REQUEST_SUCCESS,
  CONTRIBUTION_DETAILS_REQUEST_FAIL,
  CONTRIBUTION_UPDATE_REQUEST,
  CONTRIBUTION_UPDATE_SUCCESS,
  CONTRIBUTION_UPDATE_FAIL,
  CONTRIBUTION_DELETE_REQUEST,
  CONTRIBUTION_DELETE_SUCCESS,
  CONTRIBUTION_DELETE_FAIL
} from '../constants/contributionsConstant';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const config = {
  headers: {
    headers: { 'Content-Type': 'application/json' }
  }
};
export const insertContribution = (attendance) => async (dispatch) => {
  dispatch({ type: CONTRIBUTION_INSERT });

  const { data } = await axiosInstance.post(
    '/api/contribution/add',
    attendance,
    config
  );
  try {
    dispatch({
      type: CONTRIBUTION_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: CONTRIBUTION_INSERT_FAIL, payload: error.message });
  }
};
export const deleteContribution = (id) => async (dispatch) => {
  dispatch({ type: CONTRIBUTION_DELETE_REQUEST });
  try {
    const { data } = await axiosInstance.delete(`/api/attendance/delete/${id}`);
    dispatch({ type: CONTRIBUTION_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CONTRIBUTION_DELETE_FAIL,
      payload: error.message
    });
  }
};

export const getContributionByMeeting = (meeting) => async (dispatch) => {
  dispatch({ type: CONTRIBUTIONS_REQUEST });
  try {
    const { data } = await axiosInstance.get(
      `/api/meeting/${meeting}/contributions`
    );
    dispatch({
      type: CONTRIBUTIONS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: CONTRIBUTIONS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getContributionByMemberID = (member) => async (dispatch) => {
  dispatch({ type: CONTRIBUTIONS_REQUEST });

  try {
    const { data } = await axiosInstance.get(
      `/api/contributions/${member}/member`
    );
    dispatch({
      type: CONTRIBUTIONS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: CONTRIBUTIONS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getAllMeetings = () => async (dispatch) => {
  dispatch({ type: CONTRIBUTIONS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/meetings`);
    dispatch({
      type: CONTRIBUTIONS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: CONTRIBUTIONS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getMeetingDetailsByID = (id) => async (dispatch) => {
  dispatch({ type: CONTRIBUTION_DETAILS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/meeting/${id}`);

    dispatch({
      type: CONTRIBUTION_DETAILS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: CONTRIBUTION_DETAILS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const updateMeetingDetails = (meeting) => async (dispatch) => {
  dispatch({ type: CONTRIBUTION_UPDATE_REQUEST });
  try {
    const res = await axiosInstance.put(
      `/api/meeting/${meeting}`,
      meeting.id,
      config
    );
    dispatch({ type: CONTRIBUTION_UPDATE_SUCCESS, payload: 'success' });
  } catch (error) {
    dispatch({
      type: CONTRIBUTION_UPDATE_FAIL,
      payload: error.message
    });
  }
};

export const deleteMeeting = (id) => async (dispatch) => {};
