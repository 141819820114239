import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import FormContainer from '../FormContainer';
import Alert from '../Alert';
import { getAllConstituency} from '../actions/constituencyAction';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { getWardDetailsByID, updateWardDetails } from '../actions/wardAction';
const UpdateWard = () => {
  const { id } = useParams('id');
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [constituency, setConstituency] = useState('');

  const wardUpdate = useSelector((state) => state.wardUpdate);
  const wardDetails = useSelector((state) => state.wardDetails);
  const { ward, loading: groupLoading } = wardDetails;

  const constituencyList = useSelector((state) => state.constituencyList);
  const { constituencies, loading: constituenciesLoading } = constituencyList;

  useEffect(() => {
    dispatch(getAllConstituency());
    dispatch(getWardDetailsByID(id));
  }, [id]);

  const { success } = wardUpdate;
  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
    }, 3000);
  }, [success]);

  useEffect(() => {
    if (id == ward.id) {
      setName(ward.name);
      setConstituency(ward.constituency);
    }
  }, [ward]);

  const submitHandler = () => {
    if (!!constituency) {
      if (!!name) {
        dispatch(
          updateWardDetails({
            name,
            constituency,
            id
          })
        );
      }
    }
  };

  return (
    <div>
      <Row>
        <Col className='col-6 offset-3 mt-3 pt-3'>
          <h2 className='text-center'>
            UPDATE WARD DETAILS
            <span style={{ float: 'right' }}>
              <Link to='/wards'>
                <Button className='btn btn-light btn-sm'>All Wards</Button>
              </Link>
            </span>
          </h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col className='col-8 offset-2 my-3'>
          <FormContainer>
            <Row>
              <Col className='mt-3'>
                <div className='col-12'>
                  {A && <Alert type='success' message={A} />}
                </div>
              </Col>
            </Row>
            <Form onSubmit={submitHandler} className='border1'>
              <Form.Group className='my-2' as={Row} controlId='fullnames'>
                <Form.Label column sm={3}>
                  WARD NAME
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name='name'
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder='Group Name'
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group className='my-2' as={Row} controlId='ward'>
                <Form.Label column sm={3}>
                  CHOOSE CONSTITUENCY
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as='select'
                    name='constituency'
                    value={constituency}
                    onChange={(e) => {
                      setConstituency(e.target.value);
                    }}
                    placeholder='0'
                  >
                    <option value={''}>--Select Constituency--</option>
                    {!constituenciesLoading &&
                      constituencies &&
                      constituencies.length &&
                      constituencies.map((constituency) => (
                        <option key={constituency.id} value={constituency.id}>
                          {constituency.name}
                        </option>
                      ))}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Button
                style={{ float: 'right' }}
                onClick={submitHandler}
                className='btn btn-sm'
              >
                SAVE
              </Button>
            </Form>
          </FormContainer>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateWard;
