export const TRANSACTIONS_REQUEST = 'TRANSACTION_REQUEST';
export const TRANSACTIONS_REQUEST_SUCCESS = 'TRANSACTION_REQUEST_SUCCESS';
export const TRANSACTIONS_REQUEST_FAIL = 'TRANSACTION_REQUEST_FAIL';

export const TRANSACTION_INSERT = 'TRANSACTION_INSERT';
export const TRANSACTION_INSERT_SUCCESS = 'TRANSACTION_INSERT_SUCCESS';
export const TRANSACTION_INSERT_FAIL = 'TRANSACTION_INSERT_FAIL';

export const TRANSACTION_UPDATE_REQUEST = 'TRANSACTION_UPDATE_REQUEST';
export const TRANSACTION_UPDATE_SUCCESS = 'TRANSACTION_UPDATE_SUCCESS';
export const TRANSACTION_UPDATE_FAIL = 'TRANSACTION_UPDATE_FAIL';

export const TRANSACTION_DELETE_REQUEST = 'TRANSACTION_DELETE_REQUEST';
export const TRANSACTION_DELETE_SUCCESS = 'TRANSACTION_DELETE_SUCCESS';
export const TRANSACTION_DELETE_FAIL = 'TRANSACTION_DELETE_FAIL';

export const TRANSACTION_DETAILS_REQUEST = 'TRANSACTION_DETAILS_REQUEST';
export const TRANSACTION_DETAILS_REQUEST_SUCCESS =
  'TRANSACTION_DETAILS_REQUEST_SUCCESS';
export const TRANSACTION_DETAILS_REQUEST_FAIL =
  'TRANSACTION_DETAILS_REQUEST_FAIL';
