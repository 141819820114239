import React, { useEffect, useState } from 'react';
import './balancecard.css';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGroups } from '../../actions/groupAction';
import { getAllGroupTransactions } from '../../actions/transactionAction';
import { AsyncPaginate } from 'react-select-async-paginate';
import Loans from '../loan/Loans';
import { getAllLoansBygroup } from '../../actions/loanAction';

const Balancecard = () => {
  const dispatch = useDispatch();
  const groupList = useSelector((state) => state.groupList);

  const transactionList = useSelector((state) => state.transactionList);

  const { transactions, loading: transactionsLoading } = transactionList;

  const { groups, loading, error } = groupList;

  const [group, setGroup] = useState(0);

  useEffect(() => {
    //dispatch(getRooms());
    dispatch(getAllGroups());
  }, []);

  useEffect(() => {
    dispatch(getAllLoansBygroup(group));
  }, [group]);

  const [loans, setLoans] = useState(0);
  const [contributions, setContributions] = useState(0);

  useEffect(() => {
    if (!transactionsLoading && transactions?.length > 0) {
      setLoans(transactions?.filter((f) => f.summarytype == 'loans')[0]);
      setContributions(
        transactions?.filter((f) => f.summarytype == 'contribution')[0]
      );
    }
  }, [transactionsLoading, transactions]);

  const options = [];
  {
    !loading &&
      groups?.length > 0 &&
      groups?.map((a) =>
        options.push({
          value: a.id,
          label: a.name
        })
      );
  }

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  const LoadOptions = async (search, prevOptions) => {
    await sleep(0);

    let filteredOptions;
    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = options.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore
    };
  };

  return (
    <div>
      <Row className='dflex justify-content-center'>
        <Col className='mt-5 pt-5 col-lg-8'>
          <label className='form.label'>Choose Group</label>

          <AsyncPaginate
            //value={supplier}
            loadOptions={LoadOptions}
            onChange={(e) => {
              dispatch(getAllGroupTransactions(e.value));
              setGroup(e.value);
            }}
          />

          {/* <select
            typeof='select'
            className='form-control form-select form-control-sm'
            onChange={(e) => {
              dispatch(getAllGroupTransactions(e.target.value));
            }}
          >
            <option>Choose Group</option>
            {!loading &&
              groups?.length > 0 &&
              groups?.map((g) => (
                <option key={g.id} value={g.id}>
                  {' '}
                  {g.name}{' '}
                </option>
              ))}
          </select> */}
          <hr />
        </Col>
      </Row>

      {/* <Row className='d-flex justify-content-center mt-5 pt-5'>
        <Col className='col-lg-8 col-md-5'>
          <Row className=''>
            <Col className='balance-display d-flex flex-column align-items-center '>
              <label className='label'>Group Total balance</label>
              <span className='value'>
                {contributions?.amount > 0
                  ? new Intl.NumberFormat().format(contributions?.amount)
                  : 0}
              </span>
            </Col>

            <Col className='balance-display d-flex flex-column align-items-center '>
              <label className='label'>Current Available balance</label>
              <span className='value'>0</span>
            </Col>
            <Col className='balance-display d-flex flex-column align-items-center '>
              <label className='label'>Pending Receivables</label>
              <span className='value-red'>0</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
            </Col>
          </Row>
          <Row className=''>
            <Col className='loan-display d-flex flex-column align-items-center '>
              <label className='label'>Outstanding Loans</label>
              <span className='value'>
                {loans?.amount > 0
                  ? new Intl.NumberFormat().format(loans?.amount)
                  : 0}
              </span>
            </Col>

            <Col className='loan-display d-flex flex-column align-items-center '>
              <label className='label'>Overdue Loans</label>
              <span className='value'>0</span>
            </Col>
            <Col className='loan-display d-flex flex-column align-items-center '>
              <label className='label'>Pending Receivables</label>
              <span className='value-red'>0</span>
            </Col>
          </Row>
        </Col>
      </Row> */}

      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8 dflex justify-content-center'>
          <h3 className='text-center'>List of Loans</h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <Loans />
        </Col>
      </Row>
    </div>
  );
};

export default Balancecard;
