import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getMemberDetailsByID } from '../../studentAction';
const Memberdetails = ({ student }) => {
  // const { id } = useParams('id');
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   // dispatch(getStudentDetailsByID(id));
  //   dispatch(getMemberDetailsByID(id));
  // }, []);
  // const { student } = useSelector((state) => state.StudentDetails);

  return (
    <div>
      <Row className='mt-4 p-5'>
        <Col style={{ border: '2px solid gold', borderRadius: '5px' }}>
          <div>
            <label className='card-label'>Name</label>{' '}
            <span className='card-data'>{student?.fullname}</span>
          </div>
          <div>
            <label className='card-label'>ID Number</label>{' '}
            <span className='card-data'>{student?.idnumber}</span>
          </div>
          <div>
            <label className='card-label'>Mobile</label>{' '}
            <span className='card-data'>+254{student?.mobile}</span>
          </div>
          <div>
            <label className='card-label'>Group</label>{' '}
            <span className='card-data'>{student?.name}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Memberdetails;
