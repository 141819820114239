import axios from 'axios';
import {
  MEETINGS_REQUEST,
  MEETINGS_REQUEST_SUCCESS,
  MEETINGS_REQUEST_FAIL,
  MEETING_INSERT,
  MEETING_INSERT_SUCCESS,
  MEETING_INSERT_FAIL,
  MEETING_DETAILS_REQUEST,
  MEETING_DETAILS_REQUEST_SUCCESS,
  MEETING_DETAILS_REQUEST_FAIL,
  MEETING_UPDATE_REQUEST,
  MEETING_UPDATE_SUCCESS,
  MEETING_UPDATE_FAIL,
  MEETING_DELETE_REQUEST,
  MEETING_DELETE_SUCCESS,
  MEETING_DELETE_FAIL,
  MEETING_CONTRIBUTIONS_REQUEST,
  MEETING_CONTRIBUTIONS_REQUEST_SUCCESS,
  MEETING_CONTRIBUTIONS_REQUEST_FAIL,
  MEETING_LOANS_REQUEST,
  MEETING_LOANS_REQUEST_SUCCESS,
  MEETING_LOANS_REQUEST_FAIL
} from '../constants/MeetingConstant';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const config = {
  headers: {
    headers: { 'Content-Type': 'application/json' }
  }
};
export const insertAttendance = (attendance) => async (dispatch) => {
  dispatch({ type: MEETING_INSERT });
  const { data } = await axiosInstance.post(
    '/api/attendance/',
    attendance,
    config
  );
  try {
    dispatch({
      type: MEETING_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: MEETING_INSERT_FAIL, payload: error.message });
  }
};

export const inviteMeeting = (attendance) => async (dispatch) => {
  dispatch({ type: MEETING_INSERT });
  const { data } = await axiosInstance.post(
    '/api/meeting/invite/',
    attendance,
    config
  );
  try {
    dispatch({
      type: MEETING_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: MEETING_INSERT_FAIL, payload: error.message });
  }
};

export const inviteGroupMeeting = (attendance) => async (dispatch) => {
  dispatch({ type: MEETING_INSERT });
  const { data } = await axiosInstance.post(
    '/api/meeting/group/invite/',
    attendance,
    config
  );
  try {
    dispatch({
      type: MEETING_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: MEETING_INSERT_FAIL, payload: error.message });
  }
};

export const deleteInvitation = (attendance) => async (dispatch) => {
  dispatch({ type: MEETING_INSERT });

  const { data } = await axiosInstance.delete(
    `/api/meeting/deinvite/${attendance}`,
    attendance,
    config
  );
  try {
    dispatch({
      type: MEETING_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: MEETING_INSERT_FAIL, payload: error.message });
  }
};

export const getApprovedAttendance = (id, meeting) => async (dispatch) => {
  dispatch({ type: MEETING_DETAILS_REQUEST });

  console.log(`Meeting---${meeting}`);
  console.log(`-------------------------`);
  console.log(`Barcode --- ${id}`);
  try {
    const { data } = await axiosInstance.get(
      `/api/attendance/barcode/${id}/${meeting}`
    );
    dispatch({ type: MEETING_DETAILS_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MEETING_DETAILS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const deleteAttendance = (id) => async (dispatch) => {
  dispatch({ type: MEETING_DELETE_REQUEST });
  try {
    const { data } = await axiosInstance.put(`/api/attendance/delete/${id}`);
    dispatch({ type: MEETING_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MEETING_DELETE_FAIL,
      payload: error.message
    });
  }
};

export const insertMeeting = (meeting) => async (dispatch) => {
  dispatch({ type: MEETING_INSERT });
  const { data } = await axiosInstance.post('/api/meeting/', meeting, config);
  try {
    dispatch({
      type: MEETING_INSERT_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({ type: MEETING_INSERT_FAIL, payload: error.message });
  }
};

export const getAllMeetings = (status) => async (dispatch) => {
  dispatch({ type: MEETINGS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/meetings/${status}`);
    dispatch({
      type: MEETINGS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: MEETINGS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getMeetingDetailsByID = (id) => async (dispatch) => {
  dispatch({ type: MEETING_DETAILS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/meeting/${id}`);

    dispatch({
      type: MEETING_DETAILS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: MEETING_DETAILS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const getMeetingDetailsTablebanking = (id) => async (dispatch) => {
  dispatch({ type: MEETING_CONTRIBUTIONS_REQUEST });
  try {
    const { data } = await axiosInstance.get(`/api/meeting/tablebanking/${id}`);

    dispatch({
      type: MEETING_CONTRIBUTIONS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: MEETING_CONTRIBUTIONS_REQUEST_FAIL,
      payload: error.message
    });
  }
};
export const getMeetingFinancingTablebanking = (id) => async (dispatch) => {
  dispatch({ type: MEETING_CONTRIBUTIONS_REQUEST });
  try {
    const { data } = await axiosInstance.get(
      `/api/meeting/tablebanking/member/${id}`
    );

    dispatch({
      type: MEETING_CONTRIBUTIONS_REQUEST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: MEETING_CONTRIBUTIONS_REQUEST_FAIL,
      payload: error.message
    });
  }
};

export const updateMeetingDetails = (meeting) => async (dispatch) => {
  dispatch({ type: MEETING_UPDATE_REQUEST });
  try {
    const res = await axiosInstance.put(
      `/api/meeting/${meeting}`,
      meeting.id,
      config
    );
    dispatch({ type: MEETING_UPDATE_SUCCESS, payload: 'success' });
  } catch (error) {
    dispatch({
      type: MEETING_UPDATE_FAIL,
      payload: error.message
    });
  }
};

export const closeMeeting = (meeting) => async (dispatch) => {
  dispatch({ type: MEETING_UPDATE_REQUEST });
  try {
    const res = await axiosInstance.put(
      `/api/meeting/${meeting}/close`,
      meeting.id,
      config
    );
    dispatch({ type: MEETING_UPDATE_SUCCESS, payload: 'success' });
  } catch (error) {
    dispatch({
      type: MEETING_UPDATE_FAIL,
      payload: error.message
    });
  }
};

export const deleteMeeting = (id) => async (dispatch) => {};
