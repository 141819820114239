export const MEETINGS_REQUEST = 'MEETING_REQUEST';
export const MEETINGS_REQUEST_SUCCESS = 'MEETING_REQUEST_SUCCESS';
export const MEETINGS_REQUEST_FAIL = 'MEETING_REQUEST_FAIL';

export const MEETING_INSERT = 'MEETING_INSERT';
export const MEETING_INSERT_SUCCESS = 'MEETING_INSERT_SUCCESS';
export const MEETING_INSERT_FAIL = 'MEETING_INSERT_FAIL';

export const MEETING_UPDATE_REQUEST = 'MEETING_UPDATE_REQUEST';
export const MEETING_UPDATE_SUCCESS = 'MEETING_UPDATE_SUCCESS';
export const MEETING_UPDATE_FAIL = 'MEETING_UPDATE_FAIL';

export const MEETING_DELETE_REQUEST = 'MEETING_DELETE_REQUEST';
export const MEETING_DELETE_SUCCESS = 'MEETING_DELETE_SUCCESS';
export const MEETING_DELETE_FAIL = 'MEETING_DELETE_FAIL';

export const MEETING_DETAILS_REQUEST = 'MEETING_DETAILS_REQUEST';
export const MEETING_DETAILS_REQUEST_SUCCESS =
  'MEETING_DETAILS_REQUEST_SUCCESS';
export const MEETING_DETAILS_REQUEST_FAIL = 'MEETING_DETAILS_REQUEST_FAIL';

export const MEETING_CONTRIBUTIONS_REQUEST = 'MEETING_CONTRIBUTIONS_REQUEST';
export const MEETING_CONTRIBUTIONS_REQUEST_SUCCESS =
  'MEETING_CONTRIBUTIONS_REQUEST_SUCCESS';
export const MEETING_CONTRIBUTIONS_REQUEST_FAIL =
  'MEETING_CONTRIBUTIONS_REQUEST_FAIL';

export const MEETING_LOANS_REQUEST = 'MEETING_LOANS_REQUEST';
export const MEETING_LOANS_REQUEST_SUCCESS = 'MEETING_LOANS_REQUEST_SUCCESS';
export const MEETING_LOANS_REQUEST_FAIL = 'MEETING_LOANS_REQUEST_FAIL';
