import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Alert from '../../Alert';
import { insertLogin } from '../../actions/loginAction';
import { useDispatch, useSelector } from 'react-redux';
import { insertExpense } from '../../actions/expenseAction';
const AddExpense = ({ handleClose }) => {
  const [remarks, setRemark] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [A, setA] = useState('');
  const dispatch = useDispatch();
  const loginInsert = useSelector((state) => state.loginInsert);
  const { success } = loginInsert;

  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
    }, 3000);
  }, [success]);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!!description && description !== '') {
      if (!!remarks && remarks !== '') {
        if (!!amount && amount !== '' && amount > 0) {
          dispatch(
            insertExpense({
              description,
              remarks,
              amount,
              groupid: 2,
              author: 1,
              date: new Date()
            })
          );

          setDescription('');
          setRemark('');
          setAmount(0.0);
          handleClose();
        }
      }
    }
  };
  return (
    <div className='pt-3'>
      <h4 className='text-center '>Expense</h4>
      <hr />

      <Form onSubmit={submitHandler} className='border1 p-4 pb-3'>
        <Form.Group className='my-2' as={Row} controlId='fullnames'>
          <Form.Label column sm={3}>
            <label>Natur</label>
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              as='select'
              name='description'
              value={description}
              className='form-control form-control-sm'
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            >
              <option>Select Expense Type</option>
              <option>Administration Fee</option>
              <option>Wallet Charges</option>
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group className='my-2' as={Row} controlId='fullnames'>
          <Form.Label column sm={3}>
            Remark
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              name='remarks'
              value={remarks}
              className='form-control form-control-sm'
              onChange={(e) => {
                setRemark(e.target.value);
              }}
              placeholder='June Admin/Bank Charges June'
            ></Form.Control>
          </Col>
        </Form.Group>

        <Form.Group className='my-2' as={Row} controlId='fullnames'>
          <Form.Label column sm={3}>
            Mobile
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              name='amount'
              value={amount}
              className='form-control form-control-sm'
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              placeholder='0.00'
            ></Form.Control>
          </Col>
        </Form.Group>

        <Button onClick={submitHandler} className='btn btn-sm btn-block'>
          Save And Close
        </Button>
      </Form>
    </div>
  );
};

export default AddExpense;
