import {
  STUDENT_REQUEST,
  STUDENT_REQUEST_SUCCESS,
  STUDENT_REQUEST_FAIL,
  STUDENT_INSERT,
  STUDENT_INSERT_SUCCESS,
  STUDENT_INSERT_FAIL,
  STUDENT_UPDATE_REQUEST,
  STUDENT_UPDATE_FAIL,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_DELETE_FAIL,
  STUDENT_DELETE_SUCCESS,
  STUDENT_DELETE_REQUEST,
  STUDENT_DETAILS_REQUEST,
  STUDENT_DETAILS_REQUEST_SUCCESS,
  STUDENT_DETAILS_REQUEST_FAIL,
  INVITE_REQUEST_SUCCESS,
  INVITE_REQUEST,
  INVITE_REQUEST_FAIL
} from './studentConstant';

export const studentListReducer = (state = { students: [] }, action) => {
  switch (action.type) {
    case STUDENT_REQUEST:
      return {
        loading: true,
        students: []
      };
    case STUDENT_REQUEST_SUCCESS:
      return {
        loading: false,
        students: action.payload
      };
    case STUDENT_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
export const invitedListReducer = (state = { students: [] }, action) => {
  switch (action.type) {
    case INVITE_REQUEST:
      return {
        loading: true,
        invites: []
      };
    case INVITE_REQUEST_SUCCESS:
      return {
        loading: false,
        invites: action.payload
      };
    case INVITE_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
export const studentInsertReducer = (state, action) => {
  switch (action.type) {
    case STUDENT_INSERT:
      return {
        loading: true,
        studenterror: [],
        student: {}
      };
    case STUDENT_INSERT_SUCCESS:
      return {
        loading: false,
        student: {},
        success: action.payload
      };
    case STUDENT_INSERT_FAIL:
      return {
        loading: false,
        students: [],
        studenterror: action.payload
      };
    default:
      return { ...state };
  }
};

export const studentUpdateReducer = (state, action) => {
  switch (action.type) {
    case STUDENT_UPDATE_REQUEST:
      return {
        loading: true,
        studentsError: [],
        student: {}
      };
    case STUDENT_UPDATE_SUCCESS:
      return {
        loading: true,
        success: action.payload,
        student: {}
      };
    case STUDENT_UPDATE_FAIL:
      return {
        loading: false,
        studentsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const studentDeleteReducer = (state, action) => {
  switch (action.type) {
    case STUDENT_DELETE_REQUEST:
      return {
        loading: false,
        studentsError: action.payload,
        student: {}
      };
    case STUDENT_DELETE_SUCCESS:
      return {
        loading: false,

        student: {}
      };
    case STUDENT_DELETE_FAIL:
      return {
        loading: false,
        students: [],
        studentsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const StudentDetailsReducer = (state = { student: {} }, action) => {
  switch (action.type) {
    case STUDENT_DETAILS_REQUEST:
      return {
        loading: true,
        studentsError: [],
        student: {}
      };
    case STUDENT_DETAILS_REQUEST_SUCCESS:
      return {
        student: action.payload,
        loading: false,
        studentsError: {}
      };
    case STUDENT_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        student: {},
        studentsError: action.payload
      };
    default:
      return { ...state };
  }
};
