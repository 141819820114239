import {
  CONTRIBUTIONS_REQUEST,
  CONTRIBUTIONS_REQUEST_SUCCESS,
  CONTRIBUTIONS_REQUEST_FAIL,
  CONTRIBUTION_INSERT,
  CONTRIBUTION_INSERT_SUCCESS,
  CONTRIBUTION_INSERT_FAIL,
  CONTRIBUTION_UPDATE_REQUEST,
  CONTRIBUTION_UPDATE_FAIL,
  CONTRIBUTION_UPDATE_SUCCESS,
  CONTRIBUTION_DELETE_FAIL,
  CONTRIBUTION_DELETE_SUCCESS,
  CONTRIBUTION_DELETE_REQUEST,
  CONTRIBUTION_DETAILS_REQUEST,
  CONTRIBUTION_DETAILS_REQUEST_SUCCESS,
  CONTRIBUTION_DETAILS_REQUEST_FAIL
} from '../constants/contributionsConstant';

export const contributionListReducer = (
  state = { contributions: [] },
  action
) => {
  switch (action.type) {
    case CONTRIBUTIONS_REQUEST:
      return {
        loading: true,
        contributions: []
      };
    case CONTRIBUTIONS_REQUEST_SUCCESS:
      return {
        loading: false,
        contributions: action.payload
      };
    case CONTRIBUTIONS_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const contributionInsertReducer = (state, action) => {
  switch (action.type) {
    case CONTRIBUTION_INSERT:
      return {
        loading: true,
        contributionerror: [],
        contribution: {}
      };
    case CONTRIBUTION_INSERT_SUCCESS:
      return {
        loading: false,
        contribution: {},
        success: action.payload
      };
    case CONTRIBUTION_INSERT_FAIL:
      return {
        loading: false,
        contributions: [],
        contributionerror: action.payload
      };
    default:
      return { ...state };
  }
};

export const contributionUpdateReducer = (state, action) => {
  switch (action.type) {
    case CONTRIBUTION_UPDATE_REQUEST:
      return {
        loading: true,
        contributionsError: [],
        contribution: {}
      };
    case CONTRIBUTION_UPDATE_SUCCESS:
      return {
        loading: true,

        contribution: {}
      };
    case CONTRIBUTION_UPDATE_FAIL:
      return {
        loading: false,
        contributionsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const contributionDeleteReducer = (state, action) => {
  switch (action.type) {
    case CONTRIBUTION_DELETE_REQUEST:
      return {
        loading: false,
        contributionsError: action.payload,
        contribution: {}
      };
    case CONTRIBUTION_DELETE_SUCCESS:
      return {
        loading: false,

        contribution: {}
      };
    case CONTRIBUTION_DELETE_FAIL:
      return {
        loading: false,
        contributions: [],
        contributionsError: action.payload
      };
    default:
      return { ...state };
  }
};

export const contributionDetailsReducer = (
  state = { contribution: {} },
  action
) => {
  switch (action.type) {
    case CONTRIBUTION_DETAILS_REQUEST:
      return {
        loading: true,
        contributionsError: [],
        contribution: {}
      };
    case CONTRIBUTION_DETAILS_REQUEST_SUCCESS:
      return {
        contribution: action.payload,
        loading: false,
        contributionsError: []
      };
    case CONTRIBUTION_DETAILS_REQUEST_FAIL:
      return {
        loading: false,
        contribution: {},
        contributionsError: action.payload
      };
    default:
      return { ...state };
  }
};
