import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table, Modal } from 'react-bootstrap';
import AddRefund from './AddRefund';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRefundschedules } from '../../actions/refundscheduleAction';
import { LinkContainer } from 'react-router-bootstrap';
const ContributionRefunds = () => {
  const dispatch = useDispatch();

  const refundscheduleList = useSelector((state) => state.refundscheduleList);

  const { refundschedules } = refundscheduleList;
  useEffect(() => {
    dispatch(getAllRefundschedules(2));
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    dispatch(getAllRefundschedules(2));
  };
  const handleShow = () => setShow(true);

  return (
    <div>
      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8 mt-5'>
          <h3 className='text-center'>
            CONTRIBUTION REFUND SCHEDULE
            <span style={{ float: 'right' }}>
              {' '}
              <Button
                onClick={() => {
                  setShow(true);
                }}
                className='btn btn-sm btn-primary'
              >
                New Refund
              </Button>
            </span>{' '}
          </h3>
        </Col>
      </Row>

      <Row className='dflex justify-content-center'>
        <Col className='col-lg-8 mt-5'>
          <hr></hr>
        </Col>
      </Row>

      <Row className='dflex mt-3 justify-content-center'>
        <Col className='col-8 dflex   justify-content-center'>
          <Table className='table table-responsive-md table-hover table-striped table-sm'>
            <thead>
              <tr>
                <th scope='col'>#</th>
                <th scope='col'>Member</th>
                <th scope='col'>Remarks</th>
                <th scope='col'>Date</th>
                <th scope='col'>Refund Amount</th>
                <th scope='col'>Details</th>
              </tr>
            </thead>
            <tbody>
              {refundschedules?.length > 0 &&
                refundschedules?.map((r, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{r.member}</td>
                    <td>{r.reference}</td>
                    <td>{new Date(r?.date).toDateString()}</td>
                    <td>{new Intl.NumberFormat().format(r?.proposedamount)}</td>

                    <td>
                      <LinkContainer to={`/member/bio/${r.member}/admin`}>
                        <Button variant='success' className='btn-sm mr-2'>
                          Details <i className='fas fa-angle-double-right'></i>
                        </Button>
                      </LinkContainer>
                      <LinkContainer to={`/member/${r.id}/refund`}>
                        <Button variant='warning' className='btn-sm mr-2'>
                          Details <i className='fas fa-angle-double-right'></i>
                        </Button>
                      </LinkContainer>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <AddRefund handleClose={handleClose} />
      </Modal>
    </div>
  );
};

export default ContributionRefunds;
