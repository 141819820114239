import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import Loader from '../Loader';
import Message from '../Message';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { LinkContainer } from 'react-router-bootstrap';
import {
  deleteInvitation,
  deleteMeeting,
  getAllMeetings,
  getMeetingDetailsByID,
  inviteGroupMeeting,
  inviteMeeting
} from '../actions/meetingAction';
import {
  getAllStudentsByGroup,
  getAllStudentsByGroupInvite,
  getMeetingInviteesByMeetingID,
  getMeetingParticipantsByMeetingID
} from '../studentAction';
import { getAllGroups, getAllGroupsByward } from '../actions/groupAction';
import Papa from 'papaparse';
import { useCSVDownloader } from 'react-papaparse';
import { MDBDataTableV5 } from 'mdbreact';
import Alert from '../Alert';
import { getAllWards } from '../actions/wardAction';

createTheme(
  'solarized',
  {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198'
    },
    background: {
      default: '#002b36'
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF'
    },
    divider: {
      default: '#073642'
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)'
    }
  },
  'dark'
);
const customStyles = {
  rows: {
    style: {
      minHeight: '72px' // override the row height
    }
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px'
    }
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px'
    }
  }
};
const MeetingInvitationPerward = () => {
  const dispatch = useDispatch();
  const { id } = useParams('id');
  useEffect(() => {
    dispatch(getAllMeetings(true));
    dispatch(getAllGroups());
    dispatch(getMeetingParticipantsByMeetingID(id));
    dispatch(getAllWards());
  }, []);

  useEffect(() => {
    dispatch(getMeetingInviteesByMeetingID(id));
    dispatch(getMeetingDetailsByID(id));
  }, [id]);

  const groupList = useSelector((state) => state.groupList);
  const invitedList = useSelector((state) => state.invitedList);
  const { invites, loading: inviteLoading, error: invitesError } = invitedList;

  const { groups, loading: groupsLoading, error: groupError } = groupList;
  const wardList = useSelector((state) => state.wardList);

  const { wards, loading: loadingWards, error: errorWards } = wardList;

  const studentList = useSelector((state) => state.studentList);
  const { students, loading, error } = studentList;
  const [groupid, setGroup] = useState(0);
  const meetingInsert = useSelector((state) => state.meetingInsert);
  const meetingDetails = useSelector((state) => state.meetingDetails);

  const {
    meeting,
    loading: meetingLoading,
    error: meetingError
  } = meetingDetails;

  const { success } = meetingInsert;
  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
    }, 3000);
  }, [success]);
  const handleDelete = () => {};

  const columns = [
    {
      name: 'R/No',
      selector: (row) => row.id
    },
    {
      name: 'Group NAME',
      selector: (row) => row.name
    },
    {
      name: 'Ward',
      selector: (row) => row.ward
    }
  ];
  const [selected, setSelected] = useState('');
  const data =
    !loading &&
    groups?.length > 0 &&
    groups?.map((member) => {
      return {
        name: member.name.toUpperCase(),
        ward: member.ward,
        id: member.id
      };
    });

  const handleChange = ({ selectedRows }) => {
    // setTotalAmount(
    //   selectedRows
    //     .map((p) => p.amount)
    //     .reduce((total, amount) => Number(total) + Number(amount), 0)
    // );
    setSelected(selectedRows.map((s) => s.id.toString()));
  };
  const { CSVDownloader, Type } = useCSVDownloader();

  const downloadCSV = () => {
    const csv = Papa.unparse({ invites });
    const blob = new Blob([csv]);
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob, { type: 'text/plain' });
    a.download = 'CSV Export File';
  };
  return (
    <div>
      <Row>
        <Col></Col>
      </Row>
      <Row className='dflex justify-content-center'>
        <Col className='dflex flex-row col-lg-8 col-md-6 py-3'>
          <label>Choose Ward</label>
          <Form.Control
            as='select'
            name='groupid'
            value={groupid}
            className='form-control form-control-sm'
            onChange={(e) => {
              setGroup(e.target.value);

              dispatch(getAllGroupsByward(e.target.value));
            }}
          >
            <option value='0'>Choose Ward name</option>
            {!loadingWards &&
              wards?.map((g) => (
                <option value={g.id} key={g.id}>
                  {g.name}
                </option>
              ))}
          </Form.Control>
        </Col>
      </Row>
      <Row className='dflex justify-content-center'>
        <Col className='dflex flex-row col-lg-8 col-md-6 py-3'>
          {groupsLoading ? (
            <Loader />
          ) : groupError ? (
            <Message variant='danger' children={error} />
          ) : groups?.length > 0 ? (
            <DataTable
              columns={columns}
              data={data}
              selectableRows
              onSelectedRowsChange={handleChange}
              customStyles={customStyles}
              pagination
              dense
              highlightOnHover
            />
          ) : (
            0
          )}
        </Col>
      </Row>
      <Row className='d-flex col-10 justify-content-center mt-5'>
        <Col className='d-flex justify-content-end'>
          <Button
            className='btn btn-sm btn-primary mr-4'
            onClick={(e) => {
              e.preventDefault();
              dispatch(inviteGroupMeeting({ selected, meeting: id }));
            }}
          >
            Schedule Group Invitation
          </Button>
        </Col>
      </Row>
      <Row className='d-flex col-10 justify-content-center mt-3'>
        <Col className='d-flex justify-content-end'>
          <CSVDownloader data={invites} filename='invitees'>
            <Button
              className='btn btn-sm btn-warning'
              onClick={() => {
                downloadCSV();
              }}
            >
              <i className='fas fa-file-csv mx-2'></i>
              Download Attendance List
            </Button>
          </CSVDownloader>
        </Col>
      </Row>
    </div>
  );
};

export default MeetingInvitationPerward;
