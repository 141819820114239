import React, { useEffect, useState, useRef } from 'react';
import {
  closeMeeting,
  deleteAttendance,
  getMeetingDetailsByID,
  insertAttendance,
  getApprovedAttendance
} from '../actions/meetingAction';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { MDBDataTableV5 } from 'mdbreact';

import Alert from '../Alert';
import Papa from 'papaparse';
import { useCSVDownloader } from 'react-papaparse';
import Loader from '../Loader';
import Message from '../Message';
import { LinkContainer } from 'react-router-bootstrap';
import {
  getStudentDetailsByMobile,
  getMeetingParticipantsByMeetingID
} from '../studentAction';
import ReactToPrint from 'react-to-print';
import ReportMeeting from './Report_Meeting';

const Meeting = ({ match }) => {
  const { id } = useParams('id');
  const componentRef = useRef();
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState('');

  const meetingDetails = useSelector((state) => state.meetingDetails);
  const { meeting, loading, error } = meetingDetails;

  const studentList = useSelector((state) => state.studentList);
  const invitedList = useSelector((state) => state.invitedList);
  const { students, loading: studentsLoading } = studentList;
  const { invites, loading: invitesLoading } = invitedList;

  const StudentDetails = useSelector((state) => state.StudentDetails);
  const meetingInsert = useSelector((state) => state.meetingInsert);
  const { success } = meetingInsert;
  const { student, loading: studentLoading } = StudentDetails;

  useEffect(() => {
    dispatch(getMeetingParticipantsByMeetingID(id));
  }, []);
  const [A, setA] = useState('');
  useEffect(() => {
    setA(success);
    setTimeout(() => {
      setA('');
    }, 3000);
  }, [success]);

  useEffect(() => {
    dispatch(getMeetingDetailsByID(id));
    // dispatch(getMeetingParticipantsByMeetingID(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!studentLoading && !!student.id > 0) {
      // dispatch(
      //   insertAttendance({
      //     meeting: id,
      //     member: student.id,
      //   })
      // );
      handleSubmit();
      // setMobile("");
    }
  }, [studentLoading, student, dispatch]);

  const handleSubmit = () => {
    dispatch(insertAttendance({ meeting: id, member: student.id }));
    setMobile('');
  };

  const handleDelete = (id) => {
    dispatch(deleteAttendance(id));
    setTimeout(() => {}, 2000);
  };
  const { CSVDownloader, Type } = useCSVDownloader();

  const downloadCSV = () => {
    const csv = Papa.unparse({ invites });
    const blob = new Blob([csv]);
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob, { type: 'text/plain' });
    a.download = 'CSV Export File';
  };
  // useEffect(() => {
  //   dispatch(getMeetingParticipantsByMeetingID(id));
  // }, [id, dispatch]);

  const data = {
    columns: [
      {
        label: 'Member No',
        field: 'memberno',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Mobile',
        field: 'venue',
        sort: 'asc',
        width: 100
      },

      {
        label: 'ID Number',
        field: 'purpose',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Group',
        field: 'group',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Action',
        field: 'update',
        sort: 'asc',
        width: 10
      }
    ],
    rows:
      invites &&
      invites.length &&
      invites.map((meeting) => {
        return {
          memberno: meeting.member,
          name: meeting.fullname,
          venue: meeting.mobile,
          purpose: meeting.idnumber,
          group: meeting.groupname,
          update: (
            <>
              {/* <LinkContainer
                to={`/contribution/add/${id}/${meeting.studentid}`}
              >
                <Button variant='success' className='btn-sm mr-2'>
                  Receive Funds <i className='fas fa-angle-double-right'></i>
                </Button>
              </LinkContainer>{' '}
              <LinkContainer to={`/loan/process/${id}/${meeting.studentid}`}>
                <Button variant='primary' className='btn-sm mr-2'>
                  Take Loan <i className='fas fa-money'></i>
                </Button>
              </LinkContainer>{' '} */}
              {'  '}
              <Button
                variant='danger'
                className='btn-sm mr-2'
                onClick={() => {
                  handleDelete(meeting.id);
                }}
              >
                <i className='fas fa-trash'></i>
              </Button>
            </>
          )
        };
      })
  };

  return (
    <>
      <Row className='d-flex justify-content-center'>
        <Col className='col-2 mt-5 pt-3 my-3 '>
          <div style={{ display: 'none' }}>
            <ReportMeeting id={id} ref={componentRef} />
          </div>
        </Col>
        <Col className='col-6 mt-5 pt-3 my-3 '>
          Card Barcode:{' '}
          <input
            autoFocus={true}
            name='mobile'
            value={mobile}
            placeholder='072....'
            onChange={(e) => {
              setMobile(e.target.value);
              if (!!mobile) {
                dispatch(getApprovedAttendance(e.target.value, id));
              }
              setTimeout(() => {
                setMobile('');
                dispatch(getMeetingParticipantsByMeetingID(id));
              }, 2000);
            }}
          ></input>{' '}
          <Button
            className='btn btn-sm'
            onClick={() => {
              handleSubmit();
              // dispatch(getMeetingParticipantsByMeetingID(id));
            }}
          >
            Admit to Meeting
          </Button>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col className='mt-3'>
          <div className='col-12'>
            {A && <Alert type='success' message={A} />}
          </div>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col className='col-8'>
          <hr />
          <h4 className='text-center'>
            Bio : {student.fullname} | ID No: {student.idnumber} | of{' '}
            {student.group}{' '}
          </h4>
          <hr />
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col className='col-8 mt-3 pt-3'>
          <h2 className='text-center'>{meeting.name}</h2>
          <hr />
          <h3 className='text-center'>List of Participants</h3>
        </Col>
      </Row>

      <Row className='d-flex justify-content-center'>
        <Col className='col-lg-8 col-md-12'>
          {invitesLoading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger' children={error} />
          ) : (
            invites &&
            invites.length &&
            invites.length > 0 && (
              <MDBDataTableV5
                small
                responsiveSm
                //cellEdit={cellEditFactory({ mode: 'click' })}

                entriesOptions={[5, 25, 50, 75, 100, 150, 200, 250, 300]}
                entries={5}
                hover
                striped
                sortable
                exportToCSV
                fullPagination
                data={data}
              />
            )
          )}
        </Col>
      </Row>
      <Row className='dflex justify-content-end'>
        <Col className='col-lg-8 col-md-12'>
          <ReactToPrint
            trigger={() => (
              <Button variant='success' className='btn btn-sm mr-2'>
                Print Participants <i className='fas fa-print'></i>
              </Button>
            )}
            content={() => componentRef.current}
          />
          <Button
            className='btn btn-sm btn-primary mr-4'
            onClick={(e) => {
              e.preventDefault();
              dispatch(closeMeeting(id));
            }}
          >
            Close Meeting
          </Button>
          <CSVDownloader data={invites} filename='attendance'>
            <Button
              className='btn btn-sm btn-warning mr-4'
              onClick={() => {
                downloadCSV();
              }}
            >
              <i className='fas fa-file-csv mx-2'></i>
              Download Attendance List
            </Button>
          </CSVDownloader>
        </Col>
      </Row>
    </>
  );
};

export default Meeting;
