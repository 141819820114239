export const LOANS_REQUEST = 'LOAN_REQUEST';
export const LOANS_REQUEST_SUCCESS = 'LOAN_REQUEST_SUCCESS';
export const LOANS_REQUEST_FAIL = 'LOAN_REQUEST_FAIL';

export const LOANREPAYMENTS_REQUEST = 'LOANREPAYMENTS_REQUEST';
export const LOANREPAYMENTS_REQUEST_SUCCESS = 'LOANREPAYMENTS_REQUEST_SUCCESS';
export const LOANREPAYMENTS_REQUEST_FAIL = 'LOANREPAYMENTS_REQUEST_FAIL';

export const LOAN_INSERT = 'LOAN_INSERT';
export const LOAN_INSERT_SUCCESS = 'LOAN_INSERT_SUCCESS';
export const LOAN_INSERT_FAIL = 'LOAN_INSERT_FAIL';

export const LOAN_UPDATE_REQUEST = 'LOAN_UPDATE_REQUEST';
export const LOAN_UPDATE_SUCCESS = 'LOAN_UPDATE_SUCCESS';
export const LOAN_UPDATE_FAIL = 'LOAN_UPDATE_FAIL';

export const LOAN_DELETE_REQUEST = 'LOAN_DELETE_REQUEST';
export const LOAN_DELETE_SUCCESS = 'LOAN_DELETE_SUCCESS';
export const LOAN_DELETE_FAIL = 'LOAN_DELETE_FAIL';

export const LOAN_DETAILS_REQUEST = 'LOAN_DETAILS_REQUEST';
export const LOAN_DETAILS_REQUEST_SUCCESS = 'LOAN_DETAILS_REQUEST_SUCCESS';
export const LOAN_DETAILS_REQUEST_FAIL = 'LOAN_DETAILS_REQUEST_FAIL';
