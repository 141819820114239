import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import uwepa from './assets/uwepa.jpeg';
const Header = () => {
  return (
    <header>
      <Navbar
        // style='background-color: #e3f2fd;'
        fixed='top'
        bg='bg-light'
        variant='bg-light'
        collapseOnSelect
        expand='md'
        className='mb-5'
      >
        <Container>
          <LinkContainer to='/list'>
            <Navbar.Brand className='text-secondary d-none d-md-block'>
              <img scr={uwepa} />
            </Navbar.Brand>
          </LinkContainer>

          <NavbarCollapse id='collapse'>
            <Nav className='ml-auto'>
              <LinkContainer to='/'>
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/redirect'>
                <Nav.Link>BULK SMS </Nav.Link>
              </LinkContainer>
              {/* <NavDropdown title='Members'>
                <LinkContainer to='/list'>
                  <NavDropdown.Item>Members Register</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/import'>
                  <NavDropdown.Item>Import Members</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/membersreport'>
                  <NavDropdown.Item>Members Report</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <NavDropdown title='Wards'>
                <LinkContainer to='/wards'>
                  <NavDropdown.Item>Wards</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/constituencies'>
                  <NavDropdown.Item>Constituencies</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title='Groups'>
                <LinkContainer to='/groups'>
                  <NavDropdown.Item>Groups</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/groupmembers'>
                  <NavDropdown.Item>Groups Members</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title='Meetings'>
                <LinkContainer to='/meetings'>
                  <NavDropdown.Item>Meetings</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title='Profile'>
                {/* <NavDropdown.Item onClick={logoutHandler}>
                <Nav.Link className='bg-primary'>Update Bio</Nav.Link>
              </NavDropdown.Item> */}

              <LinkContainer to='/login'>
                <Nav.Link
                  onClick={() => {
                    localStorage.removeItem('mamatwendelogin');
                  }}
                >
                  Logout
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </NavbarCollapse>
        </Container>
        <NavbarToggle
          aria-controls='navbarScroll'
          data-bs-toggle='collapse'
          data-bs-target='#collapse'
          style={{ right: '0px' }}
        >
          <i className='bi bi-justify'></i>
        </NavbarToggle>
      </Navbar>
    </header>
  );
};

export default Header;
